import React from "react";
import { Card, CardBody, Container, Row, Col, CardTitle } from "reactstrap";
import Header from "components/Headers/Header.js";
import imgPhone from "assets/img/telefones-uteis.png";

export default class PhoneList extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Container className="main-zoo" fluid>
          <Row className="align-items-center">
            <Col lg="12">
              <img
                className="img-fluid mx-auto d-block"
                src={imgPhone}
                alt="Imagem Notícia"
              />
            </Col>
          </Row>
          <br />
          <br />
          <div className="header-body">
            {/* Card stats */}
            <h2 class="text-center">#PREFEITURA DE UBERLÂNDIA</h2>

            <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h3"
                          className="text-uppercase text-muted mb-0"
                        >
                          S.I.M.(Serviço de Informação Municipal)
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3239-2444 / 3239-2800
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3239-2444"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Arquivo Público
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3231-9022 / 3232-4744
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3231-9022"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Biblioteca
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3234-1600 / 3236-9625
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3234-1600"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Procon
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3291-1600 / 3236-4841
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3291-1600"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Vigilância Sanitária
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3257-4058 / 3213-6152
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3257-4058"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Zoonoses
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3213-1470 / 3213-1418
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3213-1470"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          DMAE
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          115
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:115"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Secretaria Municipal de Serviços Urbanos
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3212-5356
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3212-5356"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Cata Treco
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3212-5356
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3212-5356"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Defesa Civil
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          199
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:199"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Patrulha Ambiental
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          0800.940.1133 / 3235-3117{" "}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3235-3117"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <h2 class="text-center">#TRÂNSITO</h2>

            <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Coordenação de Trânsito
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3239-2718
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3239-2718"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Núcleo de Planejamento Viário
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3239-2783 / 3239-2739
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3239-2783"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Núcleo de Execução e Man. de Sin. Horizontal
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3232-3501
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3232-3501"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Diretoria de Operações de Tráfego
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3230-7400
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3230-7400"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Central de Atendimento
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3230-7400 / 118
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3230-7400"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Núcleo de Zona Azul
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3223-1500 / 3215-0538
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3223-1500"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Núcleo de Execução e Man. de Sin. Vertical
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3232-3501
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3232-3501"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h2 class="text-center">#UAI's</h2>

            <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          UAI Luizote
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3223-8005
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3223-8005"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          UAI Martins
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3215-8331 / 3231-0016
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3215-8331"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          UAI Morumbi
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3226-3325 / 3211-6974
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3226-3325"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          UAI Planalto
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3227-8010 / 3226-2384
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3227-8010"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          UAI Roosevelt
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3254-8777 / 3223-7006
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3254-8777"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          UAI São Jorge
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3227-2264 / 3226-7914
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3227-2264"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          UAI Pampulha
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3211-8206 / 3226-0335
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3211-8206"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          UAI Tibery
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3227-8060 / 3213-7735{" "}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3227-8060"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h2 class="text-center">#CULTURA</h2>

            <Row style={{ marginTop: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Museu Municipal
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3214-0068 / 3235-0971
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3214-0068"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Oficina Cultural
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3223-4996
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3223-4996"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Teatro Grande Otelo
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3235-1568
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3235-1568"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Teatro Rondon Pacheco
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3235-9182{" "}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3235-9182"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h2 class="text-center">#PARQUES MUNICIPAIS</h2>

            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Parque do Sabiá
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3222-4055
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3222-4055"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Parque Municipal Victorio Siquerolli
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3212-1692
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3212-1692"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Parque Santa Luzia
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3225-0076
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3225-0076"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h2 class="text-center"># O U T R O S</h2>

            <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Aeroporto
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3233-5400
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3233-5400"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Ferrovia
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3212-3771
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3212-3771"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Rodoviária
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3235-5050
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3235-5050"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Detran
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3228-1770 / 3228-1774
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3228-1770"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Câmara de Vereadores
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3239-1000
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3239-1000"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Cemig
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          116 / 0800-7238 007
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:116"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Casa dos Conselhos
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3236-5892
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3236-5892"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Conselhos Tutelares
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3214-0721 / 3237-2276
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3214-0721"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Hospital de Clínicas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3218-2111
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3218-2111"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Terminal Central
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3210-2023
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3210-2023"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Centro de Valorização da Vida (CVV)
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          141 / 3214-3214
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:141"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Alcoólicos Anônimos
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          3235-5856
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:3235-5856"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h2 class="text-center"># S E G U R A N Ç A</h2>
            <Row style={{ marginTop: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Bombeiros
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          193
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:193"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Polícia Civil
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          197
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:197"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Polícia Federal
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          194
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:194"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Polícia Militar
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          190
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:190"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Polícia Rodoviária Estadual
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          198
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:198"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Polícia Rodoviária Federal
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 txt-contact">
                          191
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <a
                            style={{ color: "white" }}
                            className=""
                            href="tel:191"
                          >
                            <i className="fas fa-phone" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}
