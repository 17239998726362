import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Header from "components/Headers/Header.js";
import api from "shared/resources/Api";
import SweetAlert2 from "react-sweetalert2";
// import PDFViewer from 'mgr-pdf-viewer-react';

export default class Normatives extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cep: "",
            api: api.baseURL,
            requesting: false,
            normative: [],
            fileUploadState: "",
            fileUri: ""
        };
        this.inputReference = React.createRef();
    }

    componentDidMount() {
        this.fetchNormatives()
            .then((res) => this.setState({ response: res }))
            .catch((err) => {
               
                console.log(err);
            });
    }


    handleNormative(d) {
        this.callApi(d)
            .then()
            .catch((err) => console.log(err));
    }


    fileUploadAction = () => this.inputReference.current.click();
    fileUploadInputChange = (e) => {
        this.setState({
            swal: {
                show: true,
                title: "Atenção",
                text: this.state.normative.length > 0 ? "Deseja sobreescrever a normativa atual?" : "Deseja enviar a normativa?",
                type: "warning",
                showCancelButton: true,
                showConfirmButton: true,
            },
            fileUploadState: e[0],
            isConfirmation: true,
        });
    }

    callApi = async () => {
        var documento = {
            tipo: "normativa"
        };

        var data = new FormData();
        data.append("documento", JSON.stringify(documento));

        if (this.state.fileUploadState !== null) {
            data.append("arquivo", this.state.fileUploadState);
        }

        let response = "";
        if (this.state.normative.length > 0) {
            response = await fetch(api.baseURL + "/documento/" + this.state.normative[0].id, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    Authorization: localStorage.getItem("agToken"),
                },
                body: data,
            });
        } else {
            response = await fetch(api.baseURL + "/documento/", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: localStorage.getItem("agToken"),
                },
                body: data,
            });
        }

        const body = await response.json();

        if (response.status === 200 && body.sucesso === true) {
            this.setState({
                swal: {
                    show: true,
                    title: "Tudo Certo",
                    text: "Normativa cadastrada com sucesso!",
                    type: "success",
                },
                isSuccess: true,
            });
        } else if (body.sucesso === false) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Erro ao enviar normativa. ",
                    type: "error",
                },
            });
        } else {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Erro ao enviar normativa. ",
                    type: "error",
                },
            });
        }

        return body;
    };

    callNewNormative = async (d) => {
        const response = await fetch(api.baseURL + "/os/" + d.id, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("agToken"),
            },
        });

        const body = await response.json();

        if (response.status === 200) {
            this.setState({
                swal: {
                    show: true,
                    title: "Tudo Certo",
                    text: "O.S deletada com sucesso!",
                    type: "success",
                },
                cpf: "",
                data_cadastro_inicio: "",
                data_cadastro_fim: "",
                nome: "",
                tel: "",
                response: "",
            });
        } else {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Erro ao deletar O.S.",
                    type: "error",
                },
            });

            throw Error(body.message);
        }

        return body;
    };

    fetchNormatives = async () => {
        let host = api.baseURL + "/documento?tipo=normativa";

        const response = await fetch(host, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("agToken"),
            },
        });

        const body = await response.json();
     
        if (body.length > 0) {
            this.setState({
                normative: body,
                fileUri: body[0].media.arquivo,
                requesting: false
            }, () => {
                console.log(this.state.normative[0].media.arquivo);
            });
        }
        return body;
    };
    render() {

        return (
            <>
                <SweetAlert2
                    {...this.state.swal}
                    onConfirm={(result) => {
                        this.setState({
                            swal: {
                                show: false,
                            },
                        });

                        if (this.state.isConfirmation && !result.dismiss) {
                            this.setState({
                                isConfirmation: false,
                            });
                            this.handleNormative(this.state.normative);
                        }
                        if (this.state.isSuccess) {
                            this.props.history.push(`/admin/index`);
                        }
                    }}
                />
                <Header />
                <Container className="main-zoo" fluid>
                    <div className="text-center">
                        {this.state.normative.length === 0 && (
                            <Row className="align-items-center">
                                <Col lg="12">

                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address"
                                    >
                                        Nenhuma normativa cadastrada.
                                </label>

                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                {localStorage.getItem("perm") === "coordenador" && (
                                    <>
                                        <input type="file" hidden ref={this.inputReference} accept=".pdf,.doc" onChange={(e) => this.fileUploadInputChange(e.target.files)} />
                                        <Button
                                            className="mt-4 btn-zoo-alt"
                                            color="primary"
                                            type="button"
                                            onClick={this.fileUploadAction}
                                        >
                                            <b>Cadastrar Nova</b>
                                        </Button>

                                    </>
                                )}
                                <Button
                                    className="mt-4 btn-zoo"
                                    color="primary"
                                    type="button"
                                    target="_blank"
                                    href={this.state.fileUri}
                                    download
                                >
                                    <b>Download</b>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <br>
                    </br>
                    <br></br>
                    {this.state.fileUri != null && this.state.fileUri !== "" && (
                        <Row className="align-items-center">
                            <Col lg="12">

                                {/* <PDFViewer document={{
                                    url: this.state.fileUri
                                }} /> */}

                            </Col>
                        </Row>
                    )}

                    <br />
                    <br />
                </Container>
            </>
        );
    }
}
