const Util = {
  validaCPF(cpf) {
    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if (cpf.length < 11) return false;
    for (i = 0; i < cpf.length - 1; i++)
      if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
        digitos_iguais = 0;
        break;
      }
    if (!digitos_iguais) {
      numeros = cpf.substring(0, 9);
      digitos = cpf.substring(9);
      soma = 0;
      for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado.toString() !== digitos.charAt(0)) return false;
      numeros = cpf.substring(0, 10);
      soma = 0;
      for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado.toString() !== digitos.charAt(1)) return false;

      return true;
    } else return false;
  },

  formatEquipe(equipeName) {
    if (equipeName === 'mobilizacao') {
      return 'Mobilização';
    }
    if (equipeName === 'acoes') {
      return 'Ações';
    }
    if (equipeName.includes('supervisao_area')) {
      return 'Supervisão Área';
    }
    if (equipeName.includes('supervisao_area')) {
      return 'Supervisão Área';
    }
    if (equipeName.includes('supervisao')) {
      return equipeName.replace('supervisao','Supervisão');
    }
    return equipeName;
  },

  formatFuncao(funcaoName) {

    if (funcaoName.includes('supervisao_area')) {
      return 'Supervisor de Área';
    }
    if (funcaoName.includes('supervisao_turma')) {
      return 'Supervisor de Turma';
    }
    if (funcaoName.includes('supervisaoentual')) {
      return 'Inserção de Informações';
    }
    return funcaoName;
  },

  formatOrigem(origem) {

    if (origem.includes('telefone')) {
      return 'Telefone';
    }
    if (origem.includes('sim')) {
      return 'S.I.M';
    }
    if (origem.includes('faleconosco')) {
      return 'Fale Conosco';
    }
    if (origem.includes('ouvidoria')) {
      return 'Ouvidoria';
    }
    if (origem.includes('aplicativo')) {
      return 'Aplicativo';
    }
    if (origem.includes('tecnica')) {
      return 'Origem Técnica';
    }
    if (origem.includes('drones')) {
      return 'Drones';
    }
    if (origem.includes('municepe')) {
      return 'Munícepe';
    }
    if (origem.includes('mail')) {
      return 'E-mail';
    }

    origem = '-';
    return origem;
  },

  formatEquipe2(equipe) {

    if (equipe === undefined) {
      return "Nenhuma";
    }
    return equipe.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
  },

  validateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  },

  removeSnakeCase(texto) {
    if (texto !== null && texto !== undefined) {
      return texto.replace("_", " ");
    }
  },

  snakeToPascal(string) {
    return string
      .split("/")
      .map(snake =>
        snake
          .split("_")
          .map(substr => substr.charAt(0).toUpperCase() + substr.slice(1))
          .join("")
      )
      .join("/")
      .replace(/([A-Z])/g, " $1")
      .trim();
  },
  capitalize(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  },

  truncate(text,size,bol3Dot) {
    if (text && text.length > size)
      return text.substring(0, size)+(bol3Dot?"...":"");
    else
      return text;
  }
};
export default Util;
