import React from "react";
import SweetAlert2 from "react-sweetalert2";
import { cpfMask } from "shared/services/Mask";
import api from "shared/resources/Api";
import Util from "shared/services/Util";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
  Row,
} from "reactstrap";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpf: "",
      codigo: "",
      showCode: false,
      sendButtonText: "Enviar",
      success: false,
      api: api.baseURL,
      response: "",
      codeSent: false,
      requesting: false,
    };

    this.handleCode = this.handleCode.bind(this);
    this.handleResend = this.handleResend.bind(this);
  }

  handleResend(event) {
    if (this.state.cpf === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o CPF.",
          type: "error",
        },
      });
      return false;
    }

    if (!Util.validaCPF(this.state.cpf.replace(/\D/g, ""))) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "CPF Inválido",
          type: "error",
        },
      });
      return false;
    }

    this.setState({
      swal: {
        show: true,
        title: "Tudo Certo",
        text: "Reenviaremos o SMS.",
        type: "success",
      },
    });

    this.callApi();
    return true;
  }

  handleCode(event) {
    if (this.state.cpf === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o CPF.",
          type: "error",
        },
      });
      return false;
    }

    if (!Util.validaCPF(this.state.cpf.replace(/\D/g, ""))) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "CPF Inválido",
          type: "error",
        },
      });
      return false;
    }

    if (!this.state.showCode) {
      this.setState({ requesting: true });
      this.callApi();
      this.setState({
        showCode: true,
        sendButtonText: "Continuar",
        requesting: false,
      });
    } else {
      if (this.state.codigo === "") {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Informe o Código SMS.",
            type: "error",
          },
          requesting: false,
        });

        return false;
      } else {
        if (this.state.codigo !== "") {
          this.callCheckSms();
        } else {
          this.callApi();
        }
      }
    }
  }

  callApi = async () => {
    const response = await fetch(api.baseURL + "/usuario/recuperar_senha", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cpf: this.state.cpf.replace(/\D/g, ""),
      }),
    });

    const body = await response.json();
    if (response.status === 200 && body.sucesso === true) {
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          //text: body.erro,
          text: body.msg,
          type: "error",
        },
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao recuperar a senha.",
          type: "error",
        },
      });
    }

    return body;
  };

  callCheckSms = async () => {
    const response = await fetch(api.baseURL + "/usuario/check_sms", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cpf: this.state.cpf.replace(/\D/g, ""),
        codigo_sms: this.state.codigo,
      }),
    });
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.props.history.push("/auth/change-password", {
        codigo: this.state.codigo,
        cpf: this.state.cpf.replace(/\D/g, ""),
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: body.erro,
          type: "error",
        },
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao recuperar a senha.",
          type: "error",
        },
      });
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
          }}
        />
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              {!this.state.requesting && (
                <div>
                  <div className="text-center text-muted mb-4">
                    <strong>Esqueci Minha Senha</strong>
                  </div>
                  <Form role="form">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-archive-2" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="CPF"
                          type="text"
                          inputMode="numeric"
                          autoComplete="new-cpf"
                          value={this.state.cpf}
                          onChange={(e) =>
                            this.setState({ cpf: cpfMask(e.target.value) })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    {!this.state.showCode && (
                      <div className="text-muted text-center font-italic">
                        <small>
                          Enviaremos um{" "}
                          <span className="text-default font-weight-700">
                            SMS{" "}
                          </span>
                          para seu celular{" "}
                        </small>
                      </div>
                    )}
                    {this.state.showCode && (
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Código SMS"
                            type="number"
                            inputMode="numeric"
                            autoComplete="new-complemento"
                            maxLength={8}
                            value={this.state.codigo}
                            onChange={(e) =>
                              this.setState({ codigo: e.target.value })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                    )}
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={() =>
                              this.props.history.push("/auth/index")
                            }
                          >
                            <b>Voltar</b>
                          </Button>
                          {this.state.showCode && (
                            <Button
                              className="mt-4"
                              color="primary"
                              type="button"
                              onClick={this.handleResend}
                            >
                              Reenviar
                            </Button>
                          )}
                          <Button
                            className="mt-4 btn-zoo"
                            color="primary"
                            type="button"
                            onClick={this.handleCode}
                          >
                            {this.state.sendButtonText}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </div>
              )}
              {this.state.requesting && (
                <div className="text-center">
                  <Spinner
                    style={{ width: "3rem", height: "3rem" }}
                    color="primary"
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default ForgotPassword;
