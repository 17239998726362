import Api from "shared/resources/Api";

export const neighborhood = async () => {
  const response = await fetch(Api.baseURL + "/bairro", {
    headers: {
      Authorization: localStorage.getItem("agToken"),
    },
  });

  return await response.json();
};

export const sectors = async () => {
  const response = await fetch(Api.baseURL + "/setor", {
    headers: {
      Authorization: localStorage.getItem("agToken"),
    },
  });

  return await response.json();
};

export const healthUnits = async () => {
  const response = await fetch(Api.baseURL + "/unidade_saude", {
    headers: {
      Authorization: localStorage.getItem("agToken"),
    },
  });

  return await response.json();
};

export const equipes = async () => {
  const response = await fetch(Api.baseURL + "/unidade_saude/equipe?status=S", {
    headers: {
      Authorization: localStorage.getItem("agToken"),
    },
  });

  return await response.json();
};
