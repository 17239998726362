import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner
} from "reactstrap";


import SweetAlert2 from "react-sweetalert2";
import Util from "shared/services/Util";
import Api from "shared/resources/Api";
import Header from "components/Headers/Header.js";
import moment from "moment";

import api from "shared/resources/Api";
import InputMask from "react-input-mask";

import gconf from "shared/resources/Gconf";
export default class OvitrampLabEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cep: "",
            nome: "",
            tel: "",
            logradouro: "",
            numero: "",
            bairro: "",
            complemento: "",
            lat: -18.90971,
            lon: -48.261921,
            api: Api.baseURL,
            isMapOk: false,
            isEdicao: false,
            formTitle: "CADASTRAR",
            neighborhood: [],
            sectors: [],
            selectedSector: null,
            selectedNeighborhood: "",
            lado: null,
            quarteirao: null,
            situacao: "",
            codigo: null,
            control: [],
            selectedOvitramp: [],
            selectedData: [],
            selectedAedes: [],
            selectedAlbobictus: [],
            selectedLarvas: [],
            selectedOutros: [],
            selectedButtonLabel: [],
            selectedClassLabel: [],
            isSuccess: false,
        };

        this.handleSave = this.handleSave.bind(this);
    }

    componentWillMount() {
        var originLab = false;

        if (
            this.props.location != null &&
            this.props.location.state != null &&
            this.props.location.state.ovitramp != null
        ) {

            if(this.props.location.state.listSource != null)
            {
               originLab = true;
            }
            this.setState(
                {
                    ovitramp: this.props.location.state.ovitramp,
                    agent: this.props.location.state.os,
                    loading: false,
                    nome: this.props.location.state.ovitramp.nome,
                    lado: this.props.location.state.ovitramp.lado,
                    quarteirao: this.props.location.state.ovitramp.quarteirao,
                    tipo: this.props.location.state.ovitramp.tipo,
                    logradouro: this.props.location.state.ovitramp.logradouro.trim(),
                    numero: this.props.location.state.ovitramp.numero,
                    complemento: this.props.location.state.ovitramp.complemento,
                    isMapOk: true,
                    isEdicao: true,
                    formTitle: "EDITAR",
                    codigo: originLab ? this.props.location.state.ovitramp.codigo_ovitrampa : this.props.location.state.ovitramp.id
                },
                () => this.fetchControl()
            );
        }
    }

    fetchControl = async () => {
        let host = this.state.api + "/controle_ovitrampa?codigo_ovitrampa=" + this.props.location.state.ovitramp.codigo_ovitrampa;


        const response = await fetch(host, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("agToken"),
            },
        });

        var body = await response.json();
 
        if (body.length > 0) {
            var latestItem =
                {
                    ovos_aeg: 0,
                    ovos_alb: 0,
                    ovos_larva: 0,
                    data_criacao: "",
                    active: true,
                    semana: body.reverse()[body.length - 1].semana + 1,
                    ovos_culex: 0,
                    ano: this.state.ovitramp.ano,
                    id_ovitrampa: this.state.ovitramp.codigo,
                }
            
            body.push(latestItem)


            this.setState({
                control: body.reverse(),
                requesting: false,
            });
        } else {
            var currentControl = [
                {
                    ovos_aeg: 0,
                    ovos_alb: 0,
                    ovos_larva: 0,
                    data_criacao: "",
                    active: true,
                    semana: this.state.ovitramp.semana,
                    ovos_culex: 0,
                    ano: this.state.ovitramp.ano,
                    id_ovitrampa: this.state.ovitramp.codigo,
                    codigo_ovitrampa: 17,
                    id: this.state.ovitramp.id
                }
            ]
            this.setState({
                control: currentControl,
                requesting: false,
            });
        }
        return body;
    };

    handleControlDataArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedData];
        updatedArray[index] = newValue;
        this.setState({
            selectedData: updatedArray,
        });
    };

    handleControlAedesArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedAedes];
        updatedArray[index] = newValue;
        this.setState({
            selectedAedes: updatedArray,
        });
    };

    handleControlAlbobictusArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedAlbobictus];
        updatedArray[index] = newValue;
        this.setState({
            selectedAlbobictus: updatedArray,
        });
    };

    handleControlLarvasArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedLarvas];
        updatedArray[index] = newValue;
        this.setState({
            selectedLarvas: updatedArray,
        });
    };

    handleControlOutrosArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedOutros];
        updatedArray[index] = newValue;
        this.setState({
            selectedOutros: updatedArray,
        });
    };

    handleSaveControl = (index) => {

        if (this.validateControlFields(index)) {
            this.callSaveControl(index);
        }
    };


    callSaveControl = async (index) => {

          console.log('save control',this.state.codigo)
        try {
            const response = await fetch(api.baseURL + "/controle_ovitrampa/", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("agToken"),
                },
                body: JSON.stringify({
                    data_criacao: moment(
                        moment(this.state.selectedData[index], "DD/MM/YYYY")
                    ).format("YYYY-MM-DDTHH:mm"),
                    id_ovitrampa: this.props.location.state.listSource != null ? this.state.ovitramp.id : this.state.ovitramp.ovitrampa,
                    ovos_aeg: (this.state.selectedAedes[index] !== undefined && this.state.selectedAedes[index] !== null && this.state.selectedAedes[index] !== "") ? this.state.selectedAedes[index] : 0,
                    ovos_alb: (this.state.selectedAlbobictus[index] !== undefined && this.state.selectedAlbobictus[index] !== null && this.state.selectedAlbobictus[index] !== "") ? this.state.selectedAlbobictus[index] : 0,
                    ovos_larva: (this.state.selectedLarvas[index] !== undefined && this.state.selectedLarvas[index] !== null && this.state.selectedLarvas[index] !== "") ? this.state.selectedLarvas[index] : 0,
                    ovos_culex: (this.state.selectedOutros[index] !== undefined && this.state.selectedOutros[index] !== null && this.state.selectedOutros[index] !== "") ? this.state.selectedOutros[index] : 0,
                })
            });

            const body = await response.json();

            if (response.status === 200) {
                this.setState({
                    swal: {
                        show: true,
                        title: "Tudo Certo",
                        text: "Laboratório enviado com sucesso.",
                        type: "success",
                    },
                    requesting: false,
                    isSuccess: true,
                });

            } else {
                this.setState({
                    swal: {
                        show: true,
                        title: "Erro",
                        text: body.erro,
                        type: "error",
                    },
                    selectedOs: null,
                    requesting: false,
                });
            }

        }
        catch (err) {
            // not jumping in here.
            console.log(err)
            this.setState({
                swal: {
                    show: true,
                    title: "Erro",
                    text: "Erro ao cadastrar Laboratório",
                    type: "error",
                },
                selectedOs: null,
                requesting: false,
            });
        }
    };


    validateControlFields(index, userId) {
        /* Validação obrigatoriedade */
        var dateFormat = "DD/MM/YYYY";
        if ((this.state.selectedData[index] === undefined || this.state.selectedData[index] === "")) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe a Data do Registro",
                    type: "warning",
                },
            });

            return false;
        }
        else
        {
            if (
                !moment(this.state.selectedData[index], dateFormat, true).isValid()
              ) {
                this.setState({
                  swal: {
                    show: true,
                    title: "Atenção",
                    text: "Data do registro inválida",
                    type: "error",
                  },
                });
                return false;
              }
        }



        return true;
    }




    handleClassLabel(team, index) {

        let estado = this.state.control.find(p => p.usuario === team.id).estado;
        let className = 'btn-zoo-alt'
        switch (estado) {
            case 'pendente':
                className = 'btn-zoo-war'
                break;
            case 'fechado':
                className = 'btn-zoo-suc'
                break;
            case 'aberto':
                className = 'btn-zoo-alt'
                break;
            default:
                break;
        }
        // this.handleButtonClassArray(index, className);
        return className;
    }
    componentDidMount() { }

    handleSave(event) {
        if (this.validateFields()) {
            this.callSave();
        }
    }

    blurEndereco = () => {
        this.consultarLatLong();
    };

    consultarLatLong = () => {
        this.setState({ isMapOk: false });
        const addres = `${this.state.logradouro}, ${this.state.numero}, ${this.state.bairro}, ${this.state.cep}`;
        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${addres}&key=${gconf.key}`
        )
            .then((response) => response.json())
            .then((res) => {
                if (res && res.status === "OK") {
                    console.log(res);
                    if (
                        res.results.length > 0 &&
                        res.results[0].geometry &&
                        res.results[0].geometry.location
                    ) {
                        this.setState({
                            ...this.state,
                            lat: res.results[0].geometry.location.lat,
                            lon: res.results[0].geometry.location.lng,
                            isMapOk: true,
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({
                    ...this.state,
                    lat: -18.90971,
                    lon: -48.261921,
                    isMapOk: true,
                });
            });
    };

    renderInputs(value) {
        const inputs = [];

        this.state.control.map((team, index) => (

            inputs.push(

                <>
                    <Row>
                        <Col lg="12">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    {team.semana}º Semana - {team.ano}
                                </label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    Data
    </label>
                                <InputMask
                                    className="form-control"
                                    mask="99/99/9999"
                                    id="input-data"
                                    type="text"
                                    disabled={team.active !== true}
                                    autoComplete="new-data"
                                    onChange={e => this.handleControlDataArray(index, e.target.value)}
                                    required
                                    value={team.active !== true ? moment(team.data_criacao, 'YYYY-MM-DD').format('DD/MM/YYYY') : this.state.selectedData[index]}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    Aedes Aegypti
    </label>
                                <Input
                                    className="form-control"
                                    id="input-aedes"
                                    type="number"
                                    disabled={team.active !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-aedes"
                                    required
                                    onChange={e => this.handleControlAedesArray(index, e.target.value)}
                                    value={team.active !== true ? team.ovos_aeg : this.state.selectedAedes[index]}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    Aedes Albobictus
    </label>
                                <Input
                                    className="form-control"
                                    id="input-alb"
                                    type="number"
                                    disabled={team.active !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-alb"
                                    required
                                    onChange={e => this.handleControlAlbobictusArray(index, e.target.value)}
                                    value={team.active !== true ? team.ovos_alb : this.state.selectedAlbobictus[index]}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    Larvas/Pupas
    </label>
                                <Input
                                    className="form-control"
                                    id="input-larv"
                                    type="number"
                                    disabled={team.active !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-larv"
                                    required
                                    onChange={e => this.handleControlLarvasArray(index, e.target.value)}
                                    value={team.active !== true ? team.ovos_larva : this.state.selectedLarvas[index]}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    Outros
    </label>
                                <Input
                                    className="form-control"
                                    id="input-out"
                                    type="number"
                                    disabled={team.active !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-outr"
                                    onChange={e => this.handleControlOutrosArray(index, e.target.value)}
                                    required
                                    value={team.active !== true ? team.ovos_culex : this.state.selectedOutros[index]}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="2" style={{ paddingTop: '2.1%' }}>
                            <FormGroup>
                                <Button
                                    className={team.active !== true ? 'btn-zoo-suc' : 'btn-zoo-alt'}
                                    color="primary"
                                    onClick={() => {
                                        this.handleSaveControl(index);
                                    }}
                                    type="button"
                                >
                                    {team.active !== true ? 'Enviado' : 'Enviar'}
                    </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className="my-4" />

                </>
            )
        )
        )
        return inputs;
    }


    validateFields() {
        /* Validação obrigatoriedade */

        if (
            this.state.codigo === undefined ||
            this.state.codigo === null ||
            this.state.codigo.length === 0
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Código.",
                    type: "warning",
                },
            });

            return false;
        } else {
            if (this.state.codigo === "0") {
                this.setState({
                    swal: {
                        show: true,
                        title: "Atenção",
                        text: "Código não pode ser igual a zero.",
                        type: "warning",
                    },
                });

                return false;
            }
        }

        if (this.state.nome === undefined || this.state.nome.length === 0) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o nome do proprietário da Ovitrampa.",
                    type: "warning",
                },
            });

            return false;
        }

        if (
            this.state.tel === undefined ||
            this.state.tel.replace(/\D/g, "").length === 0
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Telefone.",
                    type: "warning",
                },
            });

            return false;
        }

        if (this.state.situacao === undefined || this.state.situacao === "") {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe a situação da Ovitrampa.",
                    type: "warning",
                },
            });

            return false;
        }

        if (
            this.state.logradouro === undefined ||
            this.state.logradouro.length === 0
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Endereço.",
                    type: "warning",
                },
            });

            return false;
        }
        if (this.state.numero === undefined || this.state.numero.length === 0) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Número.",
                    type: "warning",
                },
            });

            return false;
        }
        if (
            this.state.selectedNeighborhood === undefined ||
            this.state.selectedNeighborhood === "" ||
            this.state.selectedNeighborhood === null
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Bairro.",
                    type: "warning",
                },
            });

            return false;
        }
        /* Validação obrigatoriedade */

        if (
            this.state.tel !== "" &&
            this.state.tel.replace(/\D/g, "").length !== 11 &&
            this.state.tel.replace(/\D/g, "").length !== 10
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Preencha o telefone com DDD e nono Dígito caso haja.",
                    type: "warning",
                },
            });

            return false;
        }
        /* Validação integridade */

        return true;
    }

    createBody = () => {
    
        var data = new FormData();
        if (this.state.ovitramp) {
            var ovitrampa = {
                cep:
                    this.state.cep !== ""
                        ? this.state.cep.replace(/\D/g, "")
                        : this.state.ovitramp.cep,
                situacao: this.state.situacao,
                nome:
                    this.state.nome !== "" ? this.state.nome : this.state.ovitramp.nome,
                tel:
                    this.state.tel !== ""
                        ? this.state.tel.replace(/\D/g, "")
                        : this.state.ovitramp.tel.replace(/\D/g, ""),
                cod_bairro:
                    this.state.selectedNeighborhood !== ""
                        ? this.state.selectedNeighborhood
                        : this.state.ovitramp.bairro_setor.nome_bairro,
                lado: this.state.lado,
                quarteirao: this.state.quarteirao,
                endereco: {
                    logradouro:
                        this.state.logradouro !== ""
                            ? this.state.logradouro
                            : this.state.ovitramp.endereco.logradouro,
                    bairro:
                        this.state.bairro !== ""
                            ? this.state.bairro
                            : this.state.ovitramp.endereco.bairro,
                    numero:
                        this.state.numero !== ""
                            ? this.state.numero
                            : this.state.ovitramp.endereco.numero,
                    complemento:
                        this.state.complemento !== ""
                            ? this.state.complemento
                            : this.state.ovitramp.endereco.complemento,
                },
                geolocalizacao: {
                    lat: (this.state.lat !== ""
                        ? this.state.lat
                        : this.state.ovitramp.lat
                    ).toString(),
                    lon: (this.state.lon !== ""
                        ? this.state.lon
                        : this.state.ovitramp.lon
                    ).toString(),
                },
                codigo: this.state.codigo
            };

            data.append("ovitrampa", JSON.stringify(ovitrampa));
            return data;
        } else {
            // eslint-disable-next-line no-redeclare
            var ovitrampa = {
                cep: this.state.cep.replace(/\D/g, ""),
                nome: this.state.nome,
                tel: this.state.tel.replace(/\D/g, ""),
                endereco: {
                    logradouro: this.state.logradouro.trim(),
                    bairro: this.state.bairro,
                    numero: this.state.numero,
                    complemento: this.state.complemento,
                },
                cod_bairro:
                    this.state.selectedNeighborhood !== ""
                        ? this.state.selectedNeighborhood
                        : this.state.ovitramp.endereco.bairro,
                lado: this.state.lado,
                quarteirao: this.state.quarteirao,
                geolocalizacao: {
                    lat: this.state.lat.toString(),
                    lon: this.state.lon.toString(),
                },
                situacao: this.state.situacao,
                codigo: this.state.codigo
            };

            data.append("ovitrampa", JSON.stringify(ovitrampa));
            return data;
        }
    };

    callSave = async () => {
        console.log(this.createBody());
        const response = await fetch(
            Api.baseURL +
            "/ovitrampa/" +
            (this.state.ovitramp ? this.state.ovitramp.id : ""),
            {
                method: this.state.ovitramp ? "PUT" : "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: localStorage.getItem("agToken"),
                },
                body: this.createBody(),
            }
        );
        const body = await response.json();

        if (response.status === 200 && body.sucesso === true) {
            this.setState({
                swal: {
                    show: true,
                    title: "Tudo Certo",
                    text: this.state.ovitramp
                        ? "Edição realizada com sucesso!"
                        : "Cadastro realizado com sucesso!",
                    type: "success",
                },
                isSuccess: true,
            });
        } else if (body.sucesso === false) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: this.state.ovitramp
                        ? body.msg
                        : body.msg,
                    type: "error",
                },
            });
            console.log(body.erro);
        } else {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: this.state.ovitramp
                        ? "Erro ao editar ovitrampa. "
                        : "Erro ao cadastrar ovitrampa.",
                    type: "warning",
                },
            });
            console.log(body.erro);
        }

        return body;
    };
    render() {
        return (
            <>
                <SweetAlert2
                    {...this.state.swal}
                    onConfirm={(result) => {
                        this.setState({
                            swal: {
                                show: false,
                            },
                        });
                        if (this.state.isSuccess) {
                            this.props.history.push("/admin/laboratorio");
                        }
                    }}
                />
                <Header />
                {/* Page content */}
                <Container className="mb-7 mt-5 " fluid>
                    <Row className="mb-3">
                        <Col className="order-xl-1">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">
                                                Ovitrampa:{" "}
                                                {this.state.ovitramp !== undefined && this.props.location.state.listSource != null
                                                    ? this.state.ovitramp.codigo
                                                    : this.state.ovitramp.codigo_ovitrampa}
                                            </h3>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Nome:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined && this.state.ovitramp.nome != null
                                                        ? this.state.ovitramp.nome
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Logradouro:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined && this.state.ovitramp.logradouro != null
                                                        ? this.state.ovitramp.logradouro
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Bairro:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined && this.state.ovitramp.bairro != null
                                                        ? this.state.ovitramp.bairro
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Status:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined &&
                                                        this.state.ovitramp.status !== null
                                                        ? Util.capitalize(this.state.ovitramp.status)
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Situação:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined &&
                                                        this.state.ovitramp.situacao !== null
                                                        ? Util.capitalize(this.state.ovitramp.situacao)
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                {this.state.control != null && this.state.control.length > 0 && (
                                    <CardBody>
                                        <Form>

                                            <div>
                                                <hr className="my-4" />
                                                {/* Address */}
                                                <h3 className="mb-4">
                                                    Laboratório
                        </h3>
                                                <>
                                                    {this.state.control.length > 0 && this.state.ovitramp.id > 0 && (
                                                        this.renderInputs(4)
                                                    )}
                                                    {this.state.control.length === 0 && this.state.ovitramp.id > 0 && (
                                                        <Row className="align-items-center">
                                                            <Col xs="12">

                                                                <div className="text-center">
                                                                    <Spinner
                                                                        style={{ width: "3rem", height: "3rem" }}
                                                                        color="primary"
                                                                    />
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    )}
                                                </>
                                            </div>
                                            {/* 
                    <hr className="my-4" /> */}

                                            {/* <div className="text-center">
                      <Button
                        className="mt-4 btn-zoo"
                        color="primary"
                        type="button"
                        onClick={this.handleSave}
                      >
                        Salvar Produção
                      </Button>
                    </div> */}
                                        </Form>
                                    </CardBody>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}
