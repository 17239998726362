import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { neighborhood } from "shared/services/PlacesService";
import Header from "components/Headers/Header.js";
import Api from "shared/resources/Api";

export default class NeighborhoodList extends React.Component {
  /* Dados Grid */
  columns = [
    {
      name: "Código",
      selector: "id",
      sortable: true,
    },
    {
      name: "Localidade",
      selector: "nome",
      sortable: true,
    },
    {
      name: "Região",
      selector: "nome_setor",
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              this.props.history.push("/admin/neighboorhood-edit", {
                selectedDigitalSketch: row,
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {/* {localStorage.getItem("perm") === "coordenador" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )} */}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      bairro: "",
      cod_bairro: "",
      selectedNeighborhood: "",
      api: Api.baseURL,
      neighborhood: [],
      requesting: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount() {



    try {
      let params = {
      
        tp: "S",
      };
     

      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map((k) => esc(k) + "=" + esc(params[k]))
        .join("&");
  
      let host = this.state.api + "/bairro?";
  
      const response = fetch(host + query, {
        headers: {
          Authorization: localStorage.getItem("agToken"),
        },
      }).then((respons2) => {
        if (!respons2.ok) {
          throw new Error("Network response was not ok");
        }
        return respons2.json();
      })
      .then((body) => {
        console.log("body"+body)
        localStorage.setItem("bairrogrid", JSON.stringify(body));
        var obj2 = JSON.parse(localStorage.getItem("bairrogrid"));
        this.setState({
          response: obj2,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });




  
      
    } catch (e) {
      console.error("Invalid JSON string:", e);
    }







    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    if (localStorage.getItem("bairrogrid")) {
 
      var obj = JSON.parse(localStorage.getItem("bairrogrid"));
      this.setState({
        response: obj,
      })
    }
    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });
  }

  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "warning",
        showCancelButton: true,
        showConfirmButton: true,
      },
      sketch: element,
      isConfirmation: true,
    });
  };
  handleChange = (value) => {
    if (value.length > 0) {
      this.setState({
        bairro: this.state.neighborhood.find((p) => p.id.toString() === value)
          .nome,
        selectedNeighborhood: value,
      });
    } else {
      this.setState({
        bairro: "",
        selectedNeighborhood: "",
      });
    }
  };
  handleDeleteSketch(d) {
    this.callDelSketch(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDelSketch = async (d) => {
    const response = await fetch(Api.baseURL + "/bairro/" + d.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Croqui digital deletado com sucesso!",
          type: "success",
        },
        cod_bairro: "",
        bairro: "",
        selectedNeighborhood:"",
        response: "",
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar Croqui Digital.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  handleSubmit(event) {
    this.setState({ requesting: true });
    this.fetchSketch()
      .then((res) => this.setState({ response: res }, () => {
        localStorage.setItem("bairrogrid", JSON.stringify(res));
      }))
      .catch((err) => console.log(err));

    event.preventDefault();
  }

  fetchSketch = async () => {
    let params = {
      bairro: this.state.bairro,
      cod_bairro: this.state.cod_bairro,
    };

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let host = this.state.api + "/bairro?";

    const response = await fetch(host + query, {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        sketch: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não foram encontrados croquis para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteSketch(this.state.sketch);
            }
          }}
        />
        <Header />

        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Consulta Localidades
                    </h6>

                    {/* <div className="pl-lg-4">
                      <Row>
                        <Col lg="9">
                          <FormGroup>
                            {this.state.neighborhood.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Bairro 
                                </label>

                                <select
                                  value={this.state.selectedNeighborhood}
                                  onChange={(e) => {
                                    this.handleChange(e.target.value);
                                  }}
                                  className="form-control "
                                >
                                  <option value="">Selecione...</option>

                                  {this.state.neighborhood.map((team) => (
                                    <option
                                      key={team.id}
                                      value={team.id}
                                      defaultValue={
                                        this.state.selectedNeighborhood
                                      }
                                    >
                                      {team.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Código Bairro
                            </label>
                            <Input
                              type="number"
                              name="cod_bairro"
                              id="cod_bairro"
                              className="form-control"
                              inputMode="numeric"
                              value={this.state.cod_bairro}
                              onChange={(e) =>
                                this.setState({ cod_bairro: e.target.value })
                              }
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div> */}

                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {/* <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button> */}
                          {localStorage.getItem("perm") === "coordenador" && (
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={() => {
                                this.props.history.push(
                                  "/admin/neighboorhood-edit"
                                );
                              }}
                            >
                              <b>Cadastrar</b>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              sortable={true}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              noDataComponent="Nenhum registro encontrado."
              paginationComponentOptions={this.defaultComponentOptions}
              customStyles={this.customStyles}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
