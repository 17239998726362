import React from 'react';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';

import SweetAlert2 from 'react-sweetalert2';
import InputMask from 'react-input-mask';
import InputNumber from 'react-input-just-numbers';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { cpfMask } from 'shared/services/Mask';

import Header from 'components/Headers/Header.js';
import Util from 'shared/services/Util';
import Api from 'shared/resources/Api';
import moment from 'moment';

export default class FeedList extends React.Component {
  /* Dados Grid */
  columns = [
    {
      name: 'Número',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => (
        <div>
          {row.status
            ? Util.snakeToPascal(row.status).replace('Nao', 'Não')
            : '-'}
        </div>
      ),
    },
    {
      name: 'Data Publicação',
      selector: 'data',
      sortable: true,
      cell: (row) => <div>{moment(moment(row.data)).format('DD/MM/YYYY')}</div>,
    },
    {
      name: 'Assunto',
      selector: 'titulo',
      sortable: true,
    },
    {
      name: 'Autor',
      selector: 'autor_nome',
      sortable: true,
    },

    {
      name: '',
      cell: (row) => (
        <div>
          <Button
            type='button'
            className='btn-acao '
            title='Editar'
            onClick={() => {
              this.props.history.push('/admin/feed-edit', {
                selectedFeed: row,
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className='edit' />
          </Button>
          {localStorage.getItem('perm') === 'secom' && (
            <Button
              type='button'
              className='btn-acao '
              title='Remover'
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className='remove' />
            </Button>
          )}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: 'Registros por Página:',
    rangeSeparatorText: 'de',
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: '#000',
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      data_inicio: '',
      data_fim: '',
      response: '',
      id: '',
      api: Api.baseURL,
      status: '',
      feed: [],
      requesting: false,
      titulo: '',
      autor: '',
      data_publicacao_inicio: '',
      data_publicacao_fim: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    document.addEventListener('keydown', (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        this.handleSubmit(event);
      }
    });
  }

  componentWillMount() {
    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    if (localStorage.getItem("gridfeed")) {
 
      var obj = JSON.parse(localStorage.getItem("gridfeed"));
      this.setState({
        response: obj,
      })
    }
  }


  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: 'Atenção',
        text: 'Deseja realizar a exclusão deste registro?',
        type: 'error',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Cancelar',
      },
      feed: element,
      isConfirmation: true,
    });
  };

  handleDeleteFeed(d) {
    this.callDelFeed(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDelFeed = async (d) => {
    const response = await fetch(Api.baseURL + '/feed/' + d.id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('agToken'),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Notícia deletada com sucesso!",
          type: "success",
        },
        data_publicacao_inicio: '',
        data_publicacao_fim: '',
        id: '',
        titulo: '',
        autor: '',
        status: '',
        response: '',
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Erro ao deletar Notícia.',
          type: 'error',
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  handleChange(event) {
    this.setState({ cpf: cpfMask(event.target.value) });
  }

  handleSubmit(event) {
    if (this.validate()) {
      this.setState({ requesting: true });
      this.fetchFeed()
        .then((res) => this.setState({ response: res }, () => {
          localStorage.setItem("gridfeed", JSON.stringify(res));
        }))
        .then()
        .catch((err) => console.log(err));

      event.preventDefault();
    }
  }

  validate() {
    var dateFormat = 'DD/MM/YYYY';

    if (
      this.state.data_publicacao_inicio !== undefined &&
      this.state.data_publicacao_inicio !== ''
    ) {
      if (
        !moment(this.state.data_publicacao_inicio, dateFormat, true).isValid()
      ) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'Data de publicação inicial inválida',
            type: 'error',
          },
        });
        return false;
      } else {
        this.dataIni = moment(
          moment(this.state.data_publicacao_inicio, 'DD/MM/YYYY')
        ).format('YYYY-MM-DD');
      }
    }

    if (
      this.state.data_publicacao_fim !== undefined &&
      this.state.data_publicacao_fim !== ''
    ) {
      if (!moment(this.state.data_publicacao_fim, dateFormat, true).isValid()) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'Data de abertura final inválida',
            type: 'error',
          },
        });
        return false;
      } else {
        this.dataFim = moment(
          moment(this.state.data_publicacao_fim, 'DD/MM/YYYY')
        ).format('YYYY-MM-DD');
      }
    }

    if (this.dataIni !== undefined && this.dataFim !== undefined) {
      var date1 = moment(this.state.data_publicacao_inicio, 'DD/MM/YYYY');
      var date2 = moment(this.state.data_publicacao_fim, 'DD/MM/YYYY');
      var diff = date2.diff(date1);

      console.log(diff);
      if (diff < 0) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'Data de Início da publicação menor que Data Final.',
            type: 'error',
          },
        });
        return false;
      }
    }

    if (this.state.data_publicacao_inicio === '') {
      this.dataIni = undefined;
    }

    if (this.state.data_publicacao_fim === '') {
      this.dataFim = undefined;
    }

    return true;
  }

  fetchFeed = async () => {
    let params = {
      data_publicacao_inicio: this.dataIni !== undefined ? this.dataIni : '',
      data_publicacao_fim: this.dataFim !== undefined ? this.dataFim : '',
      id: this.state.id,
      titulo: this.state.titulo,
      autor: this.state.autor,
    };

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + '=' + esc(params[k]))
      .join('&');

    let host = this.state.api + '/feed?status=';

    let path =
      this.state.status === '' ? '&' + query : this.state.status + '&' + query;

    const response = await fetch(host + path, {
      headers: {
        Authorization: localStorage.getItem('agToken'),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        feed: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Não foram encontradas notícias para os parâmetros informados.',
          type: 'error',
        },
        requesting: false,
      });
    }

    if (this.state.feed.length > 0) {
      // this.props.history.push("/gerenciarchamadosdetalhe", {
      //   os: this.state.os
      // });
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteFeed(this.state.feed);
            }
          }}
        />
        <Header />

        {/* Page content */}
        <Container className='mb-7 mt-5 ' fluid>
          <Row className='mb-3'>
            <Col className='order-xl-1'>
              <Card className='bg-secondary shadow'>
                <CardBody>
                  <Form>
                    <h6 className='heading-small text-muted mb-4'>
                      Consulta Notícias
                    </h6>
                    <div className='pl-lg-4'>
                      <Row>
                        <Col lg='4'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-username'
                            >
                              Status
                            </label>

                            <select
                              value={this.state.status}
                              onChange={(e) =>
                                this.setState({ status: e.target.value })
                              }
                              className='form-control '
                              id='select-status'
                            >
                              <option defaultValue='' value=''>
                                {' '}
                                Selecione...{' '}
                              </option>

                              <option value='publicado'>Publicado</option>
                              <option value='nao_publicado'>
                                Não Publicado
                              </option>
                              <option value='bloqueado'>Bloqueado</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg='4'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Número Notícia
                            </label>

                            <InputNumber
                              onlydigits
                              className='form-control'
                              id='input-id'
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 5);
                              }}
                              placeholder=''
                              value={this.state.id}
                              onChange={(e) =>
                                this.setState({ id: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg='4'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Título
                            </label>
                            <Input
                              type='text'
                              name='titulo'
                              id='titulo'
                              className='form-control'
                              value={this.state.titulo}
                              onChange={(e) =>
                                this.setState({ titulo: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className='pl-lg-4'>
                      <Row>
                        <Col lg='4'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Autor
                            </label>
                            <Input
                              type='text'
                              name='autor'
                              id='autor'
                              className='form-control'
                              value={this.state.autor}
                              onChange={(e) =>
                                this.setState({ autor: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg='4'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Data Inicial Publicação
                            </label>
                            <InputMask
                              mask='99/99/9999'
                              type='text'
                              inputMode='numeric'
                              name='data_publicacao_inicio'
                              id='data_publicacao_inicio'
                              className='form-control'
                              value={this.state.data_publicacao_inicio}
                              onChange={(e) =>
                                this.setState({
                                  data_publicacao_inicio: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg='4'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Data Final Publicação
                            </label>

                            <InputMask
                              mask='99/99/9999'
                              type='text'
                              inputMode='numeric'
                              name='data_publicacao_fim'
                              id='data_publicacao_fim'
                              className='form-control'
                              value={this.state.data_publicacao_fim}
                              onChange={(e) =>
                                this.setState({
                                  data_publicacao_fim: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className='text-center'>
                      <Row>
                        <Col className='order-xl-1' xl='12'>
                          <Button
                            className='mt-4 btn-zoo-alt'
                            color='primary'
                            type='button'
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                          {localStorage.getItem('perm') === 'coordenador' && (
                            <Button
                              className='mt-4 btn-zoo'
                              color='primary'
                              type='button'
                              onClick={() => {
                                this.props.history.push('/admin/feed-edit');
                              }}
                            >
                              <b>Cadastrar</b>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              sortable={true}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              noDataComponent='Nenhum registro encontrado.'
              paginationComponentOptions={this.defaultComponentOptions}
              customStyles={this.customStyles}
            />
          )}
          {this.state.requesting && (
            <div className='text-center'>
              <Spinner
                style={{ width: '3rem', height: '3rem' }}
                color='primary'
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
