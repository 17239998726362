import React from "react";
import SweetAlert2 from "react-sweetalert2";
import api from "shared/resources/Api";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      senha: "",
      senha2: "",
      showAlert: false,
      alertType: "success",
      alertText: "",
      alertTitle: "",
      alertCallback: "",
      success: false,
      codigo: "",
      cpf: ""
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  componentWillMount() {
   
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.codigo != null
    ) {
      this.setState({
        codigo: this.props.location.state.codigo,
        cpf: this.props.location.state.cpf,
        loading: false
      });
    } else {
    }
  }

  handleChangePassword(event) {
    event.preventDefault()
    if (this.state.senha === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Confirme a sua Nova senha.",
          type: "error"
        }
      });

      return false;
    }

    if (this.state.senha2 === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Confirme a sua Nova senha.",
          type: "error"
        }
      });
      return false;
    }

    if (this.state.senha !== "" && this.state.senha !== "") {
      if (this.state.senha !== this.state.senha2) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "As senhas não são iguais.",
            type: "error"
          }
        });
        return false;
      }
    }

    this.callApi();
  }

  callApi = async () => {
    const response = await fetch(api.baseURL + "/usuario/nova_senha", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        cpf: this.state.cpf.replace(/\D/g, ""),
        codigo_sms: this.state.codigo,
        senha: this.state.senha
      })
    });

    const body = await response.json();
    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Senha alterada com sucesso",
          type: "success"
        },
        isSuccess: true
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: body.erro,
          type: "error"
        }
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao recuperar a senha.",
          type: "error"
        }
      });
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/auth/index");
            }
          }}
        />
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <strong>Alterar Senha</strong>
              </div>
              <Form role="form" onSubmit={this.handleChangePassword}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nova senha"
                      type="password"
                      autoComplete="new-password"
                      value={this.state.senha}
                      onChange={e => this.setState({ senha: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirme a nova senha"
                      type="password"
                      autoComplete="new-password"
                      value={this.state.senha2}
                      onChange={e => this.setState({ senha2: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="mt-4 btn-zoo"
                    color="primary"
                    type="submit"
                    onClick={this.handleChangePassword}
                  >
                    Confirmar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default ChangePassword;
