import React from "react";
import {  Container, Row, Col } from "reactstrap";
import Header from "components/Headers/Header.js";
import faleConosco from "assets/img/fale-conosco.png";
import faleConoscoEmail from "assets/img/fale-conosco-email.png";
import faleConoscoTelefone from "assets/img/fale-conosco-telefone.png";

export default class ContactList extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Container className="main-zoo" fluid>
          <Row className="align-items-center">
            <Col lg="12">
              <img
                   width="600"
                   heigth="600"
                className="img-fluid mx-auto d-block"
                src={faleConosco}
                alt="Imagem Notícia"
              />
            </Col>
          </Row>
          <br />
          <br />
          <div className="header-body">
          <a href="tel:3213-1470">
            <img
              className="img-fluid mx-auto d-block"
              width="400"
              heigth="400"
              src={faleConoscoTelefone}
              alt="Fale Conosco Telefone"
            />
          </a>
          <a href="mailto:falservidor@uberlandia.mg.gov.br">
            <img
            style={{marginTop: "30px"}}
              className="img-fluid mx-auto d-block"
              width="400"
              heigth="400"
              src={faleConoscoEmail}
              alt="Fale Conosco eMAIL"
            />
          </a>

          </div>
        </Container>
      </>
    );
  }
}
