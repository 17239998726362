/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { cpfMask } from "shared/services/Mask";
import api from "shared/resources/Api";
import SweetAlert2 from "react-sweetalert2";
import Util from "shared/services/Util";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { login: "", senha: "", response: "", api: api.baseURL };

    this.handleChange = this.handleChange.bind(this);

    this.handleChangePass = this.handleChangePass.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  handleChange(event) {
    this.setState({ login: cpfMask(event.target.value) });
  }

  handleChangePass(event) {
    this.setState({ senha: event.target.value });
  }

  handleSubmitForm(e) {
    e.preventDefault();

    if (this.state.login === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o CPF.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.senha === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Senha.",
          type: "warning",
        },
      });

      return false;
    }

    if (Util.validaCPF(this.state.login.replace(/\D/g, ""))) {
      this.callApi()
        .then((res) => this.setState({ response: res.express }))
        .catch((err) => console.log(err));
      //   event.preventDefault();
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "CPF inválido ou vazio.",
          type: "error",
        },
      });
    }
  }

  handleSubmit(e) {
    //  e.preventDefault()

    if (this.state.login === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o CPF.",
          type: "error",
        },
      });

      return false;
    }

    if (this.state.senha === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Senha.",
          type: "error",
        },
      });

      return false;
    }

    if (Util.validaCPF(this.state.login.replace(/\D/g, ""))) {
      this.callApi()
        .then((res) => this.setState({ response: res.express }))
        .catch((err) => console.log(err));
      //   event.preventDefault();
    } else {
      alert("CPF inválido ou vazio.");
    }
  }

  callApi = async () => {
    const response = await fetch(api.baseURL + "/usuario/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cpf: this.state.login,
        senha: this.state.senha,
      }),
    });
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      localStorage.setItem("agToken", body.token);
      localStorage.setItem("perm", body.tipo);
      localStorage.setItem("code", this.state.login);
      //  Auth.authenticate();
      this.props.history.push("/admin/index", {
        tipo: body.tipo,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: body.erro,
          type: "error",
        },
      });
    } else {
      alert("Erro ao autenticar usuário");
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: body.erro,
          type: "error",
        },
      });
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
          }}
        />
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={this.handleSubmitForm}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="CPF"
                      name="cpf"
                      type="text"
                      autoComplete="new-cpf"
                      value={this.state.login}
                      onChange={this.handleChange}
                      inputMode="numeric"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Senha"
                      name="senha"
                      type="password"
                      autoComplete="new-password"
                      value={this.state.senha}
                      onChange={this.handleChangePass}
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button color="primary" type="submit">
                    Entrar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                style={{ cursor: "pointer" }}
                className="text-light"
                onClick={() => this.props.history.push("/auth/forgot-password")}
              >
                <small>Esqueceu a senha?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                style={{ cursor: "pointer" }}
                className="text-light"
                onClick={() => this.props.history.push("/auth/register")}
              >
                {/* <small>Criar Conta</small> */}
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
