const AuthService = {
  isAuthenticated: false,
  authenticate() {
    this.isAuthenticated = true;
  },
  signout() {
    sessionStorage.removeItem('avatar');
    this.isAuthenticated = false;
  },
  getAuth() {


    if (localStorage.getItem("agToken")) {
       this.authenticate()
    }
    return this.isAuthenticated;
  }
};
export default AuthService;
