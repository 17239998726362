import React from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "components/Headers/Header.js";
import prefeituraUberlandia from "assets/img/prefeitura-uberlandia.jpg";
import contraCheque from "assets/img/contra-cheque.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";

export default class GovEmployeeList extends React.Component {
    render() {
        return (
            <>
                <Header />
                <Container className="main-zoo" fluid>
                    <Row className="align-items-center">
                        <Col lg="12">
                            <img
                                width="600"
                                heigth="600"
                                className="img-fluid mx-auto d-block"
                                src={prefeituraUberlandia}
                                alt="Imagem Notícia"
                            />
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <div className="header-body">
                        <a href="http://portaludi.uberlandia.mg.gov.br:4000/#/login" target="_blank"
                        rel="noopener noreferrer" className="btn-vacation img-fluid mx-auto d-block">
                            <div className="icon-container"> <FontAwesomeIcon icon={faUmbrellaBeach}></FontAwesomeIcon></div>
                            <span>
                               Requerimento de Férias
                                <br />
               Acesse Aqui
           </span>
                        </a>
                        <a href="http://portaludi.uberlandia.mg.gov.br:4000/#/login" target="_blank"
                        rel="noopener noreferrer">
                            <img
                                style={{ marginTop: "30px" }}
                                className="img-fluid mx-auto d-block"
                                width="400"
                                heigth="400"
                                src={contraCheque}
                                alt="Fale Conosco eMAIL"
                            />
                        </a>

                    </div>
                </Container>
            </>
        );
    }
}
