/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import AuthService from "shared/services/AuthService";
import Api from "shared/resources/Api";
import AvatarService from "shared/services/AvatarService";
class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      cpf: undefined,
      user: undefined,
      api: Api.baseURL,
      routes: undefined,
    };
    tipo: "";
  }
  componentDidMount() {
    const cpf = localStorage.getItem("code")
      ? localStorage.getItem("code").replace(/\D/g, "")
      : undefined;
    if (cpf) {
      this.fetchUser(cpf)
        .then((res) => {
  
          let user = res;
          this.setState({ user: user });
        })
        .catch((err) => console.log(err));
        AvatarService.getAvatar();
    }
  }

  fetchUser = async (cpf) => {
    let host = this.state.api + "/usuario/info";

    const response = await fetch(host, {
      headers: {
        Authorization: localStorage.getItem("agToken")
      }
    });

    const body = await response.json();

    return body;
  };

  logout() {
    localStorage.removeItem("agToken");
    AuthService.signout();
  }

  myProfile()
  {
    this.props.history.push(`/admin/profile`);
    
  }
  myPassword()
  {
    this.props.history.push(`/admin/password`);
    
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.toggleSubItem(this.props.routes);
    this.setState({
      collapseOpen: false,
    });
  };

  changeMenu = (id, isOpen) => {
    if (document.getElementById(id)) {
      document.getElementById(id).removeAttribute("class");
      document.getElementById("icon-right-" + id).removeAttribute("class");
      document.getElementById("icon-down-" + id).removeAttribute("class");

      document
        .getElementById(id)
        .setAttribute("class", "collapse " + (isOpen ? "" : "show"));
      document
        .getElementById("icon-right-" + id)
        .setAttribute(
          "class",
          "svg-inline--fa fa-chevron-right fa-w-10 chev-sidebar " +
            (isOpen ? "" : "ico-hide")
        );
      document
        .getElementById("icon-down-" + id)
        .setAttribute(
          "class",
          "svg-inline--fa fa-chevron-right fa-w-10 chev-sidebar " +
            (isOpen ? "ico-hide" : "")
        );
    }
  };

  toggleSubItem = (routes, id) => {
    routes.map((prop, key) => {
      if (prop.subItem && prop.subItem.length > 0) {
        if (prop.path !== id) {
          this.changeMenu(prop.path, true);
        }
      }
    });
    if (id && id.length > 0) {
      const className = JSON.parse(
        JSON.stringify(document.getElementById(id).className)
      );
      this.changeMenu(id, className.indexOf("show") > -1);
    }
  };

  // creates the links that appear in the left menu / Sidebar
  createSubItem = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.isMenu &&
        (!prop.permissions ||
          (prop.permissions &&
            prop.permissions.includes(localStorage.getItem("perm"))))
      )
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={this.closeCollapse}
              activeClassName="active"
              className="logo-menu"
            >
              {prop.name}
            </NavLink>
          </NavItem>
        );
    });
  };
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.isMenu &&
        (!prop.permissions ||
          (prop.permissions &&
            prop.permissions.includes(localStorage.getItem("perm"))))
      ) {
     
        if (prop.subItem && prop.subItem.length > 0) {
          return (
            <NavItem key={key}>
              <NavLink
                to="@"
                tag={NavLinkRRD}
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleSubItem(routes, prop.path);
                }}
                className="logo-menu"
              >
                <FontAwesomeIcon
                  className="logo-sidebar"
                  icon={prop.icon}
                ></FontAwesomeIcon>
                {prop.name}
                <FontAwesomeIcon
                  className="chev-sidebar ico-hide"
                  id={"icon-down-" + prop.path}
                  icon={faChevronDown}
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  className="chev-sidebar "
                  id={"icon-right-" + prop.path}
                  icon={faChevronRight}
                ></FontAwesomeIcon>
              </NavLink>
              <Collapse id={prop.path} isOpen={false}>
                <Nav navbar className="sub-navbar">
                  {this.createSubItem(prop.subItem)}
                </Nav>
              </Collapse>
            </NavItem>
          );
        } else {
          return (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                activeClassName="active"
                className="logo-menu"
              >
                <FontAwesomeIcon
                  className="logo-sidebar"
                  icon={prop.icon}
                ></FontAwesomeIcon>
                {prop.name}
              </NavLink>
            </NavItem>
          );
        }
      }
    });
  };
  render() {
    const { routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-sidebar"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <h1>Menu</h1>
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                  {AvatarService.avatar !== '' && (
                          <img
                            alt='...'                           
                            src={AvatarService.avatar}
                          />
                        )}
                        {AvatarService.avatar === '' && (
                          <img
                            alt='...'                            
                            src={require('assets/img/brand/user.png')}
                          />
                        )}
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem Vindo!</h6>
                </DropdownItem>
                <DropdownItem to="/" onClick={() => this.myProfile()} >
                  <i className="ni ni-single-02" />
                  <span>Meu Perfil</span>
                </DropdownItem>
                <DropdownItem to="/"onClick={() => this.myPassword()}>
                    <i className="ni ni-lock-circle-open" />
                    <span>Alterar Senha</span>
                  </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={() => this.logout()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    <h1>Menu</h1>
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>

            {/* Navigation */}
            <Nav navbar className="main-navbar">
              {this.createLinks(routes)}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
