import Api from "shared/resources/Api";

export const workersByTeam = async (team) => {
  const response = await fetch(Api.baseURL + "/usuario?equipe=" + team, {
    headers: {
      Authorization: localStorage.getItem("agToken"),
    },
  });

  return await response.json();
};

