const Api = {
  baseURL:
//'https://zoonoses-api-hml.uberlandia.mg.gov.br'
   'api.udisemdengue'
    // 'https://udisemdengue-api-hml.uberlandia.mg.gov.br'
       //'http://localhost:8000'       
};

console.log("envmode", Api.baseURL);
export default Api;
 