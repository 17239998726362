
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

class Admin extends React.Component {
  componentWillMount() {
    localStorage.removeItem("gridpool")
    localStorage.removeItem("gridovi")
    localStorage.removeItem("gridowner")
    // localStorage.removeItem("gridimov")
    localStorage.removeItem("gridtire")
    localStorage.removeItem("gridbio")
    localStorage.removeItem("gridovilab")
    localStorage.removeItem("gridpoint")
    localStorage.removeItem("gridubv")
    localStorage.removeItem("gridrone")
    localStorage.removeItem("gridcroqui")
    localStorage.removeItem("gridciti")
    localStorage.removeItem("gridos")
    localStorage.removeItem("griditi")
    localStorage.removeItem("gridfeed")
    localStorage.removeItem("gridage")
    localStorage.removeItem("excel");
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.subItem && prop.subItem.length > 0)
          return this.getRoutes(prop.subItem);
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path, routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].subItem && routes[i].subItem.length > 0) {

        let brand = ""
        
        // const brand = this.getBrandText(path, routes[i].subItem);
        console.log(path, routes[i].subItem);
        if (typeof routes[i].subItem.length > 0) {
           brand = this.getBrandText(path, routes[i].subItem.name);
        } else {
           brand = this.getBrandText(path, routes[i].subItem);
        }

        if(path === '/admin/ticket-new'){
          brand = 'CADASTRAR O.S.'
        }

  
        if ("Brand" !== brand)
          return brand
      }
      if (
        path.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("assets/img/brand/argon-react.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname, routes)}
            backPath={this.props.location.pathname}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
