import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SweetAlert2 from "react-sweetalert2";
import InputMask from "react-input-mask";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";

import Util from "shared/services/Util";
import { cnpjMask } from "shared/services/Mask";
import Api from "shared/resources/Api";
import InputNumber from "react-input-just-numbers";
import { equipes } from "shared/services/PlacesService";

import Header from "components/Headers/Header.js";

export default class EmpresaEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nr_cnpj: "",
      nome: "",
      matricula: "",
      email: "",
      cel: "",
      senha: "",
      senha2: "",
      data_inicio: "",
      data_fim: "",
      ds_inatividade: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      situacao_funcional: "",
      habilidades_especificas: "",
      formacao: null,
      vinculo: null,
      habilitacao: null,
      tipo: "",
      equipes: [],
      equipe: null,
      api: Api.baseURL,
      isEdicao: false,
      formTitle: "Listar",
      maskednr_cnpj: "",
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount() {
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.selectedAgent != null
    ) {
      this.setState(
        {
          selectedAgent: this.props.location.state.selectedAgent,
          agent: this.props.location.state.os,
          id: this.props.location.state.selectedAgent.id,
          loading: false,
          nr_cnpj: this.props.location.state.selectedAgent.nr_cnpj,
          ds_razao_fornecedor_servico: this.props.location.state.selectedAgent.ds_razao_fornecedor_servico,
          nr_telefone: this.props.location.state.selectedAgent.nr_telefone,
          nr_cep: this.props.location.state.selectedAgent.nr_cep,
          nm_bairro: this.props.location.state.selectedAgent.nm_bairro,
          ds_email: this.props.location.state.selectedAgent.ds_email,
          tp_ativo: this.props.location.state.selectedAgent.tp_ativo,
          matricula: this.props.location.state.selectedAgent.matricula,
          equipe: this.props.location.state.selectedAgent.equipe,
          formacao: this.props.location.state.selectedAgent.formacao,
          habilitacao: this.props.location.state.selectedAgent.habilitacao,
          vinculo: this.props.location.state.selectedAgent.vinculo,
          habilidades_especificas: this.props.location.state.selectedAgent.habilidades_especificas,
          situacao_funcional: this.props.location.state.selectedAgent.situacao_funcional,
          logradouro: this.props.location.state.selectedAgent.nm_logradouro,
          bairro: this.props.location.state.selectedAgent.nm_bairro,
          numero: this.props.location.state.selectedAgent.nr_numero,
          complemento: this.props.location.state.selectedAgent.nm_logradouro,
          email: this.props.location.state.selectedAgent.ds_email,
          isEdicao: true,
          formTitle: "EDITAR",
          maskednr_cnpj: "a"
        },
        () => console.log("state logou end")
      );
    }
    equipes().then((res) => {
      if (res) {
        this.setState({
          equipes: res,
        });
      }
    });
  }

  fetchEquipes = async () => {
    const response = await fetch(Api.baseURL + "/unidade_saude/equipe", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };


  handleSave(event) {
    if (this.validateFields()) {
      this.callSave();
    }
  }

  handleInputChange = (e) => {
    // Use a regular expression to remove non-numeric characters
    const numericValue = e.target.value.replace(/\D/, "");
    // numericValue = numericValue.slice(0, 6);
    
    
    // Update the state with the numeric value
    this.setState({ matricula: numericValue });
  };



  validateFields() {
    /* Validação obrigatoriedade */
    // if (this.state.nome === undefined || this.state.nome.length === 0) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe o Nome Usuário.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }
    // if (
    //   this.state.nr_cnpj === undefined ||
    //   this.state.nr_cnpj.replace(/\D/g, "").length === 0
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe o nr_cnpj.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }
    // if (
    //   this.state.cel === undefined ||
    //   this.state.cel.replace(/\D/g, "").length === 0
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe o Celular.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (
    //   !this.state.matricula ||
    //   this.state.matricula === "" ||
    //   this.state.matricula.replace(/\D/g, "").length === 0
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe a Matrícula.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (this.state.tipo === undefined || this.state.tipo.length === 0) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe a Função.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (this.state.equipe === undefined || this.state.equipe === null) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe a equipe.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    if (
      this.state.ds_email === undefined ||
      this.state.ds_email === null
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o email.",
          type: "warning",
        },
      });

      return false;
    }

    // if (this.state.formacao === undefined || this.state.formacao === null) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe a formacao.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (this.state.habilitacao === undefined || this.state.habilitacao === null ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe a habilitacao.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (this.state.situacao_funcional === undefined || this.state.situacao_funcional === null) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe a Situação funcional.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (this.state.vinculo === undefined || this.state.vinculo === null ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe o vinculo.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }


    // if (
    //   this.state.habilidades_especificas === undefined || this.state.habilidades_especificas === null ||
    //   this.state.habilidades_especificas === ""
     
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe pelo menos 1 Habilidade Específica.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (
    //   this.state.logradouro === undefined ||
    //   this.state.logradouro === ""
      
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe o logradouro no Endereço.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (
    //   this.state.numero === undefined ||
    //   this.state.numero === ""
      
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe o numero no Endereço.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (
    //   this.state.bairro === undefined ||
    //   this.state.bairro === ""
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe o bairro no Endereço.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }


    /* Validação obrigatoriedade */

    /* Validação integridade */
    // if (
    //   this.state.nr_cnpj !== "" &&
    //   !Util.validanr_cnpj(this.state.nr_cnpj.replace(/\D/g, ""))
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "nr_cnpj inválido",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }
    if (
      this.state.ds_email &&
      this.state.ds_email !== "" &&
      !Util.validateEmail(this.state.ds_email)
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "E-mail inválido",
          type: "warning",
        },
      });

      return false;
    }
    // if (
    //   this.state.cel !== "" &&
    //   this.state.cel.replace(/\D/g, "").length !== 11
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Preencha o celular com DDD e nono dígito.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (!this.state.isEdicao && this.state.senha === "") {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe a Senha.",
    //       type: "warning",
    //     },
    //   });
    //   return false;
    // }
    // if (!this.state.isEdicao && this.state.senha2 === "") {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Confirme a Senha.",
    //       type: "warning",
    //     },
    //   });
    //   return false;
    // }

    // if (this.state.senha !== "" && this.state.senha2 !== "") {
    //   if (this.state.senha !== this.state.senha2) {
    //     this.setState({
    //       swal: {
    //         show: true,
    //         title: "Atenção",
    //         text: "As senhas não são iguais.",
    //         type: "warning",
    //       },
    //     });

    //     return false;
    //   }
    // }
    /* Validação integridade */

    return true;
  }

  handleTipo(event) {
    this.setState({ tipo: event.target.value }, () =>
      console.log(this.state.tipo)
    );
  }

  handleFormacao(event) {
    this.setState({ formacao: event.target.value }, () =>
      console.log(this.state.formacao)
    );
  }

  handleVinculo(event) {
    this.setState({ tp_ativo: event.target.value }, () =>
      console.log(this.state.tp_ativo)
    );
  }

  handleFuncional(event) {
    this.setState({ situacao_funcional: event.target.value }, () =>
      console.log(this.state.situacao_funcional)
    );
  }

  handleHabilitacao(event) {
    this.setState({ habilitacao: event.target.value }, () =>
      console.log(this.state.habilitacao)
    );
  }

  handleEquipe(event) {
    this.setState({ equipe: event.target.value }, () =>
      console.log(this.state.equipe)
    );
  }

  createBody = () => {
    if (this.state.selectedAgent) {
      return JSON.stringify({
        fornecedores: {
        nr_cnpj:  "",
        ds_razao_fornecedor_servico:
          this.state.ds_razao_fornecedor_servico !== ""
            ? this.state.ds_razao_fornecedor_servico
            : this.state.selectedAgent.ds_razao_fornecedor_servico,
         nr_cep: "",
        
        nm_logradouro:
          this.state.complemento !== ""
            ? this.state.complemento
            : this.state.selectedAgent.complemento,
        ds_email: this.state.ds_email,
        nr_numero:"",
        tp_ativo: this.state.tp_ativo !== ""
        ? this.state.tp_ativo
        : this.state.selectedAgent.tp_ativo,
        //     ? this.state.cel.replace(/\D/g, "")
        //     : "",
        // tipo:
        //   this.state.tipo !== ""
        //     ? this.state.tipo
        //     : this.state.selectedAgent.tipo,
        // situacao_funcional:
        //   this.state.situacao_funcional !== ""
        //     ? this.state.situacao_funcional
        //     : this.state.selectedAgent.situacao_funcional,

        // vinculo:
        //   this.state.vinculo !== ""
        //     ? this.state.vinculo
        //     : this.state.selectedAgent.vinculo,

        // formacao:
        //   this.state.formacao !== ""
        //     ? this.state.formacao
        //     : this.state.selectedAgent.formacao,

        // habilitacao:
        //   this.state.habilitacao !== ""
        //     ? this.state.habilitacao
        //     : this.state.selectedAgent.habilitacao,

        // habilidades_especificas:
        //   this.state.habilidades_especificas !== ""
        //     ? this.state.habilidades_especificas
        //     : this.state.selectedAgent.habilidades_especificas,

        // endereco: {
        //   logradouro: this.state.logradouro.trim(),
        //   bairro: this.state.bairro,
        //   numero: this.state.numero,
        //   complemento: this.state.complemento,
        //   },
        // inicio_inatividade:
        //   this.state.data_inicio !== ""
        //     ? this.state.data_inicio
        //     : " ",
          
        // fim_inatividade:
        //   this.state.data_fim !== ""
        //     ? this.state.data_fim
        //     : " ",

        // descricao_inatividade:
        //   this.state.ds_inatividade !== ""
        //     ? this.state.ds_inatividade
        //     : " ",
      },
      });
    } else {
      return JSON.stringify({
        nr_cnpj: "",
        nome: this.state.nome.trim(),
        matricula: this.state.matricula,
        email: this.state.email,
        cel: "",
        tipo: this.state.tipo,
        equipe: this.state.equipe ? this.state.equipe : null,
        senha: this.state.senha,
        habilidades_especificas: this.state.habilidades_especificas ? this.state.habilidades_especificas : null,
        formacao: this.state.formacao ? this.state.formacao : null,
        vinculo: this.state.vinculo ? this.state.vinculo: null,
        habilitacao: this.state.habilitacao ? this.state.habilitacao : null,
        situacao_funcional: this.state.situacao_funcional ? this.state.situacao_funcional : null,
        endereco: {
          logradouro: this.state.logradouro.trim(),
          bairro: this.state.bairro.trim(),
          numero: this.state.numero,
          complemento: this.state.complemento.trim(),
        },
      });
    }
  };

  callSave = async () => {
    // var data = new FormData();
    // console.log('loggg', this.createBody())
    // data.append("fornecedores", this.createBody());
    const data = this.createBody();
    const response = await fetch(
      Api.baseURL +
      "/Fornecedores/" +
      (this.state.selectedAgent
        ? this.state.selectedAgent.id
        : ""),
      {
        method: this.state.selectedAgent ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: data,
      }
    );

    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: this.state.selectedAgent
            ? "Edição realizada com sucesso!"
            : "Cadastro realizado com sucesso!",
          type: "success",
        },
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: body.erro,
          type: "warning",
        },
      });
      console.log(body.erro);
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.selectedAgent
            ? "Erro ao editar empresa. "
            : "Erro ao cadastrar usuário.",
          type: "warning",
        },
      });
      console.log(body.erro);
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/empresalist");
            }
          }}
        />
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {this.state.formTitle} Empresa
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="9">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-nome"
                            >
                              Razão Social
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-nome"
                              type="text"
                              disabled={this.state.isEdicao}
                              value={this.state.ds_razao_fornecedor_servico}
                              onChange={(e) =>
                                this.setState({ ds_razao_fornecedor_servico: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-nr_cnpj"
                            >
                              CNPJ 
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-nr_cnpj"
                              inputMode="numeric"
                              type="text"
                              
                              disabled={this.state.isEdicao}
                              value={
                               this.state.nr_cnpj
                              }
                              onChange={(e) =>
                                this.setState({ nr_cnpj: cnpjMask(e.target.value) })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Telefone 
                            </label>
                            <InputMask
                              mask="(99) 99999-9999"
                              type="text"
                              name="cel"
                              inputMode="numeric"
                              id="cel"
                              className="form-control"
                              disabled={this.state.isEdicao}
                              value={this.state.nr_telefone}
                              onChange={(e) =>
                                this.setState({ nr_telefone: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Cep 
                            </label>
                            <InputMask
                              mask="99999-999"
                              type="text"
                              name="cep"
                              inputMode="numeric"
                              id="cep"
                              className="form-control"
                              disabled={this.state.isEdicao}
                              value={this.state.nr_cep}
                              onChange={(e) =>
                                this.setState({ nr_cep: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Bairro 
                            </label>
                            <Input
                              
                              type="text"
                              name="bairro"
                              inputMode="numeric"
                              id="bairro"
                              className="form-control"
                              disabled={this.state.isEdicao}
                              value={this.state.nm_bairro}
                              onChange={(e) =>
                                this.setState({ nm_bairro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Status
                            </label>

                            <select
                              value={this.state.tp_ativo}
                              onChange={(e) => {
                                this.handleVinculo(e);
                              }}
                              className="form-control "
                              
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="A">Ativo</option>
                              <option value="I">Inativo</option>
                              
                            </select>
                          </FormGroup>
                        </Col>

                       
                       
                       
                    
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              E-mail
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              type="email"
                              autoComplete="new-email"
                              required
                              
                              value={this.state.ds_email}
                              onChange={(e) =>
                                this.setState({ ds_email: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    
                    
                      <Row>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-endereco"
                            >
                              Endereço
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-endereco"
                              type="text"
                              required
                              disabled={this.state.isEdicao}
                              value={this.state.logradouro}
                              onChange={(e) =>
                                this.setState({ logradouro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-num"
                            >
                              Nº
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-num"
                              type="text"
                              required
                              disabled={this.state.isEdicao}
                              value={this.state.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                   
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-comp"
                            >
                              Complemento
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-comp"
                              type="text"
                              required
                              disabled={this.state.isEdicao}
                              value={this.state.complemento}
                              onChange={(e) =>
                                this.setState({ complemento: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                         
                      </Row>
                     
                      
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          <Button
                            className="mt-4 btn-zoo"
                            color="primary"
                            type="button"
                            onClick={this.handleSave}
                          >
                            <b>
                              {this.state.isEdicao
                                ? "Salvar Alterações"
                                : "Cadastrar"}
                            </b>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
