import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert2 from "react-sweetalert2";
import InputMask from "react-input-mask";
import moment from "moment";
import Api from "shared/resources/Api";
import ImageUploader from "react-images-upload";

import Header from "components/Headers/Header.js";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default class FeedEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo: "",
      texto: "",
      status: "nao_publicado",
      api: Api.baseURL,
      isEdicao: false,
      foto1: "",
      data: "",
      feed: [],
      formTitle: "CADASTRAR",
      file: null,
      requesting: false,
      photoIndex: 0,
      isOpen: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentWillMount() {
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.selectedFeed != null
    ) {
      this.setState({
        selectedFeed: this.props.location.state.selectedFeed,
        feed: this.props.location.state.selectedFeed,
        loading: false,
        titulo: this.props.location.state.selectedFeed.titulo,
        texto: this.props.location.state.selectedFeed.texto,
        status: this.props.location.state.selectedFeed.status,
        data: this.props.location.state.selectedFeed.data,
        id: this.props.location.state.selectedFeed.id,
        foto1: this.props.location.state.selectedFeed.media?
        `${this.props.location.state.selectedFeed.media.foto1}?${moment().format('YYYYMMDDHHMMSS')}`
        :"",
        isEdicao: true,
        formTitle: "EDITAR",
      });
    }
  }

  handleSave() {
    if (this.state.titulo === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Título da Notícia",
          type: "warning",
        },
      });

      return;
    }

    if (this.state.texto === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Conteúdo da Notícia",
          type: "warning",
        },
      });

      return;
    }

    if (this.state.foto1 === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Faça o upload da foto da Notícia.",
          type: "warning",
        },
      });

      return;
    }
    this.setState({ requesting: true });
    this.callApi();
  }

  callApi = async () => {
    var news = {
      titulo: this.state.titulo,
      texto: this.state.texto,
      status: this.state.status
    };

    var data = new FormData();
    data.append("news", JSON.stringify(news));

    if (this.state.file !== null) {
      data.append("foto1", this.state.file);
    }
    console.log('file',this.state.file)
    let response = "";
    if (this.state.id > 0) {
      response = await fetch(Api.baseURL + "/feed/" + this.state.id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: data,
      });
    } else {
      response = await fetch(Api.baseURL + "/feed/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: data,
      });
    }

    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: this.state.selectedFeed
            ? "Edição realizada com sucesso!"
            : "Cadastro realizado com sucesso!",
          type: "success",
        },
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.selectedFeed
            ? "Erro ao editar notícia. "
            : "Erro ao cadastrar notícia.",
          type: "warning",
        },
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.selectedFeed
            ? "Erro ao editar notícia. "
            : "Erro ao cadastrar notícia.",
          type: "warning",
        },
      });
    }

    return body;
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      foto1: pictureDataURLs[pictureDataURLs.length - 1],
      pictures: [],
      file: pictureFiles[pictureFiles.length - 1],
    });
  }

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/feed");
            }
          }}
        />
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.state.foto1}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {this.state.formTitle} Notícia
                    </h6>
                    <div className="pl-lg-4">
                      {this.state.id > 0 && (
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-nome"
                              >
                                Número
                              </label>

                              <Input
                                className="form-control-alternative"
                                id="input-id"
                                type="number"
                                placeholder=""
                                disabled
                                required
                                value={this.state.id}
                                onChange={(e) =>
                                  this.setState({ id: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-dtnascimento"
                              >
                                Data Publicação
                              </label>
                              <InputMask
                                mask="99/99/9999"
                                type="text"
                                name="cel"
                                disabled
                                id="cel"
                                className="form-control"
                                value={moment(moment(this.state.data)).format(
                                  "DD/MM/YYYY"
                                )}
                                onChange={(e) =>
                                  this.setState({
                                    data: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Status
                              </label>

                              <select
                                value={this.state.status}
                                onChange={(e) =>
                                  this.setState({ status: e.target.value })
                                }
                                className="form-control "
                                id="select-status"
                              >
                                <option defaultValue="" value="">
                                  {" "}
                                  Selecione...{" "}
                                </option>

                                <option value="publicado">Publicada</option>
                                <option value="nao_publicado">
                                  Não Publicada
                                </option>
                                <option value="bloqueado">Bloqueado</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-nome"
                            >
                              Título *
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-titulo"
                              type="text"
                              placeholder=""
                              required
                              value={this.state.titulo}
                              onChange={(e) =>
                                this.setState({ titulo: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-dtnascimento"
                            >
                              Conteúdo *
                            </label>
                            <Input
                              id="input-conteudo"
                              placeholder="Conteúdo da Notícia"
                              value={this.state.texto}
                              onChange={(e) =>
                                this.setState({
                                  texto: e.target.value,
                                })
                              }
                              rows="10"
                              type="textarea"
                              className="form-control-alternative"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Foto *
                            </label>
                            <ImageUploader
                              withIcon={false}
                              withLabel={false}
                              buttonText=""
                              onChange={this.onDrop}
                              imgExtension={[".jpg", ".jpeg", "png"]}
                              fileSizeError=" O arquivo deve ter até 5mb"
                              fileTypeError=" Extensão do arquivo não suportada"
                              maxFileSize={5242880}
                              buttonClassName={"zoo-feed-photo"}
                            />{" "}
                          </FormGroup>
                        </Col>
                      </Row>
                      {this.state.foto1 !== "" && (
                        <Row className="text-center">
                          <Col lg="12">
                            <FormGroup>
                              {this.state.foto1 !== "" && (
                                <img
                                  style={{ cursor: "pointer" }}
                                  className="img-fluid"
                                  src={this.state.foto1}
                                  alt="Imagem Notícia"
                                  onClick={() =>
                                    this.setState({ isOpen: true })
                                  }
                                />
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </div>
                    {localStorage.getItem("perm") === "coordenador" && (
                      <div className="text-center">
                        <Row>
                          <Col className="order-xl-1" xl="12">
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={this.handleSave}
                            >
                              <b>
                                {" "}
                                {this.state.isEdicao
                                  ? "Salvar Alterações"
                                  : "Cadastrar"}
                              </b>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
