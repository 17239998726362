
import React from "react";
class UserHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className=""
          style={{
            minHeight: "60px",
          }}
        >
          {/* Mask */}
          {/* Header container */}
        </div>
      </>
    );
  }
}

export default UserHeader;
