import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import logoZoo from "assets/img/logo-zoo.png";


// core components

import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";

class Auth extends React.Component {
  
  componentDidMount() {
    document.body.classList.add("bg-login");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-login");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content bg-login">
          <div className="header py-2  ">
            <Container>
              <div className="header-body text-center mb-7 mt-5">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <img
                       src={logoZoo}
                      width="250"
                      alt="logo udi sem dengue"
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          {/* Page content */}
          <Container className="mt--6 pb-5">
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
