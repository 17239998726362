
import React from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";



import Header from "components/Headers/Header.js";
import logoZoo from "assets/img/logo-zoo.png";

class Index extends React.Component {
 
 
  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container>
              <div className="header-body text-center mb-7 mt-5">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <img
                      src={logoZoo}
                      width="300"
                      alt="logo udi sem dengue"
                    />
                  </Col>
                </Row>
              </div>
            </Container>
      </>
    );
  }
}

export default Index;
