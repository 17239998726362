import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Header from "components/Headers/Header.js";

import Api from "shared/resources/Api";
import { sectors, neighborhood } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";
import Util from "shared/services/Util";

export default class DroneList extends React.Component {
  /* Dados Grid */
  columns = [
    {
      name: "Código",
      maxWidth: "50px",
      selector: "id",
    },
    {
      name: "Situação",
      selector: "situacao",
    },
    {
      name: "Criadouro",
      selector: "criadouro",
      sortable: true,
      cell: (row) => (
        <div>
          {Util.snakeToPascal(row.criadouro)}
        </div>
      ),
    },
    {
      name: "Bairro",
      selector: "bairro_nome",
    },
    {
      name: "Lado",
      selector: "lado",
      cell: (row) => (
        <div>
          {row.lado !== undefined && row.lado !== null
            ? row.lado
            : "-"}
        </div>
      ),
    },
    {
      name: "Quarteirão",
      selector: "quarteirao",
      cell: (row) => (
        <div>
          {row.quarteirao !== undefined && row.quarteirao !== null && row.quarteirao !== 0
            ? row.quarteirao
            : "-"}
        </div>
      ),
    },
    {
      name: "",
      center: true,
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              this.props.history.push("/admin/drone-edit", {
                drone: row,
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {localStorage.getItem("perm") === "coordenador" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      response: "",
      id: "",
      situacao: "",
      lado: "",
      quarteirao: "",
      criadouro: "",
      api: Api.baseURL,
      requesting: false,
      neighborhood: [],
      sectors: [],
      selectedSector: null,
      selectedNeighborhood: null,
    };
  }
  componentWillMount() {
    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    if (localStorage.getItem("gridrone")) {
 
      var obj = JSON.parse(localStorage.getItem("gridrone"));
      this.setState({
        response: obj,
      })
    }

    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });

    sectors().then((res) => {
      if (res) {
        this.setState({
          sectors: res,
        });
      }
    });
  }

  fetchSectors = async () => {
    const response = await fetch(Api.baseURL + "/setor", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };

  fetchNeighborhood = async () => {
    const response = await fetch(Api.baseURL + "/bairro", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        neighborhood: body,
      });
    }

    return body;
  };

  handleSubmit = (event) => {
    this.setState({ response: "", requesting: true });

    this.fetchDrone()
      .then((res) => this.setState({ response: res }, () => {
        localStorage.setItem("gridrone", JSON.stringify(res));
      }))
      .catch((err) => {
        console.log(err);
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text:
              "Não foram encontrados drones para os parâmetros informados.",
            type: "error",
          },
          requesting: false,
        });
      });

    event.preventDefault();
  };

  fetchDrone = async () => {
    let host = this.state.api + "/drone?";

    let params = {
      situacao: this.state.situacao,
      lado: this.state.lado,
      quarteirao: this.state.quarteirao,
      id: this.state.id,
      criadouro: this.state.criadouro,
      bairro_id: this.state.selectedNeighborhood ? this.state.selectedNeighborhood : ""
    };
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        agent: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text:
            "Não foram encontrados drones para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };
  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "error",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      trieshop: element,
      isConfirmation: true,
    });
  };
  handleDeleteDrone(d) {
    this.callDel(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDel = async (d) => {
    const response = await fetch(Api.baseURL + "/drone/" + d.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Drone deletado com sucesso!",
          type: "success",
        },
        response: "",
        situacao: "",
        criadouro: "",
        selectedNeighborhood: "",
        lado: "",
        quarteirao: "",
        id: ""
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar drone.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteDrone(this.state.trieshop);
            }
          }}
        />

        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Consulta Drone
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Código
                            </label>
                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-id"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder=""
                              value={this.state.id}
                              onChange={(e) =>
                                this.setState({ id: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Situação
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-criadouro"
                              type="text"
                              maxLength={100}
                              required
                              value={this.state.situacao}
                              onChange={(e) =>
                                this.setState({ situacao: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Criadouro
                            </label>

                            <select
                              value={this.state.criadouro}
                              onChange={(e) =>
                                this.setState({ criadouro: e.target.value })
                              }
                              className="form-control "
                              id="select-tipo"
                            >
                              <option defaultValue="" value="">
                                {" "}
                                Selecione...{" "}
                              </option>
                              <option value="piscinas">Piscinas</option>
                              <option value="caixa_dagua">Caixa Dagua</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            {this.state.neighborhood.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Bairro
                                </label>

                                <select
                                  value={this.state.selectedNeighborhood}
                                  onChange={(e) =>
                                    this.setState({
                                      selectedNeighborhood: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>

                                  {this.state.neighborhood.map((team) => (
                                    <option
                                      key={team.id}
                                      value={team.id}
                                      defaultValue={
                                        this.state.selectedNeighborhood
                                      }
                                    >
                                      {team.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-lado"
                            >
                              Lado
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-lado"
                              type="text"
                              maxLength={3}
                              required
                              value={this.state.lado}
                              onChange={(e) =>
                                this.setState({ lado: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-quarteirao"
                            >
                              Quarteirão
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-quarteirao"
                              type="text"
                              required
                              maxLength={3}
                              value={this.state.quarteirao}
                              onChange={(e) =>
                                this.setState({ quarteirao: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}
                      </Row>
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {" "}
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                          {localStorage.getItem("perm") !== "agente" && (
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={() => {
                                this.props.history.push("/admin/drone-edit");
                              }}
                            >
                              <b>Cadastrar</b>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              paginationComponentOptions={this.defaultComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={this.customStyles}
              progressPending={this.state.response === undefined}
              noDataComponent="Nenhum registro encontrado."
              paginationResetDefaultPage={this.state.response.length > 0}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
