import React  from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt,  faLocationArrow } from "@fortawesome/free-solid-svg-icons";

import Header from "components/Headers/Header.js";
import Api from "shared/resources/Api";
import { sectors, neighborhood } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";
import Util from "shared/services/Util";
import moment from "moment";

import ReactExport from "react-data-export";
import ExcelFile from "../../shared/ExcelPlugin/components/ExcelFile"

// const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default class CadastroCreate extends React.Component {
  /* Dados Grid */


  columns2 = [
    
    {
      name: "O.S",
      selector: "oid_ordem_servico",
      sortable: true,
      cell: (row) => <div>{row.oid_ordem_servico ? row.oid_ordem_servico : "-"} </div>,
      style: {
        fontSize: '12px', // Adjust the font size to your preference
      },
      
    },
    {
      name: "Origem",
      selector: "tp_origem",
      sortable: true,
      cell: (row) => <div>{row.tp_origem ? row.tp_origem : "-"} </div>,
      style: {
        fontSize: '12px', // Adjust the font size to your preference
      },
    },
    
    {
      name: "Endereço",
      selector: "endereco",
      sortable: true,
      cell: (row) => <div>{row.nm_logradouro ? row.nm_logradouro : "-"}</div>,
      wrap: true,
      style: {
        fontSize: '12px', // Adjust the font size to your preference
      },
    },
    {
      name: "Numero",
      selector: "numero",
      sortable: true,
      cell: (row) => <div>{row.nr_numero ? row.nr_numero : "-"}</div>,
    },
    
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => <div>{Util.capitalize(row.tp_status)}</div>,
      style: {
        fontSize: '11px', // Adjust the font size to your preference
      },
    },
    {
      name: "Data Abertura",
      selector: "data_abertura",
      sortable: true,
      cell: (row) => (
        <div>{moment(moment(row.dt_abertura)).format("DD/MM/YYYY")}</div>
      ),
    },
    
    {
      name: "Tipo",
      selector: "tipo",
      sortable: true,
      cell: (row) => (
        <div>
          {row.tp_ordem_servico === "solicitacao"
            ? Util.snakeToPascal(row.tp_ordem_servico)
            : Util.capitalize(row.tp_ordem_servico)}
        </div>
      ),
      style: {
        fontSize: '11px', // Adjust the font size to your preference
      },
    },

    {
      name: "Agente",
      selector: "agente",
      sortable: true,
      cell: (row) => <div>{row.agente ? row.agente : "N/A"}</div>,
      style: {
        fontSize: '12px', // Adjust the font size to your preference
      },
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Redirecionar"
            onClick={() => {

              let params = {
                data_abertura_inicio: "",
                data_abertura_fim: "",
                agendamento_inicio: "",
                agendamento_fim: "",
                id: row.oid_ordem_servico,
                tipo_de_imovel: "",
                local: "",
                tel: "",
                lado: "",
                quarteirao: "",
                cpf: "",
                agente: "",
                status: "",
                origem: "",
                equipe: "",
                logradouro: "",
                bairro: "",
              };
          
              localStorage.setItem('params', JSON.stringify(params));
              localStorage.setItem('show', "false");



              this.props.history.push("/admin/ticket");
            }}
          >
            <FontAwesomeIcon icon={faLocationArrow} className="edit" />
          </Button>
          {/* {localStorage.getItem("perm") !== "agente" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )} */}
        </div>
      ),
    },

    

    
  ];



  columns = [
    {
      name: "Código",
      maxWidth: "10px",
      selector: "id",
      sortable: true,
    },
    {
      name: "Tipo",
      maxWidth: "10px",
      selector: "source",
      sortable: true,
    },
    {
      name: "Nome",
      selector: "nome",
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "Logradouro",
      selector: "logradouro",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Lado",
      selector: "lado",
      cell: (row) => (
        <div>
          {row.lado !== undefined && row.lado !== null
            ? row.lado
            : "-"}
        </div>
      ),
    },
    {
      name: "Quarteirão",
      selector: "quarteirao",
      cell: (row) => (
        <div>
          {row.quarteirao !== undefined && row.quarteirao !== null && row.quarteirao !== 0
            ? row.quarteirao
            : "-"}
        </div>
      ),
    },
    {
      name: "Bairro",
      selector: "Bairro",
      sortable: true,
      cell: (row) => (
        <div>
          {row.bairro !== undefined && row.bairro !== null
            ? row.bairro
            : "-"}
        </div>
      ),
    },
    {
      name: "Setor",
      selector: "Setor",
      maxWidth: "10px",
      sortable: true,
      cell: (row) => (
        <div>
          {row.setor !== undefined && row.setor !== null
            ? row.setor
            : "-"}
        </div>
      ),
    },
    {
      name: "Tratamento Biológico",
      selector: "tratamento",
      sortable: true,
      cell: (row) => <div>{row.tratamento ? "Sim" : "Não"}</div>,
      wrap: true
    },
    {
      name: "Tipo de Solicitação",
      selector: "tipo",
      sortable: true,
      cell: (row) => (
        <div>
          {row.tipoorigem === "solicitacao"
            ? Util.snakeToPascal(row.tipo_de_imovel)
            : Util.capitalize(row.tipoorigem)}
        </div>
      ),
    },
    {
      name: "",
      center: true,
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {

              

              this.props.history.push(
                `/admin/${this.state.pathTipo === "abandonado"
                  ? "abandoned-property"
                  : "registred-property"
                }-edit`,
                {
                  property: row,
                }
              );
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {localStorage.getItem("perm") !== "agente" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )}

          
        </div>
      ),
    },

    {

      name: "",
      center: true,
      cell: (row) => (
      <div>
        {row.source === "imovel" && (
      <Button
      type="button"
      color="primary"
      className=" btn-zoo "
      title="Histórico O.S."
      onClick={() => this.toggleModal(row.id,row.numero,row.logradouro)}
      // onClick={() => {
      //   this.props.history.push(
      //     `/admin/${this.state.pathTipo === "boletim"
      //       ? "abandoned-property"
      //       : "registred-property"
      //     }-edit`,
      //     {
      //       property: row,
      //     }
      //   );
      // }}
    >
      <b>Histórico O.S</b>
      {/* <FontAwesomeIcon icon={faEdit} className="edit" /> */}
    </Button>
    )}
  </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };
  customStyles2 = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 12,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      response: "",
      response2: "",
      nome: "",
      logradouro: "",
      id: "",
      pathTipo: "",
      bairro: "",
      api: Api.baseURL,
      requesting: false,
      neighborhood: [],
      sectors: [],
      selectedSector: null,
      selectedNeighborhood: null,
      isOpen: false,
      setIsOpen: false,
     
    };
  }
  toggleModal = (codigoid,cnumero,clogradouro) => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      codigoid: codigoid,
      cnumero: cnumero ? cnumero :"N/A",
      clogradouro: clogradouro,
    }));

    try {

      let para ={

        numero: cnumero,
        logradouro: clogradouro,
      }


      let endpoint = "imovel/boletim";
      let esc = encodeURIComponent;
      let query = Object.keys(para)
      .map((k) => esc(k) + "=" + esc(para[k]))
      .join("&");

      let host = this.state.api + `/${endpoint}?status=`;

      let path = "" + "&" + query;

      const respons2 = fetch(host + path, {
        headers: {
          Authorization: localStorage.getItem("agToken"),
        },
      })
      .then((respons2) => {
        if (!respons2.ok) {
          throw new Error("Network response was not ok");
        }
        return respons2.json();
      })
      .then((body) => {
        console.log("body"+body)
        
        this.setState({
          response2: body,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    } catch (e) {
      console.error("Invalid JSON string:", e);
    }


  };

  CloseModal = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      codigoid: null  // Reset codigoid when closing the modal
    }));
  };

  componentWillMount() {

    localStorage.setItem("excel", "Imóveis");

    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });
    if (this.props.location != null) {
      const path = this.props.location.pathname;
      this.setState({
        pathTipo:
          path === "/admin/abandoned-property" ? "abandonado" : "cadastrado",
      });
    }

    if (localStorage.getItem("gridimov")) {

      localStorage.setItem("excel", "Imóveis");
 
      var obj = JSON.parse(localStorage.getItem("gridimov"));
      console.log(obj)
      console.log(obj.length)
      this.setState({
        response: obj,
      })
    }

    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });

    sectors().then((res) => {
      if (res) {
        this.setState({
          sectors: res,
        });
      }
    });
  }

  fetchSectors = async () => {
    const response = await fetch(Api.baseURL + "/setor", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };

  fetchNeighborhood = async () => {
    const response = await fetch(Api.baseURL + "/bairro", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        neighborhood: body,
      });
    }

    return body;
  };

  handleSubmit = (event) => {
    this.setState({ response: "", requesting: true });

    localStorage.setItem("excel", "Imóveis");

    this.fetchTireShop()
      .then((res) => this.setState({ response: res }, () => {
        localStorage.setItem("gridimov", JSON.stringify(res));
      }))
      .catch((err) => {
        console.log(err);
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text:
              "Não foram encontradas imóveis para os parâmetros informados.",
            type: "error",
          },
          requesting: false,
        });
      });

    event.preventDefault();
  };

  fetchTireShop = async () => {
    let host = this.state.api + "/imovel/cadastro?";
    let params = {};
    if (this.state.id) {
      params = {
        id: this.state.id,
      };
    } else {
      params = {
        nome: this.state.nome,
        logradouro: this.state.logradouro.trim(),
        tipo: this.state.pathTipo,
        tipoorigem: this.state.tipo_de_imovel ? this.state.tipo_de_imovel : "",
        lado: this.state.lado ? this.state.lado : "",
        quarteirao: this.state.quarteirao ? this.state.quarteirao : "",
        tratamento: this.state.tratamento ? this.state.tratamento : "",
        bairro:
          this.state.bairro !== null
            ? this.state.bairro
            : "",
        setor:
          this.state.selectedSector !== null ? this.state.selectedSector : "",
      };
    }
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        agent: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não foram encontrados imóveis para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };
  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "error",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      trieshop: element,
      isConfirmation: true,
    });
  };
  handleDeleteTireShop(d) {
    this.callDel(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDel = async (d) => {
    const response = await fetch(Api.baseURL + "/imovel/" + d.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Imóvel deletado com sucesso!",
          type: "success",
        },
        response: "",
        nome: "",
        logradouro: "",
        tel: "",
        selectedNeighborhood: "",
        selectedSector: "",
        id: ""
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar imóvel.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  render() {
    const { isOpen, codigoid, cnumero,clogradouro } = this.state;
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteTireShop(this.state.trieshop);
            }
          }}
        />

        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
        

        

          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Cadastrar{" "}
                      {this.state.pathTipo === "cadastrado"
                        ? ""
                        : ""}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        


                        <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                  >
                                    Selecione Tipo de Cadastro 
                                  </label>

                                  <select
                                    value={this.state.tipo_de_imovel}
                                    onChange={(e) =>
                                      this.setState({
                                        tipo_de_imovel: e.target.value,
                                      })
                                    }
                                    className="form-control "
                                    id="select-tipo"
                                  >
                                    <option defaultValue="" value="">
                                      {" "}
                                      Selecione...{" "}
                                    </option>
                                    <option value="reservatorio">
                                      Reservatórios
                                    </option>
                                    <option value="grandesareas">
                                      Grandes areas
                                    </option>
                                    <option value="imoveisabandono">Imóveis em abandono
                                    </option>
                                    <option value="controlebio">
                                      Controle biológico
                                    </option>
                                    <option value="borracharias">
                                      Ecopneumáticos
                                    </option>
                                    <option value="ponto">Pontos estratégicos</option>
                                    <option value="ronda">Rondas Escolares</option>
                                    
                                  </select>
                                </FormGroup>
                              </Col>
                      </Row>
                      <Row>
                        
                        

                        

                       


                        
                      </Row>

                      <Row>

                     
                       

                   
                      </Row>
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {" "}
                          {/* <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button> */}
                          {/* {localStorage.getItem("perm") !== "agente" && (
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={() => {
                                this.props.history.push(
                                  `/admin/${this.state.pathTipo === "abandonado"
                                    ? "abandoned-property"
                                    : "registred-property"
                                  }-edit`
                                );
                              }}
                            >
                              <b>Cadastrar {this.state.tipo_de_imovel}</b>
                            </Button>
                          )} */}

                          {localStorage.getItem("perm") !== "agente" && this.state.tipo_de_imovel && (
                            <Button
                            className="mt-4 btn-zoo"
                            color="primary"
                            type="button"
                            onClick={() => {
                              let route;
                              let buttonText;
                          
                              switch (this.state.tipo_de_imovel) {
                                case "imoveisabandono":
                                  route = "/admin/abandoned-property-edit";
                                  buttonText = "Cadastrar ";
                                  break;
                                case "grandesareas":
                                  route = "/admin/registred-property-edit";
                                  buttonText = "Cadastrar ";
                                  break;
                                case "reservatorio":
                                  route = "/admin/pool-edit";
                                  buttonText = "Cadastrar ";
                                  break;
                                case "controlebio":
                                  route = "/admin/biocontrol-edit";
                                  buttonText = "Cadastrar ";
                                  break;
                                case "borracharias":
                                  route = "/admin/tireshop-edit";
                                  buttonText = "Cadastrar ";
                                  break;
                                case "ponto":
                                  route = "/admin/strategicpoint-edit";
                                  buttonText = "Cadastrar ";
                                  break;
                                case "ronda":
                                  route = "/admin/ronda-edit";
                                  buttonText = "Cadastrar ";
                                  break;
                                // Add more cases for other values of tipo_de_imovel as needed
                          
                                default:
                                  route = "/"; // Default route if tipo_de_imovel doesn't match any case
                                  buttonText = `Cadastrar `;
                              }
                          
                              this.props.history.push(route);
                            }}
                          >
                            <b>Cadastrar</b>
                          </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
         
          {/* {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              paginationComponentOptions={this.defaultComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={this.customStyles}
              progressPending={this.state.response === undefined}
              noDataComponent="Nenhum registro encontrado."
              paginationResetDefaultPage={this.state.response.length > 0}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )} */}

        
        </Container>
        <Modal isOpen={isOpen} toggle={() => this.toggleModal(codigoid,cnumero,clogradouro)} className="custom-modal">
          <ModalHeader toggle={() => this.toggleModal(codigoid,cnumero,clogradouro)} className="custom-modal-header">Histórico de O.S.</ModalHeader>
          <ModalBody>
            <p style={{fontWeight:"bold"}}>Histórico de O.S. para Logradouro: {clogradouro}, Nº {cnumero}</p>
            {/* {this.state.response2 && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns2}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response2}
              paginationComponentOptions={this.defaultComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={this.customStyles}
              progressPending={this.state.response2 === undefined}
              noDataComponent="Nenhum registro encontrado."
              paginationResetDefaultPage={this.state.response2.length > 0}
              
            />
          )} */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.CloseModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
