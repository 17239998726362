import React from 'react';
import { Map,  Marker, GoogleApiWrapper } from 'google-maps-react';
import gconf from "shared/resources/Gconf";

const style = {
    width: '100%',
    height: '100%', position: 'relative'
}
export class GoogleMap extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.lat)
        console.log(this.props.lng)
    }
    componentDidMount() {
        console.log(this.props.lat)
        console.log(this.props.lng)
    }
    render() {
        return (<>
            <Map google={this.props.google}
            style={style}
            zoom={20}
             initialCenter={{
                lat: this.props.lat,
                lng: this.props.lng
              }}
            >

                <Marker onClick={this.onMarkerClick}
                    name={'Current location'} />


            </Map>
        </>);
    }
}
const LoadingContainer = (props) => (
    <div>Carregando!</div>
)
GoogleMap= GoogleApiWrapper({
    apiKey: (gconf.key),
    LoadingContainer: LoadingContainer
})(GoogleMap)