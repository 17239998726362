import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import InputMask from "react-input-mask";
import SweetAlert2 from "react-sweetalert2";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Header from "components/Headers/Header.js";
import Api from "shared/resources/Api";
import { telMask } from "shared/services/Mask";
import { sectors, neighborhood } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";

import ReactExport from "react-data-export";
import ExcelFile from "../../shared/ExcelPlugin/components/ExcelFile"

// const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class BioControlList extends React.Component {
  /* Dados Grid */
  columns = [
    {
      name: "Código",
      maxWidth: "50px",
      selector: "id",
      sortable: true,
    },
    {
      name: "Nome",
      selector: "nome",
      sortable: true,
    },
    {
      name: "Telefone",
      sortable: true,
      cell: (row) => <div>{telMask(row.tel)}</div>,
    },
    {
      name: "Logradouro",
      selector: "logradouro",
      sortable: true,
    },
    {
      name: "Bairro",
      selector: "Bairro",
      sortable: true,
      cell: (row) => (
        <div>
          {row.bairro !== undefined && row.bairro !== null
            ? row.bairro
            : "-"}
        </div>
      ),
    },
    {
      name: "Setor",
      selector: "Setor",
      sortable: true,
      cell: (row) => (
        <div>
          {row.setor !== undefined && row.setor !== null
            ? row.setor
            : "-"}
        </div>
      ),
    },
    {
      name: "",
      center: true,
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              this.props.history.push(`/admin/biocontrol-edit`, {
                biocontrol: row,
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {localStorage.getItem("perm") !== "agente" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      response: "",
      nome: "",
      bairro: "",
      tel: "",
      logradouro: "",
      id: "",
      api: Api.baseURL,
      requesting: false,
      neighborhood: [],
      sectors: [],
      selectedSector: null,
      selectedNeighborhood: null,
    };
  }
  componentWillMount() {

    localStorage.setItem("excel", "ControleBiologico");


    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    if (localStorage.getItem("gridbio")) {
 
      var obj = JSON.parse(localStorage.getItem("gridbio"));
      this.setState({
        response: obj,
      })
    }

    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });

    sectors().then((res) => {
      if (res) {
        this.setState({
          sectors: res,
        });
      }
    });
  }

  fetchSectors = async () => {
    const response = await fetch(Api.baseURL + "/setor", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };

  fetchNeighborhood = async () => {
    const response = await fetch(Api.baseURL + "/bairro", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        neighborhood: body,
      });
    }

    return body;
  };

  handleSubmit = (event) => {
    this.setState({ response: "", requesting: true });

    this.fetchList()
      .then((res) => this.setState({ response: res }, () => {
        localStorage.setItem("gridbio", JSON.stringify(res));
      }))
      .catch((err) => {
        console.log(err);
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text:
              "Não foram encontrados controles biológicos para os parâmetros informados.",
            type: "error",
          },
          requesting: false,
        });
      });

    event.preventDefault();
  };

  fetchList = async () => {
    let host = this.state.api + "/controle_biologico?";
    let params = {};
    if (this.state.id) {
      params = {
        id: this.state.id,
      };
    } else {
      params = {
        nome: this.state.nome,
        logradouro: this.state.logradouro.trim(),
        tel: this.state.tel.replace(/\D/g, ""),
        bairro:
          this.state.bairro !== null
            ? this.state.bairro
            : "",
        setor:
          this.state.selectedSector !== null ? this.state.selectedSector : "",
      };
    }
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        agent: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não foram encontrados controles biológicos para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };
  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "error",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      trieshop: element,
      isConfirmation: true,
    });
  };
  handleDeleteTireShop(d) {
    this.callDel(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDel = async (d) => {
    const response = await fetch(Api.baseURL + "/controle_biologico/" + d.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Controle biológico deletado com sucesso!",
          type: "success",
        },
        response: "",
        nome: "",
        logradouro: "",
        tel: "",
        selectedNeighborhood: "",
        selectedSector: "",
        id: ""
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar controle biológico.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteTireShop(this.state.trieshop);
            }
          }}
        />

        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Consulta Controle Biológico{" "}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Número
                            </label>
                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-id"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder=""
                              value={this.state.id}
                              onChange={(e) =>
                                this.setState({ id: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Nome Proprietário{" "}
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-nome"
                              type="text"
                              required
                              value={this.state.nome}
                              onChange={(e) =>
                                this.setState({ nome: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-tel"
                            >
                              Telefone
                            </label>
                            <InputMask
                              mask="(99) 99999-9999"
                              type="text"
                              inputMode="numeric"
                              name="tel"
                              id="tel"
                              className="form-control"
                              value={this.state.tel}
                              onChange={(e) =>
                                this.setState({ tel: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            {this.state.sectors.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Setor
                                </label>

                                <select
                                  value={this.state.selectedSector}
                                  onChange={(e) =>
                                    this.setState({
                                      selectedSector: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  {this.state.sectors.map((team) => (
                                    <option
                                      key={team.id}
                                      value={team.nome}
                                      defaultValue={this.state.selectedSector}
                                    >
                                      {team.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bairro"
                            >
                              Bairro
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-lado"
                              type="text"
                              maxLength="30"
                              value={this.state.bairro}
                              onChange={(e) =>
                                this.setState({ bairro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-logradouro"
                            >
                              Logradouro
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-logradouro"
                              type="text"
                              required
                              value={this.state.logradouro}
                              onChange={(e) =>
                                this.setState({ logradouro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}
                      </Row>
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {" "}
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                          {localStorage.getItem("perm") !== "agente" && (
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={() => {
                                this.props.history.push(
                                  `/admin/biocontrol-edit`
                                );
                              }}
                            >
                              <b>Cadastrar</b>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
           <Row className="text-left" style={{ paddingBottom:"2%" }}>
                        <Col className="order-xl-1" xl="12">

                <ExcelFile>
                <ExcelSheet data={this.state.response} name="ControleBiológico">
                <ExcelColumn label="Código" 
                value={(row) => " "+row.id}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                
                  <ExcelColumn label="nome" 
                value={(row) => " "+row.nome}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                  <ExcelColumn label="Telefone" 
                value={(row) => row.tel ? telMask(row.tel) : ""}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                 <ExcelColumn label="logradouro" 
                value={(row) => row.logradouro}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                  <ExcelColumn label="Bairro" 
                value={(row) => row.bairro ? row.bairro : ""}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                  <ExcelColumn label="Setor" 
                value={(row) => row.setor ? row.setor : ""}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                
                 
                  
                  
                  
                 
                </ExcelSheet>
           
              </ExcelFile>
                        
                        </Col>
                      </Row>
                      )}
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              paginationComponentOptions={this.defaultComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={this.customStyles}
              progressPending={this.state.response === undefined}
              noDataComponent="Nenhum registro encontrado."
              paginationResetDefaultPage={this.state.response.length > 0}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
