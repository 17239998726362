export  const  viaCep =  async (value) => {       
        const response = await fetch(`https://viacep.com.br/ws/${value.replace(/\D/g, '')}/json`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        });
    
        return await response.json();
  }