import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import InputMask from "react-input-mask";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { cpfMask, telMask } from "shared/services/Mask";

import Header from "components/Headers/Header.js";
import Util from "shared/services/Util";
import Api from "shared/resources/Api";
import moment from "moment";

export default class CitizenList extends React.Component {
  /* Dados Grid */
  columns = [
    {
      name: "Status",
      selector: "status",
      sortable: true
    },
    {
      name: "Nome",
      selector: "nome",
      sortable: true
    },

    {
      name: "CPF",
      selector: "cpf",
      sortable: true,
      cell: row => <div>{cpfMask(row.cpf)}</div>
    },
    {
      name: "Telefone",
      selector: "tel",
      sortable: true,
      cell: row => <div>{telMask(row.tel)}</div>
    },
    {
      name: "Data Cadastro",
      selector: "horario_criacao",
      sortable: true,
      cell: row => (
        <div>{moment(moment(row.horario_criacao)).format("DD/MM/YYYY")}</div>
      )
    },
    {
      name: "Data Nascimento",
      selector: "data_nascimento",
      sortable: true,
      cell: row => (
        <div>{moment(moment(row.data_nascimento)).format("DD/MM/YYYY")}</div>
      )
    },

    {
      name: "",
      cell: row => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              this.props.history.push("/admin/citizen-edit", {
                selectedCitizen: row
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {localStorage.getItem("perm") === "coordenador" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )}
        </div>
      )
    }
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000"
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      cpf: "",
      tel: "",
      status: "",
      data_cadastro_inicio: "",
      data_cadastro_fim: "",
      response: "",
      api: Api.baseURL,
      citizen: [],
      requesting: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteCitizen = this.handleDeleteCitizen.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillMount() {
    document.addEventListener("keydown", event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event)
      }
    });

    if (localStorage.getItem("gridciti")) {

      var obj = JSON.parse(localStorage.getItem("gridciti"));
      this.setState({
        response: obj,
      })
    }
  }

  handleChange(event) {
    this.setState({ cpf: cpfMask(event.target.value) });
  }

  handleSubmit(event) {
    if (this.validate()) {
      this.setState({ requesting: true });
      this.fetchCitizen()
        .then(res => this.setState({ response: res }, () => {
          localStorage.setItem("gridciti", JSON.stringify(res));
        }))
        .then()
        .catch(err => console.log(err));

      event.preventDefault();
    }
  }

  fetchCitizen = async () => {
    let params = {
      nome: this.state.nome,
      cpf: this.state.cpf.replace(/\D/g, ""),
      tel: this.state.tel.replace(/\D/g, ""),
      data_cadastro_inicio: this.dataIni !== undefined ? this.dataIni : "",
      data_cadastro_fim: this.dataFim !== undefined ? this.dataFim : ""
    };

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + "=" + esc(params[k]))
      .join("&");

    let host = this.state.api + "/cidadao?status=";

    let path =
      this.state.status === "todos"
        ? "&" + query
        : this.state.status + "&" + query;

    const response = await fetch(host + path, {
      headers: {
        Authorization: localStorage.getItem("agToken")
      }
    });

    const body = await response.json();

    let bytes = new Blob([JSON.stringify(body)]).size
    let megabytes = bytes / (1024 * 1024);
    if (megabytes < 4.5) {

      if (body.length > 0) {
        this.setState({
          citizen: body,
          requesting: false
        });
      } else {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Não foram encontrados cidadãos para os parâmetros informados.",
            type: "error"
          },
          requesting: false
        });

      }
    }else{
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Sua pesquisa trouxe "+body.length+" resultados ultrapassando o limite de consultas, por favor restrinja a busca.",
          type: "error",
        },
        requesting: false,
      });
      return ''
    }
    return body;
  };

  validate() {
    if (this.state.cpf !== "") {
      if (!Util.validaCPF(this.state.cpf.replace(/\D/g, ""))) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "CPF inválido.",
            type: "error"
          }
        });
        return false;
      }
    }

    if (this.state.tel !== "") {
      if (this.state.tel.replace(/\D/g, "").length !== 11) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Telefone inválido.",
            type: "error"
          }
        });
        return false;
      }
    }

    var dateFormat = "DD/MM/YYYY";

    if (this.state.data_cadastro_inicio !== "") {
      if (
        !moment(this.state.data_cadastro_inicio, dateFormat, true).isValid()
      ) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data inicial inválida.",
            type: "error"
          }
        });
        return false;
      } else {
        this.dataIni = moment(
          moment(this.state.data_cadastro_inicio, "DD/MM/YYYY")
        ).format("YYYY-MM-DD");
      }
    }

    if (this.state.data_cadastro_fim !== "") {
      if (!moment(this.state.data_cadastro_fim, dateFormat, true).isValid()) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data final inválida.",
            type: "error"
          }
        });
        return false;
      } else {
        this.dataFim = moment(
          moment(this.state.data_cadastro_fim, "DD/MM/YYYY")
        ).format("YYYY-MM-DD");
      }
    }

    if (this.dataIni !== undefined && this.dataFim !== undefined) {
      var date1 = moment(this.state.data_cadastro_inicio, "DD/MM/YYYY");
      var date2 = moment(this.state.data_cadastro_fim, "DD/MM/YYYY");
      var diff = date2.diff(date1);

      if (diff < 0) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data de Início do cadastro menor que Data Final.",
            type: "error",
          },
        });
        return false;
      }
    }

    if (this.state.data_cadastro_inicio === "") {
      this.dataIni = undefined;
    }


    if (this.state.data_cadastro_fim === "") {
      this.dataFim = undefined;
    }

    return true;
  }

  handleDelete = element => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "warning",
        showCancelButton: true,
        showConfirmButton: true
      },
      citizen: element,
      isConfirmation: true
    });
  };
  handleDeleteCitizen(d) {
    this.callDelCitizen(d)
      .then()
      .catch(err => console.log(err));
  }

  callDelCitizen = async d => {
    const response = await fetch(Api.baseURL + "/cidadao/" + d.cpf, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken")
      }
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Usuário deletado com sucesso!",
          type: "success"
        },
        cpf: "",
        data_cadastro_inicio: "",
        data_cadastro_fim: "",
        nome: "",
        tel: "",
        response: "",
        status: ""
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar Usuário.",
          type: "error"
        }
      });

      throw Error(body.message);
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={result => {
            this.setState({
              swal: {
                show: false
              }
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false
              });
              this.handleDeleteCitizen(this.state.citizen);
            }
          }}
        />
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Consulta Cidadão
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              CPF
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-nome"
                              type="text"
                              inputMode="numeric"
                              required
                              value={this.state.cpf}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Nome Usuário
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-nome"
                              type="text"
                              required
                              value={this.state.nome}
                              onChange={e =>
                                this.setState({ nome: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Status Usuário
                            </label>

                            <select
                              value={this.state.status}
                              onChange={e =>
                                this.setState({ status: e.target.value })
                              }
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">Todos</option>
                              <option value="ativo">Ativo</option>
                              <option value="inativo">Inativo</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Telefone
                            </label>
                            <InputMask
                              mask="(99) 99999-9999"
                              type="text"
                              inputMode="numeric"
                              name="tel"
                              id="tel"
                              className="form-control"
                              value={this.state.tel}
                              onChange={e =>
                                this.setState({ tel: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Data Inicial Cadastro
                            </label>
                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              name="cel"
                              inputMode="numeric"
                              id="cel"
                              className="form-control"
                              value={this.state.data_cadastro_inicio}
                              onChange={e =>
                                this.setState({
                                  data_cadastro_inicio: e.target.value
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Data Final Cadastro
                            </label>

                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              inputMode="numeric"
                              name="cel"
                              id="cel"
                              className="form-control"
                              value={this.state.data_cadastro_fim}
                              onChange={e =>
                                this.setState({
                                  data_cadastro_fim: e.target.value
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="text-center">
                      <Col>
                        <Button
                          className="mt-4 btn-zoo-alt"
                          color="primary"
                          type="button"
                          onClick={this.handleSubmit}
                        >
                          <b>Pesquisar</b>
                        </Button>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              sortable={true}
              responsive={true}
              highlightOnHover={true}
              noDataComponent="Nenhum registro encontrado."
              data={this.state.response}
              paginationComponentOptions={this.defaultComponentOptions}
              customStyles={this.customStyles}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
