import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import { GoogleMap } from "components/Map/GoogleMap";
import SweetAlert2 from "react-sweetalert2";
import Api from "shared/resources/Api";
import Header from "components/Headers/Header.js";
import { neighborhood } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";

export default class DroneEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cep: "",
      nome: "",
      tel: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      lat: -18.90971,
      lon: -48.261921,
      api: Api.baseURL,
      isMapOk: false,
      isEdicao: false,
      formTitle: "CADASTRAR",
      neighborhood: [],
      sectors: [],
      selectedSector: null,
      selectedNeighborhood: "",
      lado: null,
      quarteirao: null,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount() {
    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.drone != null
    ) {
      this.setState(
        {
          drone: this.props.location.state.drone,
          agent: this.props.location.state.os,
          loading: false,
          criadouro: this.props.location.state.drone.criadouro,
          lado: this.props.location.state.drone.lado,
          quarteirao: this.props.location.state.drone.quarteirao,
          situacao: this.props.location.state.drone.situacao,
          selectedNeighborhood: this.props.location.state.drone.bairro_id,
          isEdicao: true,
          formTitle: "EDITAR",
        },
        () => console.log("state logou end")
      );
    }
  }

  componentDidMount() { }

  handleSave(event) {
    if (this.validateFields()) {
      this.callSave();
    }
  }

  blurEndereco = () => {
    this.consultarLatLong();
  };

  validateFields() {
    /* Validação obrigatoriedade */



    if (
      this.state.situacao === undefined ||
      this.state.situacao === "" ||
      this.state.situacao === null
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Situação.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.criadouro === undefined || this.state.criadouro.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe os dados do Criadouro.",
          type: "warning",
        },
      });

      return false;
    }
    if (
      this.state.selectedNeighborhood === undefined ||
      this.state.selectedNeighborhood === "" ||
      this.state.selectedNeighborhood === null
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro.",
          type: "warning",
        },
      });

      return false;
    }
    /* Validação obrigatoriedade */
    /* Validação integridade */

    return true;
  }

  createBody = () => {
    if (this.state.drone) {
      return JSON.stringify({

        criadouro:
          this.state.criadouro !== "" ? this.state.criadouro : this.state.drone.criadouro,
        bairro:
          this.state.selectedNeighborhood !== ""
            ? this.state.selectedNeighborhood
            : this.state.drone.bairro_setor.nome_bairro,
        lado: this.state.lado,
        quarteirao: Number(this.state.quarteirao),
        situacao: this.state.situacao,

      });
    } else {
      return JSON.stringify({

        criadouro: this.state.criadouro,
        situacao: this.state.situacao,
        bairro:
          this.state.selectedNeighborhood !== ""
            ? this.state.selectedNeighborhood
            : this.state.drone.endereco.bairro,
        lado: this.state.lado,
        quarteirao: Number(this.state.quarteirao)
      });
    }
  };

  callSave = async () => {
    console.log(this.createBody());
    const response = await fetch(
      Api.baseURL +
      "/drone/" +
      (this.state.drone ? this.state.drone.id : ""),
      {
        method: this.state.drone ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: this.createBody(),
      }
    );
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: this.state.drone
            ? "Edição realizada com sucesso!"
            : "Cadastro realizado com sucesso!",
          type: "success",
        },
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.drone
            ? "Erro ao editar drone. "
            : "Erro ao cadastrar drone.",
          type: "warning",
        },
      });
      console.log(body.erro);
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.drone
            ? "Erro ao editar drone. "
            : "Erro ao cadastrar drone.",
          type: "warning",
        },
      });
      console.log(body.erro);
    }

    return body;
  };
  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/drone");
            }
          }}
        />
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {this.state.formTitle} Drone
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="9">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Situação *
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-criadouro"
                              type="text"
                              required
                              maxLength={100}
                              value={this.state.situacao}
                              onChange={(e) =>
                                this.setState({ situacao: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <div>
                              <label
                                className="form-control-label"
                                htmlFor="input-criadouro"
                              >
                                Criadouro *
                              </label>

                              <select
                                value={this.state.criadouro}
                                onChange={(e) =>
                                  this.setState({
                                    criadouro: e.target.value,
                                  })
                                }
                                className="form-control "
                              >
                                <option defaultValue="" value="">
                                  {" "}
                                  Selecione...{" "}
                                </option>
                                <option value="caixa_dagua">Caixa Dagua</option>
                                <option value="piscinas">Piscinas</option>
                              </select>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            {this.state.neighborhood.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Bairro *
                                </label>

                                <select
                                  value={this.state.selectedNeighborhood}
                                  onChange={(e) =>
                                    this.setState({
                                      selectedNeighborhood: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option value="">Selecione...</option>
                                  {this.state.neighborhood.map((team) => (
                                    <option
                                      key={team.id}
                                      value={team.id}
                                      defaultValue={
                                        this.state.selectedNeighborhood
                                      }
                                    >
                                      {team.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-comp"
                            >
                              Quarteirão
                            </label>

                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-quarteirao"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                              placeholder=""
                              value={this.state.quarteirao}
                              onChange={(e) =>
                                this.setState({ quarteirao: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-comp"
                            >
                              Lado
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-comp"
                              type="text"
                              required
                              maxLength={4}
                              value={this.state.lado}
                              onChange={(e) =>
                                this.setState({ lado: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {this.state.isMapOk && (
                      <Row>
                        <Col lg="12" style={{ height: "40vh", paddingLeft: 0 }}>
                          <GoogleMap
                            lat={this.state.lat}
                            lng={this.state.lon}
                          />
                        </Col>
                      </Row>
                    )}
                    {localStorage.getItem("perm") !== "agente" && (
                      <div className="text-center">
                        <Row>
                          <Col className="order-xl-1" xl="12">
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={this.handleSave}
                            >
                              <b>
                                {this.state.isEdicao
                                  ? "Salvar Alterações"
                                  : "Cadastrar"}
                              </b>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
