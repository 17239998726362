import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Login extends React.Component {
  
  render() {

    return (
      <>
        <footer className="py-5 bg-login ">
          <Container className=" footer-login">
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className=" text-center text-xl-left ">
                  © {new Date().getFullYear()} {" "}
                  <a
                    className="font-weight-bold ml-1 main-text"
                    href="https://www.uberlandia.mg.gov.br/"
                    target="_blank" rel="noopener noreferrer"
                  >
                    Prefeitura Municipal de Uberlândia
                  </a>
                </div>
              </Col>
              <Col xl="6">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <NavLink
                      href="https://www.uberlandia.mg.gov.br/prefeitura/secretarias/saude/centro-de-controle-de-zoonoses/"
                      target="_blank"
                    >
                      Zoonoses
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://play.google.com/store/apps/details?id=br.gov.mg.prodaub.udisemdengue&hl=pt_BR"
                      target="_blank"
                    >
                      Udi Sem Dengue
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <p hidden>{process.env.REACT_APP_API_ENV}</p>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
