import React from "react";
import api from "shared/resources/Api";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import SweetAlert2 from "react-sweetalert2";

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      senha: "",
      senha2: "",
      showAlert: false,
      alertType: "success",
      alertText: "",
      alertTitle: "",
      alertCallback: "",
      success: false,
      alertPosition: "bottom-end"
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
  }
  componentWillMount(){
    document.addEventListener("keydown", event=>{
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleChangePassword(event)
      }
    });
  }

  handleChangePassword(e) {
    if (this.state.senha === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Nova Senha.",
          type: "warning"
        }
      });

      return false;
    }

    if (this.state.senha2 === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Confirme a Nova Senha.",
          type: "warning"
        }
      });
      return false;
    }

    if (this.state.senha !== "" && this.state.senha !== "") {
      if (this.state.senha !== this.state.senha2) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "As senhas não são Iguais.",
            type: "warning"
          }
        });
        return false;
      }
    }

    e.preventDefault();
    this.callSave()
      .then(res => this.setState({ response: res.express }))
      .then()
      .catch(err => console.log(err));

  }

  callSave = async () => {
    const response = await fetch(
      api.baseURL +
        "/usuario/" +
        localStorage.getItem("code").replace(/\D/g, "") +
        "/senha",
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken")
        },
        body: JSON.stringify({
          senha: this.state.senha
        })
      }
    );
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {

      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Senha alterada com sucesso.",
          type: "success"
        }
      });

    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: body.erro,
          type: "error"
        }
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao trocar senha do usuário: " + body.erro,
          type: "error"
        }
      });
    }

    return body;
  };

  render() {
    return (
      <>
        <UserHeader></UserHeader>
        {/* Page content */}
        <Container className="main-zoo" fluid>
          <SweetAlert2
            {...this.state.swal}
            onConfirm={result => {
              this.setState(
                {
                  swal: {
                    show: false
                  }
                },
                this.props.history.push("/admin/password")
              );
            }}
          />
          <Row>
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Crie uma nova Senha</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div id="oi" className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nova Senha
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Informe sua nova senha"
                              type="password"
                              autoComplete="new-password"
                              value={this.state.senha}
                              onChange={e =>
                                this.setState({ senha: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Confirmar nova Senha
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Repita a senha"
                              type="password"
                              autoComplete="new-password"
                              value={this.state.senha2}
                              onChange={e =>
                                this.setState({ senha2: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="text-center">
                        <Button
                          className="mt-4 btn-zoo"
                          color="primary"
                          type="button"
                          onClick={this.handleChangePassword}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Password;
