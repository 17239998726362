import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import { viaCep } from "shared/services/ViaCep";
import Api from "shared/resources/Api";
import Header from "components/Headers/Header.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { neighborhood } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";
import gconf from "shared/resources/Gconf";
import DataTable from "react-data-table-component";
import ImageUploader from "react-images-upload";


export default class PoolEditar extends React.Component {
  columns = [
    {
      name: "Endereço",
      selector: "endereco",
      sortable: true,
    },
    {
      name: "Bairro",
      selector: "bairro",
      sortable: true,
      cell: (row) => <div>{row.bairro ? row.bairro : "-"}</div>,
    },
    {
      name: "Ação",
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Escolher"
            onClick={() => {
              this.handleSelectedAddress(row)
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
            Escolher
          </Button>
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    // cells: {
    //   style: {
    //     backgroundColor: "#F7FAFC"
    //   },
    // },
    table: {
      style: {
        backgroundColor: "#F7FAFC",
      },
    },
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
        backgroundColor: "#F7FAFC"
      },
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      cep: "",
      nome: "",
      responseAddress: "",
      selectedAddress: "",
      tel: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      api: Api.baseURL,
      isEdicao: false,
      formTitle: "CADASTRAR",
      neighborhood: [],
      sectors: [],
      selectedSector: null,
      lado: null,
      file: null,
      quarteirao: null,
      pictures: [],
      selectedFile: "",
      video: "",
      descricao: "",
      showMedia: false,
      tratamento: "",
      volume: "",
      foto1: "",
      codigo: null
    };

    this.handleSave = this.handleSave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  fileSelectedHandler = (e) => {
    this.setState({ files: [...this.state.files, ...e.target.files] })
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      foto1: pictureDataURLs[pictureDataURLs.length - 1],
      pictures: [],
      file: pictureFiles[pictureFiles.length - 1],
    });
  }

  handleImage = (imageList, addUpdateIndex) => {
    console.log('carai');
    this.setState({
      images: imageList,
    });
  };

  handleSelectedAddress = (row) => {
    console.log(row, 'escolheu')
    this.setState({ selectedAddress: row, logradouro: row.endereco, bairro: row.bairro });
  };


  handleSiat = (event) => {

    console.log(this.state.logradouro)

    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    } else {
      if (this.state.selectedAddress === "") {
        this.setState({ requesting: true });

        this.fetchSiat()
          .then((res) => this.setState({ responseAddress: res }))
          .catch((err) => {
            console.log(err);
            this.setState({
              swal: {
                show: true,
                title: "Atenção",
                text:
                  "Não foram encontrados endereços para os parâmetros informados.",
                type: "error",
              },
              requestingPool: false,
            });
          });
      } else {
        this.setState({ selectedAddress: "", logradouro: "", bairro: "", responseAddress: "" });
      }
    }




    event.preventDefault();
  };

  fetchSiat = async () => {
    let host = this.state.api + "/siat_enderecos?";

    let params = {
      endereco: this.state.logradouro,
      bairro: this.state.bairro ? this.state.bairro : ""
    };

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text:
            "Não foram encontrados endereços para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };


  componentWillMount() {
    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.property != null
    ) {
      console.log(this.props.location.state.property.tratamento)
      console.log(this.props.location.state.property)
      this.setState(
        {
          pool: this.props.location.state.property,
          agent: this.props.location.state.os,
          loading: false,
          nome: this.props.location.state.property.nome,
          lado: this.props.location.state.property.lado,
          quarteirao: this.props.location.state.property.quarteirao,
          tratamento: (this.props.location.state.property.tratamento !== undefined ? (this.props.location.state.property.tratamento ? this.props.location.state.property.tratamento : "0") : "0"),
          volume: this.props.location.state.property.volume,
          logradouro: this.props.location.state.property.logradouro.trim(),
          numero: this.props.location.state.property.numero,
          tipo: this.props.location.state.property.tipo, 
          complemento: this.props.location.state.property.complemento,
          bairro: this.props.location.state.property.bairro,
          descricao: this.props.location.state.property.descricao,
          foto1: this.props.location.state.property.media ? this.props.location.state.property.media.foto1 : "",
          isEdicao: true,
          formTitle: "EDITAR",
          codigo: this.props.location.state.property.codigo
        },
        () => console.log("state logou end")
      );
    }
  }

  componentDidMount() { }

  consultarCep = () => {
    if (this.state.cep && this.state.cep.replace(/\D/g, "").length === 8) {
      viaCep(this.state.cep)
        .then((res) => {
          if (res && !res.erro) {
            const idx = this.state.neighborhood.findIndex(
              (p) => p.nome === res.bairro
            );
            this.setState({
              ...this.state,
              bairro: res.bairro,
              complemento: res.complemento,
              logradouro: res.logradouro.trim(),
              selectedNeighborhood:
                idx > -1
                  ? this.state.neighborhood[idx].id
                  : this.state.selectedNeighborhood,
            });
            this.consultarLatLong();
          } else {
            this.setState({
              swal: {
                show: true,
                title: "Atenção",
                text: "Nenhum Endereço localizado.",
                type: "warning",
              },
            });
          }
        })
        .catch((err) => {
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "Nenhum Endereço localizado.",
              type: "warning",
            },
          });
          console.log(err);
        });
    }
  };
  handleSave(event) {
    if (this.validateFields()) {
      this.setState({ requestingPool: true });
      this.callSave();
    }
  }

  blurEndereco = () => {
    this.consultarLatLong();
  };

  consultarLatLong = () => {
    this.setState({ isMapOk: false });
    const addres = `${this.state.logradouro}, ${this.state.numero}, ${this.state.bairro}, ${this.state.cep}`;
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${addres}&key=${gconf.key}`
    )
      .then((response) => response.json())
      .then((res) => {
        if (res && res.status === "OK") {
          console.log(res);
          if (
            res.results.length > 0 &&
            res.results[0].geometry &&
            res.results[0].geometry.location
          ) {
            this.setState({
              ...this.state,
              lat: res.results[0].geometry.location.lat,
              lon: res.results[0].geometry.location.lng,
              isMapOk: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          lat: -18.90971,
          lon: -48.261921,
          isMapOk: true,
        });
      });
  };

  validateFields() {
    /* Validação obrigatoriedade */

    if (
      this.state.codigo === undefined ||
      this.state.codigo === null ||
      this.state.codigo.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Código.",
          type: "warning",
        },
      });

      return false;
    } else {
      if (this.state.codigo === "0") {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Código não pode ser igual a zero.",
            type: "warning",
          },
        });

        return false;
      }
    }
    if (this.state.nome === undefined || this.state.nome.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Nome do Imóvel",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.tratamento === undefined ||
      this.state.tratamento === ""
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Tratamento Biológico.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.volume === undefined || this.state.volume.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Volume.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    }
    if (this.state.bairro === undefined || this.state.bairro.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro.",
          type: "warning",
        },
      });

      return false;
    }
    if (this.state.numero === undefined || this.state.numero.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Número.",
          type: "warning",
        },
      });

      return false;
    }


    return true;
  }


  callSave = async () => {

    var piscina =
    {
      nome:
        this.state.nome !== "" ? this.state.nome : this.state.pool.nome,
      bairro:
        this.state.bairro !== ""
          ? this.state.bairro
          : "",
      lado: this.state.lado,
      quarteirao: this.state.quarteirao !== null && this.state.quarteirao !== undefined ? this.state.quarteirao : 0,
      tratamento: this.state.tratamento,
      tipo: this.state.tipo,
      volume: this.state.volume,
      descricao: this.state.descricao,
      logradouro: this.state.logradouro.trim(),
      numero: this.state.numero,
      complemento: this.state.complemento,
      codigo: this.state.codigo
    }
    console.log(JSON.stringify(piscina), 'request piscina')
    var data = new FormData();
    data.append('piscina', JSON.stringify(piscina));

    if (this.state.file !== null) {
      data.append("foto1", this.state.file);
    }

    const response = await fetch(
      Api.baseURL +
      "/piscinas/" +
      (this.state.pool ? this.state.pool.id : ""),
      {
        method: this.state.pool ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: data,
      }
    );
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: this.state.pool
            ? "Edição realizada com sucesso!"
            : "Cadastro realizado com sucesso!",
          type: "success",
        },
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.pool
            ? "Erro ao editar piscina. " + body.msg
            : "Erro ao cadastrar piscina.",
          type: "warning",
        },
        requestingPool: false,
      });
      
      return false;
      console.log(body.erro);
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.pool
            ? "Erro ao editar piscina. " + body.msg
            : "Erro ao cadastrar piscina.",
          type: "warning",
          requestingPool: false,
        },
      });
      return false;
      console.log(body.erro);
    }

    return body;
  };
  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/cadastroListar");
            }
          }}
        />
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  {!this.state.requestingPool && (
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        {this.state.formTitle === 'CADASTRAR' ? 'Cadastro de Reservatórios' : 'Editar Reservatórios'}
                    </h6>
                      <div className="pl-lg-4">
                        <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-comp"
                            >
                              Código *
                            </label>

                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-codigo"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder=""
                              value={this.state.codigo}
                              onChange={(e) =>
                                this.setState({ codigo: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Nome Imóvel *
                            </label>

                              <Input
                                className="form-control-alternative"
                                id="input-nome"
                                type="text"
                                required
                                value={this.state.nome}
                                onChange={(e) =>
                                  this.setState({ nome: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Tratamento Biológico * 
                                </label>

                              <select
                                value={this.state.tratamento}
                                onChange={(e) =>
                                  this.setState({
                                    tratamento: e.target.value,
                                  })
                                }
                                className="form-control "
                                id="select-tratamento"
                              >
                                <option defaultValue="" value="">
                                  {" "}
                                    Selecione...{" "}
                                </option>
                                <option value="0">
                                  Nenhum
                                  </option>
                                <option value="1">A definir</option>
                                <option value="2">Povoado</option>
                                <option value="3">Monitorado</option>
                                <option value="4">Clorado</option>
                              </select>
                            </FormGroup>
                          </Col>

                            <Col lg="3">

                          <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Tipo de  Reservatório *
                                </label>

                              <select
                                value={this.state.tipo}
                                onChange={(e) =>
                                  this.setState({
                                    tipo: e.target.value,
                                  })
                                }
                                className="form-control "
                                id="select-tratamento"
                              >
                                <option defaultValue="" value="">
                                  {" "}
                                    Selecione...{" "}
                                </option>
                                <option value="bolsao">
                                    Bolsão
                                  </option>
                                  <option value="piscina">
                                    Piscina
                                  </option>
                                  <option value="tanque">Tanque</option>
                                  <option value="poco">
                                    Poço
                                  </option>
                                  <option value="mina">
                                    Mina
                                  </option>
                                  <option value="outros">Outros</option>
                              </select>
                            </FormGroup>
                            
                          </Col>
                        
                        </Row>


                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Logradouro *
                                </label>
                              <Input
                                className="form-control-alternative"
                                id="input-address"
                                disabled={this.state.selectedAddress !== ""}
                                placeholder=""
                                type="text"
                                value={this.state.logradouro}
                                onChange={(e) =>
                                  this.setState({
                                    logradouro: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-bairro"
                              >
                                Localidade *
                            </label>

                              <Input
                                className="form-control-alternative"
                                id="input-lado"
                                type="text"
                                maxLength="30"
                                disabled={this.state.selectedAddress !== ""}
                                value={this.state.bairro}
                                onChange={(e) =>
                                  this.setState({ bairro: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                              >
                                Número *
                                </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder=""
                                type="text"
                                value={this.state.numero}
                                onChange={(e) =>
                                  this.setState({ numero: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Volume (Litros) *
                            </label>

                              <InputNumber
                                onlydigits
                                className="form-control"
                                id="input-volume"
                                inputMode="numeric"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 6);
                                }}
                                placeholder=""
                                value={this.state.volume}
                                onChange={(e) =>
                                  this.setState({ volume: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>


                        </Row>

                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-complement"
                              >
                                Complemento
                                </label>
                              <Input
                                className="form-control-alternative"
                                id="input-complement"
                                placeholder=""
                                type="text"
                                maxLength={20}
                                value={this.state.complemento}
                                onChange={(e) =>
                                  this.setState({
                                    complemento: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-comp"
                              >
                                Quarteirão
                            </label>

                              <InputNumber
                                onlydigits
                                className="form-control"
                                id="input-quarteirao"
                                inputMode="numeric"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 4);
                                }}
                                placeholder=""
                                value={this.state.quarteirao}
                                onChange={(e) =>
                                  this.setState({ quarteirao: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-comp"
                              >
                                Lado
                            </label>

                              <Input
                                className="form-control-alternative"
                                id="input-comp"
                                type="text"
                                required
                                maxLength={4}
                                value={this.state.lado}
                                onChange={(e) =>
                                  this.setState({ lado: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <div className="text-left mt-4 pt-2">
                                <Button
                                  className={this.state.selectedAddress === "" ? "btn-zoo-alt" : "btn-zoo"}
                                  color="primary"
                                  type="button"
                                  onClick={this.handleSiat}
                                >
                                  {this.state.selectedAddress === "" ? "Consultar Endereço" : "Alterar Endereço"}

                                </Button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        {this.state.responseAddress && !this.state.requesting && this.state.selectedAddress === "" && (
                          <DataTable
                            noHeader={true}
                            pagination={true}
                            striped={true}
                            columns={this.columns}
                            sortable={true}
                            responsive={true}
                            highlightOnHover={true}
                            data={this.state.responseAddress}
                            noDataComponent="Nenhum registro encontrado."
                            paginationComponentOptions={this.defaultComponentOptions}
                            customStyles={this.customStyles}
                          />
                        )}

                        {this.state.requesting && (
                          <div className="text-center">
                            <Spinner
                              style={{ width: "3rem", height: "3rem" }}
                              color="primary"
                            />

                          </div>

                        )}
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-dtnascimento"
                              >
                                Descrição
                                </label>
                              <Input
                                id="input-conteudo"
                                maxLength={1000}
                                value={this.state.descricao}

                                onChange={(e) =>
                                  this.setState({
                                    descricao: e.target.value,
                                  })
                                }
                                rows="6"
                                type="textarea"
                                className="form-control-alternative"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Foto
                            </label>
                              <ImageUploader
                                withIcon={false}
                                withLabel={false}
                                buttonText=""
                                onChange={this.onDrop}
                                imgExtension={[".jpg", ".jpeg", "png"]}
                                fileSizeError=" O arquivo deve ter até 5mb"
                                fileTypeError=" Extensão do arquivo não suportada"
                                maxFileSize={5242880}
                                buttonClassName={"zoo-feed-photo"}
                              />{" "}
                            </FormGroup>

                          </Col>

                        </Row>
                        {this.state.foto1 !== "" && (
                          <Row className="text-center">
                            <Col lg="12">
                              <FormGroup>
                                {this.state.foto1 !== "" && (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    className="img-fluid"
                                    src={this.state.foto1}
                                    alt="Imagem Notícia"
                                    onClick={() =>
                                      this.setState({ isOpen: true })
                                    }
                                  />
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </div>
                      {localStorage.getItem("perm") !== "agente" && (
                        <div className="text-center">
                          <Row>
                            <Col className="order-xl-1" xl="12">
                              <Button
                                className="mt-4 btn-zoo"
                                color="primary"
                                type="button"
                                onClick={this.handleSave}
                              >
                                <b>
                                  {this.state.isEdicao
                                    ? "Salvar Alterações"
                                    : "Cadastrar"}
                                </b>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Form>
                  )}
                  {this.state.requestingPool && (
                    <div className="text-center">
                      <Spinner
                        style={{ width: "3rem", height: "3rem" }}
                        color="primary"
                      />

                    </div>

                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
