import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import InputMask from "react-input-mask";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Header from "components/Headers/Header.js";
import Util from "shared/services/Util";
import { telMask } from "shared/services/Mask";
import Api from "shared/resources/Api";
import InputNumber from "react-input-just-numbers";

import { equipes } from "shared/services/PlacesService";

export default class AgentList extends React.Component {
  /* Dados Grid */
  columns = [
    {
      name: "Função",
      selector: "tipo",
      cell: (row) => (
        <div>
          {row.tipo !== undefined && row.tipo !== null
            ? Util.snakeToPascal(Util.formatFuncao(row.tipo))
            : "-"}
        </div>
      ),
    },
    {
      name: "Nome usuário",
      selector: "nome",
    },

    {
      name: "CPF",
      cell: (row) => (
        <div>
          {row.cpf.replace(/\D/g, "").substring(0, 3) +
            "." +
            row.cpf
              .replace(/\D/g, "")
              .substring(3, 9)
              .replace(/^.{6}/g, "XXX.XXX") +
            "-" +
            row.cpf.replace(/\D/g, "").slice(-2).replace(/^.{2}/g, "XX")}
        </div>
      ),
    },
    {
      name: "Telefone",
      cell: (row) => <div>{telMask(row.cel)}</div>,
    },
    {
      name: 'Equipe',
      selector: 'equipe',
      sortable: true,
      cell: (row) => (
        <div>
          {row.equipe_descricao
            ? Util.snakeToPascal(Util.formatEquipe(row.equipe_descricao))
            : '-'}
        </div>
      ),
    },
    {
      name: "Situação",
      selector: "situacao_funcional",
      cell: (row) => {
        const tratamentoValue = row.situacao_funcional;
        let displayedText = "";

        if (tratamentoValue !== null && tratamentoValue !== undefined) {
          
            // Your switch cases here
          
    
        switch (tratamentoValue.toLowerCase()) {
          case 'abandono':
            displayedText = 'Abandono';
            break;
          case 'afastado':
            displayedText = 'Atestado Médico';
            break;
          case 'ativo_em_funcao':
            displayedText = 'Ativo na função';
            break;
          case 'cedido':
            displayedText = 'Cedido-Outra unidade PMU';
            break;
          case 'convocacao_juri_popular':
            displayedText = 'Convocação - Juri Popular';
            break;
          case 'desligado':
            displayedText = 'Desligado';
            break;
          case 'ferias':
              displayedText = 'Férias';
              break;
          case 'justica_eleitoral':
            displayedText = 'Justiça Eleitoral';
            break;
          case 'licenca_interesses_pessoais':
            displayedText = 'Licença Interesses Pessoais';
            break;
          case 'licenca_maternidade':
            displayedText = 'Licença Maternidade';
            break;
          case 'readaptado':
            displayedText = 'Readaptado';
            break;
          case 'ativo':
            displayedText = 'Ativo na Função';
            break;
          default:
            displayedText = "-";
            break;
        }

      
      } else {
        // Handle the case when tratamentoValue is null or undefined
        displayedText = "-"; // You can set a default value or handle it as per your requirements
      }
    
        return <div>{displayedText}</div>;
      },
    },
    {
      name: "Período de inatividade Início",
      selector: "inicio_inatividade",
      sortable: true,
    },
    {
      name: "Período de inatividade Fim",
      selector: "fim_inatividade",
      sortable: true,
    },
    {
      name: "",
      center: true,
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              this.props.history.push("/admin/agent-edit", {
                selectedAgent: row,
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {row.cpf !== localStorage.getItem("code").replace(/\D/g, "") && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      response: "",
      api: Api.baseURL,
      agent: [],
      tipo: "",
      nome: "",
      cpf: "",
      cel: "",
      matricula: "",
      situacao_funcional :"",
      equipes: [],
      requesting: false,
      status: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {

    if (localStorage.getItem("paramser")) {
     
      try {
        var obj = (localStorage.getItem("paramser"));
        console.log(obj.tipo)
        console.log(JSON.stringify(localStorage.params));
        var para = JSON.parse(localStorage.getItem("paramser"));
        var tipo = para.tipo;
       

       let reload = {
        nome: para.nome !== undefined ? para.nome : "",
        cpf: para.cpf !== undefined ? para.cpf.replace(/\D/g, "") : "",
        cel: para.cel !== undefined ? para.cel.replace(/\D/g, "") : "",
        matricula: para.matricula !== undefined ? para.matricula : "",
        situacao_funcional: para.situacao_funcional !== undefined ? para.situacao_funcional : "",
        tipo: para.tipo !== undefined ? para.tipo : "",
        equipe: para.equipe !== undefined ? para.equipe : "",

      };
      let endpoint = "usuario?status=";
      let esc = encodeURIComponent;
      let query = Object.keys(reload)
      .map((k) => esc(k) + "=" + esc(reload[k]))
      .join("&");

      

      let host = this.state.api + `/${endpoint}`;

      let path =
      reload.status === "todos"
        ? "&" + query
        : "" + "&" + query;

        const respons2 = fetch(host + path, {
          headers: {
            Authorization: localStorage.getItem("agToken"),
          },
        })
        .then((respons2) => {
          if (!respons2.ok) {
            throw new Error("Network response was not ok");
          }
          return respons2.json();
        })
        .then((body) => {
          console.log("body"+body)
          localStorage.setItem("gridage", JSON.stringify(body));
          var obj2 = JSON.parse(localStorage.getItem("gridage"));
          this.setState({
            response: obj2,
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });


      console.log(reload)
        // Do something with the parsed object
      } catch (e) {
        console.error("Invalid JSON string:", e);
      }
    }





    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    if (localStorage.getItem("gridage")) {
 
      var obj = JSON.parse(localStorage.getItem("gridage"));
      this.setState({
        response: obj,
      })
    }
    equipes().then((res) => {
      if (res) {
        this.setState({
          equipes: res,
        });
      }
    });
  }

  fetchEquipes = async () => {
    const response = await fetch(Api.baseURL + "/unidade_saude/equipe?status=S", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };


  handleSubmit(event) {
    if (this.validate()) {
      this.setState({ response: "", requesting: true });
      this.fetchAgent()
        .then((res) => this.setState({ response: res }, () => {
          localStorage.setItem("gridage", JSON.stringify(res));
        }))
        .then()
        .catch((err) => console.log(err));

      event.preventDefault();
    }
  }

  fetchAgent = async () => {
    let params = {
      nome: this.state.nome,
      cpf: this.state.cpf.replace(/\D/g, ""),
      cel: this.state.cel.replace(/\D/g, ""),
      matricula: this.state.matricula,
      situacao_funcional: this.state.situacao_funcional,
      tipo: this.state.tipo,
      equipe:
          this.state.equipe ?
             this.state.equipe : ""
    };

    localStorage.setItem("paramser", JSON.stringify(params));

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let host = this.state.api + "/usuario?status=";

    let path =
      this.state.status === "todos"
        ? "&" + query
        : this.state.status + "&" + query;

    const response = await fetch(host + path, {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        agent: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não foram encontrados usuários para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };

  validate() {
    if (this.state.cpf !== "") {
      if (!Util.validaCPF(this.state.cpf.replace(/\D/g, ""))) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "CPF inválido.",
            type: "error",
          },
        });

        return false;
      }
    }

    if (this.state.cel !== "") {
      if (this.state.cel.replace(/\D/g, "").length !== 11) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Telefone inválido",
            type: "error",
          },
        });

        return false;
      }
    }

    return true;
  }

  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "error",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      agent: element,
      isConfirmation: true,
    });
  };
  handleDeleteAgent(d) {
    this.callDelAgent(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDelAgent = async (d) => {
    const response = await fetch(Api.baseURL + "/usuario/" + d.cpf, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Usuário deletado com sucesso!",
          type: "success",
        },
        response: "",
        tipo: "",
        nome: "",
        cpf: "",
        cel: "",
        matricula: "",
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar usuário.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteAgent(this.state.agent);
            }
          }}
        />

        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Consultar Servidor
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Função
                            </label>

                            <select
                              value={this.state.tipo}
                              onChange={(e) =>
                                this.setState({ tipo: e.target.value })
                              }
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">Todos</option>
                              <option value="agente">Agente</option>
                              <option value="coordenador">Coordenador</option>
                              <option value="operador">Operador</option>
                              <option value="supervisaoentual">Inserção de Informações</option>
                              <option value="supervisao_area">Supervisor de Área</option>
                              <option value="supervisao_turma">Supervisor de Turma</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Nome Usuário
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-nome"
                              type="text"
                              required
                              value={this.state.nome}
                              onChange={(e) =>
                                this.setState({ nome: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">

                        <FormGroup>
                            {this.state.equipes.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Equipe
                                </label>

                                <select
                                  value={this.state.equipe}
                                  onChange={(e) =>
                                    this.setState({
                                      equipe: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  {this.state.equipes.map((team) => (
                                    <option
                                     
                                      value={team.nm_equipe}
                                      defaultValue={this.state.equipe}
                                    >
                                      {team.ds_descricao_equipe.charAt(0).toUpperCase() + team.ds_descricao_equipe.slice(1).toLowerCase()}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cpf"
                            >
                              CPF
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-cpf"
                              type="text"
                              required
                              inputMode="numeric"
                              value={this.state.cpf}
                              onChange={(e) =>
                                this.setState({ cpf: cpfMask(e.target.value) })
                              }
                            />
                          </FormGroup>
                        </Col> */}
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Telefone
                            </label>
                            <InputMask
                              mask="(99) 99999-9999"
                              type="text"
                              name="cel"
                              inputMode="numeric"
                              id="cel"
                              className="form-control"
                              value={this.state.cel}
                              onChange={(e) =>
                                this.setState({ cel: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Matrícula
                            </label>

                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-matricula"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder=""
                              value={this.state.matricula}
                              onChange={(e) =>
                                this.setState({ matricula: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Situação Funcional
                            </label>

                            <select
                              value={this.state.situacao_funcional}
                              onChange={(e) => {
                                this.setState({ situacao_funcional: e.target.value })
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="abandono">Abandono</option>
                              <option value="afastado">Atestado Médico</option>
                              <option value="ativo">Ativo na função</option>
                              <option value="cedido">Cedido-Outra unidade PMU</option>
                              <option value="convocacao_juri_popular">Convocação - Juri Popular</option>
                              <option value="desligado">Desligado</option>
                              <option value="ferias">Férias</option>
                              <option value="justica_eleitoral">Justiça Eleitoral</option>
                              <option value="licenca_interesses_pessoais">Licença Interesses Pessoais</option>
                              <option value="licenca_maternidade">Licença Maternidade</option>
                              <option value="readaptado">Readaptado</option>
                              
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {" "}
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                          <Button
                            className="mt-4 btn-zoo"
                            color="primary"
                            type="button"
                            onClick={() => {
                              this.props.history.push("/admin/agent-edit");
                            }}
                          >
                            <b>Cadastrar</b>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              paginationComponentOptions={this.defaultComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={this.customStyles}
              noDataComponent="Nenhum registro encontrado."
              paginationResetDefaultPage={this.state.response.length > 0}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
