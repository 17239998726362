import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import InputMask from "react-input-mask";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import Header from "components/Headers/Header.js";
import Util from "shared/services/Util";
import { telMask } from "shared/services/Mask";
import Api from "shared/resources/Api";
import InputNumber from "react-input-just-numbers";

import { equipes } from "shared/services/PlacesService";
import ReactInputMask from 'react-input-mask';

export default class EmpresaList extends React.Component {
  /* Dados Grid */
  columns = [
    {
      name: "Razão Social",
      selector: "tipo",
      cell: (row) => (
        <div>
        {row.ds_razao_fornecedor_servico}
        </div>
      ),
    },
    {
      name: "CNPJ",
      cell: (row) => (
        <div>
        {row.nr_cnpj ? row.nr_cnpj : "00.000.000/0000-00"
          .replace(/\D/g, "") // Remove non-digit characters
          .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5")}
      </div>
      ),
      style: {
        minWidth: '170px', // Set minimum width for the email column
      },
    },

    // {
    //   name: "CNPJ",
    //   cell: (row) => (
    //     <div>
    //       {row.cpf.replace(/\D/g, "").substring(0, 3) +
    //         "." +
    //         row.cpf
    //           .replace(/\D/g, "")
    //           .substring(3, 9)
    //           .replace(/^.{6}/g, "XXX.XXX") +
    //         "-" +
    //         row.cpf.replace(/\D/g, "").slice(-2).replace(/^.{2}/g, "XX")}
    //     </div>
    //   ),
    // },
    {
      name: "Telefone",
      cell: (row) => <div>{telMask(row.nr_telefone)}</div>,
    },
    {
      name: 'Cep',
    
      sortable: true,
      cell: (row) => (
        <div>
          {row.nr_cep ? row.nr_cep.replace(/^(\d{5})(\d{3})$/, "$1-$2") : ""}
        </div>
      ),
    },
   
    {
      name: "Bairro",
      selector: "nm_bairro",
      sortable: true,
    },
    {
      name: "Email",
      selector: "ds_email",
      sortable: true,
      style: {
        minWidth: '205px', // Set minimum width for the email column
      },
    },

    {
      name: 'Status',
      cell: (row) => (
        <div>
          {row.tp_ativo && row.tp_ativo.toLowerCase() === 'a' ? 'Ativo' : row.tp_ativo && row.tp_ativo.toLowerCase() === 'i' ? 'Inativo' : ''}
        </div>
      ),
    },
  
    {
      name: "",
      center: true,
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              this.props.history.push("/admin/empresadit", {
                selectedAgent: row,
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>

          

          <Button
              type="button"
              className="btn-acao "
              title="Reenviar"
              onClick={() => {
                this.handleReenviar(row);
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} className="edit" />
            </Button>
          {/* {row.cpf !== localStorage.getItem("code").replace(/\D/g, "") && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )} */}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      response: "",
      api: Api.baseURL,
      agent: [],
      tipo: "",
      nome: "",
      razao: "",
      cnpj: "",
      ativo: "",
      cpf: "",
      cel: "",
      matricula: "",
      situacao_funcional :"",
      equipes: [],
      requesting: false,
      requestingemail: false,
      status: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {

    if (localStorage.getItem("paramemp")) {


    //   const res = [{
    //     "equipe": "Empresa Teste",
    //     "cnpj": "18431312001006",
    //     "matricula": "253170",
    //     "cep": "38200-000",
    //     "email": "Waldemar@systemid.com",
    //     "bairro": "Santa Monica",
    //     "cel": "34996709636",
    //     "descricao_inatividade": null,
    //     "senha_a_expirar": true,
    //     "vinculo": "efetivo",
    //     "horario_criacao": "2020-05-27T14:28:01",
    //     "habilitacao": "nao_possui",
    //     "situacao_funcional": "ativo",
    //     "tipo": "Empresa Testee",
    //     "id": 366.0,
    //     "ativo": true,
    //     "media": {
    //         "avatar": "http://zoonoses-api.uberlandia.mg.gov.br/usuario/avatar"
    //     },
    //     "codigo_sms": null,
    //     "fim_inatividade": null,
    //     "habilidades_especificas": "Agente",
    //     "nome": "Daiane Marques Silva",
    //     "endereco": {
    //         "logradouro": "rua Ademar Martins Costa",
    //         "bairro": "Val Paraiso",
    //         "numero": "411"
    //     },
    //     "equipe_descricao": "Chefes de Área"
    // }];
    // localStorage.setItem("gridemp", JSON.stringify(res));
     
      try {
        var obj = (localStorage.getItem("paramemp"));
        // console.log(obj.tipo)
        // console.log(JSON.stringify(localStorage.params));
        var para = JSON.parse(localStorage.getItem("paramemp"));
        var tipo = para.tipo;
       

       let reload = {
        razao: para.nome !== undefined ? para.razao : "",
        cnpj: para.cnpj !== undefined ? para.cnpj : "",
        ativo: para.ativo !== undefined ? para.ativo : "",
        

      };
      let endpoint = "Fornecedores?status=";
      let esc = encodeURIComponent;
      let query = Object.keys(reload)
      .map((k) => esc(k) + "=" + esc(reload[k]))
      .join("&");

      

      let host = this.state.api + `/${endpoint}`;

      let path =
      reload.status === "todos"
        ? "&" + query
        : "" + "&" + query;

        const respons2 = fetch(host + path, {
          headers: {
            Authorization: localStorage.getItem("agToken"),
          },
        })
        .then((respons2) => {
          if (!respons2.ok) {
            throw new Error("Network response was not ok");
          }
          return respons2.json();
        })
        .then((body) => {
          console.log("body"+body)
          localStorage.setItem("gridemp", JSON.stringify(body));
          var obj2 = JSON.parse(localStorage.getItem("gridemp"));
          this.setState({
            response: obj2,
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });


      console.log(reload)
        // Do something with the parsed object
      } catch (e) {
        console.error("Invalid JSON string:", e);
      }
    }





    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    if (localStorage.getItem("gridemp")) {
 
      var obj = JSON.parse(localStorage.getItem("gridemp"));
      this.setState({
        response: obj,
      })
    }
    equipes().then((res) => {
      if (res) {
        this.setState({
          equipes: res,
        });
      }
    });
  }

  fetchEquipes = async () => {
    const response = await fetch(Api.baseURL + "/unidade_saude/equipe?status=S", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };


  handleSubmit(event) {
    // if (this.validate()) {
      this.setState({  requesting: true });

    //   const res = [{
    //     "equipe": "Empresa Teste",
    //     "cnpj": "18431312001006",
    //     "matricula": "253170",
    //     "formacao": "pos_graduacao",
    //     "email": "daianemarques3110@gmail.com",
    //     "inicio_inatividade": null,
    //     "cel": "34996709636",
    //     "descricao_inatividade": null,
    //     "senha_a_expirar": true,
    //     "vinculo": "efetivo",
    //     "horario_criacao": "2020-05-27T14:28:01",
    //     "habilitacao": "nao_possui",
    //     "situacao_funcional": "ativo",
    //     "tipo": "Empresa Testee",
    //     "id": 366.0,
    //     "ativo": true,
    //     "media": {
    //         "avatar": "http://zoonoses-api.uberlandia.mg.gov.br/usuario/avatar"
    //     },
    //     "codigo_sms": null,
    //     "fim_inatividade": null,
    //     "habilidades_especificas": "Agente",
    //     "nome": "Daiane Marques Silva",
    //     "endereco": {
    //         "logradouro": "rua Ademar Martins Costa",
    //         "bairro": "Val Paraiso",
    //         "numero": "411"
    //     },
    //     "equipe_descricao": "Chefes de Área"
    // }];
    
    // localStorage.setItem("gridemp", JSON.stringify(res));

    this.setState({ requesting: false });

      this.fetchAgent()
        .then((res) => this.setState({ response: res }, () => {
          localStorage.setItem("gridemp", JSON.stringify(res));
        }))
        .then()
        .catch((err) => console.log(err));

      event.preventDefault();
    // }
  }

  fetchAgent = async () => {
    let params = {
      razao: this.state.razao,
      cnpj: this.state.cnpj,
      ativo: this.state.ativo,
      // matricula: this.state.matricula,
      
    };

    localStorage.setItem("paramemp", JSON.stringify(params));

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let host = this.state.api + "/Fornecedores?status=";

    let path =
      this.state.status === "todos"
        ? "&" + query
        : this.state.status + "&" + query;

    const response = await fetch(host + path, {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        // swal: {
        //   show: true,
        //   title: "Sucesso",
        //   text: "Resultados encontrados.",
        //   type: "success",
        // },
        agent: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não foram encontrados resultados para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };

  // validate() {
  //   if (this.state.cpf !== "") {
  //     if (!Util.validaCPF(this.state.cpf.replace(/\D/g, ""))) {
  //       this.setState({
  //         swal: {
  //           show: true,
  //           title: "Atenção",
  //           text: "CPF inválido.",
  //           type: "error",
  //         },
  //       });

  //       return false;
  //     }
  //   }

  //   if (this.state.cel !== "") {
  //     if (this.state.cel.replace(/\D/g, "").length !== 11) {
  //       this.setState({
  //         swal: {
  //           show: true,
  //           title: "Atenção",
  //           text: "Telefone inválido",
  //           type: "error",
  //         },
  //       });

  //       return false;
  //     }
  //   }

  //   return true;
  // }

  handleReenviar = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja Reenviar Email de confirmação para "+element.ds_email+"?",
        type: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      agent: element,
      isConfirmationReenviar: true,
    });
  };

  handleReenviarAgent(d) {
    this.callReenviarAgent(d)
      .then()
      .catch((err) => console.log(err));
  }

  callReenviarAgent = async (d) => {

  

    var data = JSON.stringify({
      email: d.ds_email,
      user: d.nr_cnpj,
      secret: d.ds_secret
      
      
      
    });

    const response = await fetch(Api.baseURL + "/Fornecedores/email",{
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
      body: data
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "E-mail enviado com sucesso para "+d.ds_email,
          type: "success",
        },
        
        tipo: "",
        nome: "",
        cpf: "",
        cel: "",
        matricula: "",
      
       
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "serviço temporariamente indisponível",
          type: "error",
        },
    
      });

      throw Error(body.message);
    }

    
  };


  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja Inativar registro?",
        type: "error",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      agent: element,
      isConfirmation: true,
    });
  };
  // handleDeleteAgent(d) {
  //   this.callDelAgent(d)
  //     .then()
  //     .catch((err) => console.log(err));
  // }

  callDelAgent = async (d) => {
    const response = await fetch(Api.baseURL + "/usuario/" + d.cpf, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Usuário deletado com sucesso!",
          type: "success",
        },
        response: "",
        tipo: "",
        nome: "",
        cpf: "",
        cel: "",
        matricula: "",
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar usuário.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmationReenviar && !result.dismiss) {
              this.setState({
                isConfirmationReenviar: false,
              });
              this.handleReenviarAgent(this.state.agent);
            }

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteAgent(this.state.agent);
            }
          }}
        />

        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Consultar Empresa
                    </h6>
                    <div className="pl-lg-4">
                      <Row>

                      <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Razão Social
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-razao"
                              type="text"
                              required
                              value={this.state.razao}
                              onChange={(e) =>
                                this.setState({ razao: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              CNPJ
                            </label>

                            <ReactInputMask
                              className="form-control"
                              id="input-nome"
                              mask="99.999.999/9999-99"
                              type="text"
                              required
                              value={this.state.cnpj}
                              onChange={(e) =>
                                this.setState({ cnpj: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                       
                    
                    
                        {/* <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cpf"
                            >
                              CPF
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-cpf"
                              type="text"
                              required
                              inputMode="numeric"
                              value={this.state.cpf}
                              onChange={(e) =>
                                this.setState({ cpf: cpfMask(e.target.value) })
                              }
                            />
                          </FormGroup>
                        </Col> */}
                       
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Status
                            </label>

                            <select
                              value={this.state.ativo}
                              onChange={(e) => {
                                this.setState({ ativo: e.target.value })
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="A">Ativo</option>
                              <option value="I">Inativo</option>
                              
                              
                            </select>
                          </FormGroup>
                        </Col>
                        </Row>
                   
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {" "}
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                          {/* <Button
                            className="mt-4 btn-zoo"
                            color="primary"
                            type="button"
                            onClick={() => {
                              this.props.history.push("/admin/agent-edit");
                            }}
                          >
                            <b>Cadastrar</b>
                          </Button> */}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              paginationComponentOptions={this.defaultComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={this.customStyles}
              noDataComponent="Nenhum registro encontrado."
              paginationResetDefaultPage={this.state.response.length > 0}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
