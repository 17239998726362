import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from "reactstrap";

import InputMask from "react-input-mask";

import { cpfMask } from "shared/services/Mask";
import api from "shared/resources/Api";
import Util from "shared/services/Util";
import SweetAlert2 from "react-sweetalert2";
import InputNumber from "react-input-just-numbers";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpf: "",
      nome: "",
      matricula: "",
      email: "",
      cel: "",
      senha: "",
      senha2: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      tipo: "",
      api: api.baseURL,
      isSuccess: false,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  handleSave(event) {
    event.preventDefault();
    if (this.validateFields()) {
      this.callSave();
    }
  }

  validateFields() {
    if (this.state.cpf === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o CPF.",
          type: "warning",
        },
      });
      return;
    }

    if (
      this.state.cpf !== "" &&
      !Util.validaCPF(this.state.cpf.replace(/\D/g, ""))
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "CPF inválido",
          type: "warning",
        },
      });
      return;
    }

    if (this.state.matricula === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Matrícula.",
          type: "warning",
        },
      });
      return;
    }

    if (this.state.nome === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Nome.",
          type: "warning",
        },
      });
      return;
    }

    if (this.state.cel === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Celular.",
          type: "warning",
        },
      });
      return;
    }

    if (
      this.state.cel !== "" &&
      this.state.cel.replace(/\D/g, "").length !== 11
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o celular com DDD e nono dígito.",
          type: "warning",
        },
      });
      return;
    }

    if (this.state.senha === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Senha.",
          type: "warning",
        },
      });
      return;
    }

    if (this.state.senha2 === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Confirme a Senha.",
          type: "warning",
        },
      });
      return;
    }

    if (this.state.senha !== "" && this.state.senha !== "") {
      if (this.state.senha !== this.state.senha2) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "As senhas não são iguais.",
            type: "warning",
          },
        });
        return;
      }
    }

    if (this.state.email && this.state.email !== "" && !Util.validateEmail(this.state.email)) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "E-mail inválido",
          type: "warning",

        }
      });

      return false;
    }

    return true;
  }

  handleTipo(event) {
    this.setState({ tipo: event.target.value }, () =>
      console.log(this.state.tipo)
    );
  }

  callSave = async () => {
    const response = await fetch(api.baseURL + "/usuario/unsafe", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cpf: this.state.cpf.replace(/\D/g, ""),
        nome: this.state.nome,
        matricula: this.state.matricula,
        email: this.state.email,
        cel: this.state.cel,
        senha: this.state.senha,
        endereco: {
          logradouro: this.state.logradouro.trim(),
          bairro: this.state.bairro,
          numero: this.state.numero,
          complemento: this.state.complemento,
        },
      }),
    });
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Faça o login para acessar o sistema.",
          type: "success",
        },
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: body.erro,
          type: "error",
        },
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao cadastrar usuário: " + body.erro,
          type: "error",
        },
      });
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/feed");
            }
          }}
        />
        <Col lg="12" md="12">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <strong>Cadastro</strong>
              </div>
              <Form role="form" onSubmit={this.handleSave}>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-badge" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="CPF *"
                          name="cpf"
                          type="text"
                          autoComplete="new-cpf"
                          inputMode="numeric"
                          value={this.state.cpf}
                          onChange={(e) =>
                            this.setState({ cpf: cpfMask(e.target.value) })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-archive-2" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <InputNumber
                          onlydigits
                          className="form-control"
                          inputMode="numeric"
                          id="input-matricula"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 6);
                          }}
                          placeholder="Matrícula *"
                          maxLength={6}
                          value={this.state.matricula}
                          onChange={(e) =>
                            this.setState({ matricula: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nome *"
                      type="text"
                      autoComplete="new-nome"
                      maxLength={40}
                      value={this.state.nome}
                      onChange={(e) => this.setState({ nome: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-mobile-button" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <InputMask
                          className="form-control"
                          mask="(99) 99999-9999"
                          inputMode="numeric"
                          placeholder="Celular *"
                          type="text"
                          autoComplete="new-cel"
                          value={this.state.cel}
                          onChange={(e) =>
                            this.setState({ cel: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="E-mail"
                          autoComplete="new-email"
                          maxLength={40}
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-building" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Logradouro"
                          type="text"
                          autoComplete="new-logradouro"
                          maxLength={40}
                          value={this.state.logradouro}
                          onChange={(e) =>
                            this.setState({ logradouro: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-building" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Número"
                          type="text"
                          maxLength={40}
                          autoComplete="new-numero"
                          value={this.state.numero}
                          onChange={(e) =>
                            this.setState({ numero: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-building" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Bairro"
                          type="text"
                          autoComplete="new-bairro"
                          maxLength={40}
                          value={this.state.bairro}
                          onChange={(e) =>
                            this.setState({ bairro: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-building" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Complemento"
                          maxLength={40}
                          type="text"
                          autoComplete="new-complemento"
                          value={this.state.complemento}
                          onChange={(e) =>
                            this.setState({ complemento: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Senha *"
                          maxLength={20}
                          type="password"
                          autoComplete="new-password"
                          value={this.state.senha}
                          onChange={(e) =>
                            this.setState({ senha: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Repetir Senha *"
                          type="password"
                          maxLength={20}
                          autoComplete="new-password"
                          value={this.state.senha2}
                          onChange={(e) =>
                            this.setState({ senha2: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-center">
                  <Row>
                    <Col className="order-xl-1" xl="12">
                      <Button
                        className="mt-4 btn-zoo-alt"
                        color="primary"
                        type="button"
                        onClick={() => this.props.history.push("/auth/index")}
                      >
                        <b>Voltar</b>
                      </Button>
                      <Button
                        className="mt-4 btn-zoo"
                        color="primary"
                        type="submit"
                      >
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Register;
