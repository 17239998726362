import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
// core components
import InputMask from 'react-input-mask';
// import InputNumber from 'react-input-just-numbers';
import Header from 'components/Headers/Header.js';
import { cpfMask } from 'shared/services/Mask';
import Api from 'shared/resources/Api';
import Util from 'shared/services/Util';
import SweetAlert2 from 'react-sweetalert2';
import ImageUploader from 'react-images-upload';
import AvatarService from 'shared/services/AvatarService';
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpf: '',
      nome: '',
      matricula: '',
      email: '',
      cel: '',
      senha: '',
      senha2: '',
      logradouro: '',
      numero: '',
      bairro: '',
      complemento: '',
      tipo: '',
      api: Api.baseURL,
      isEdicao: false,
      formTitle: 'CADASTRAR',
      foto: '',
      file: null,
    };

    this.handleSave = this.handleSave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }
  componentWillMount() {
    const cpf = localStorage.getItem('code')
      ? localStorage.getItem('code').replace(/\D/g, '')
      : undefined;
    if (cpf) {
      AvatarService.getAvatar();
      this.fetchUser(cpf)
        .then((res) => {
          let user = res;

          this.setState(
            {
              selectedAgent: user,
              loading: false,
              cpf: cpfMask(user.cpf),
              nome: user.nome,
              tipo: user.tipo,
              cel: user.cel,
              matricula: user.matricula,
              logradouro: user.endereco.logradouro.trim(),
              bairro: user.endereco.bairro,
              numero: user.endereco.numero,
              complemento: user.endereco.complemento,
              requesting: false,
              email: user.email,
              isEdicao: true,
              formTitle: 'EDITAR',
            },
            () => console.log('state logou end')
          );
        })
        .catch((err) => console.log(err));
    }
  }
  fetchUser = async (cpf) => {
    let host = this.state.api + '/usuario/info';

    const response = await fetch(host, {
      headers: {
        Authorization: localStorage.getItem('agToken'),
      },
    });

    const body = await response.json();

    return body;
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({ requesting: true });
    this.callSaveImage(
      pictureDataURLs[pictureDataURLs.length - 1],
      this.state.selectedAgent.cpf.replace(/\D/g, '')
    );
    this.setState({
      foto: pictureDataURLs[pictureDataURLs.length - 1],
      pictures: [],
      file: pictureFiles[pictureFiles.length - 1],
    });
  }
  handleSave(event) {
    if (this.validateFields()) {
      this.callSave();
    }
  }
  validateFields() {
    if (!this.state.isEdicao && this.state.senha === '') {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Informe a Senha.',
          type: 'warning',
        },
      });
      return false;
    }
    if (!this.state.isEdicao && this.state.senha2 === '') {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Confirme a Senha.',
          type: 'warning',
        },
      });
      return false;
    }

    /* Validação obrigatoriedade */
    if (this.state.nome === undefined || this.state.nome.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Informe o Nome Usuário.',
          type: 'warning',
        },
      });

      return false;
    }
    if (
      this.state.cpf === undefined ||
      this.state.cpf.replace(/\D/g, '').length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Informe o CPF.',
          type: 'warning',
        },
      });

      return false;
    }
    if (
      this.state.cel === undefined ||
      this.state.cel.replace(/\D/g, '').length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Informe o Celular.',
          type: 'warning',
        },
      });

      return false;
    }
    if (this.state.tipo === undefined || this.state.tipo.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Informe a Permissão.',
          type: 'warning',
        },
      });

      return false;
    }

    if (
      !this.state.matricula ||
      this.state.matricula === '' ||
      this.state.matricula.replace(/\D/g, '').length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Informe a Matrícula.',
          type: 'warning',
        },
      });

      return false;
    }

    /* Validação obrigatoriedade */

    /* Validação integridade */
    if (
      this.state.cpf !== '' &&
      !Util.validaCPF(this.state.cpf.replace(/\D/g, ''))
    ) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'CPF inválido',
          type: 'warning',
        },
      });

      return false;
    }
    if (
      this.state.email &&
      this.state.email !== '' &&
      !Util.validateEmail(this.state.email)
    ) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'E-mail inválido',
          type: 'warning',
        },
      });

      return false;
    }
    if (
      this.state.cel !== '' &&
      this.state.cel.replace(/\D/g, '').length !== 11
    ) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Preencha o celular com DDD e nono dígito.',
          type: 'warning',
        },
      });

      return false;
    }

    if (this.state.senha !== '' && this.state.senha2 !== '') {
      if (this.state.senha !== this.state.senha2) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'As senhas não são iguais.',
            type: 'warning',
          },
        });

        return false;
      }
    }
    /* Validação integridade */

    return true;
  }

  createBody = () => {
    if (this.state.selectedAgent) {
      return JSON.stringify({
        cpf:
          this.state.cpf !== ''
            ? this.state.cpf.replace(/\D/g, '')
            : this.state.selectedAgent.cpf.replace(/\D/g, ''),
        nome:
          this.state.nome !== ''
            ? this.state.nome
            : this.state.selectedAgent.nome,
        email: this.state.email,
        cel:
          this.state.cel !== ''
            ? this.state.cel.replace(/\D/g, '')
            : this.state.selectedAgent.cel.replace(/\D/g, ''),
        endereco: {
          logradouro: this.state.logradouro.trim(),
          bairro: this.state.bairro,
          numero: this.state.numero,
          complemento: this.state.complemento,
        },
      });
    } else {
      return JSON.stringify({
        cpf: this.state.cpf.replace(/\D/g, ''),
        nome: this.state.nome,
        email: this.state.email,
        cel: this.state.cel.replace(/\D/g, ''),
        endereco: {
          logradouro: this.state.logradouro.trim(),
          bairro: this.state.bairro,
          numero: this.state.numero,
          complemento: this.state.complemento,
        },
      });
    }
  };

  callSave = async () => {
    const response = await fetch(Api.baseURL + '/usuario/atualizar_cadastro', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('agToken'),
      },
      body: this.createBody(),
    });

    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: 'Tudo Certo',
          text: this.state.selectedAgent
            ? 'Edição realizada com sucesso!'
            : 'Cadastro realizado com sucesso!',
          type: 'success',
        },
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: body.erro,
          type: 'warning',
        },
      });
      console.log(body.erro);
    } else {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: this.state.selectedAgent
            ? 'Erro ao editar usuário. '
            : 'Erro ao cadastrar usuário.',
          type: 'warning',
        },
      });
      console.log(body.erro);
    }

    return body;
  };
  callSaveImage = async (file, cpf) => {
    const response = await fetch(Api.baseURL + `/usuario/${cpf}/avatar`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'content-type': 'image/png',
        Authorization: localStorage.getItem('agToken'),
      },
      body: file.split(',')[1],
    });

    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      sessionStorage.removeItem('avatar');
      AvatarService.getAvatar();
      this.setState({
        swal: {
          show: true,
          title: 'Tudo Certo',
          text: 'Imagem adicionada com sucesso!',
          type: 'success',
        },
        isSuccess: true,
        requesting: false,
      });
    } else if (body.sucesso === false) {
    
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: body.erro,
          type: 'warning',
        },
      });
    } else {
      
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'Erro ao adicionar imagem. ',
          type: 'warning',
        },
      });
    }

    return body;
  };
  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
          }}
        />
        <Header />
        {/* Page content */}
        <Container className='mt-7' fluid>
          <Row>
            <Col className='order-xl-2 mb-5 mb-xl-0' xl='4'>
              <Card className='card-profile shadow'>
                <Row className='justify-content-center'>
                  <Col className='order-lg-2' lg='3'>
                    <div className='card-profile-image'>
                      {AvatarService.avatar !== '' && (
                        <img
                          alt='...'
                          className='rounded-circle'
                          src={AvatarService.avatar}
                        />
                      )}
                      {AvatarService.avatar === '' && (
                        <img
                          alt='...'
                          className='rounded-circle'
                          src={require('assets/img/brand/user.png')}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <CardHeader className='text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4'>
                  <div className='d-flex justify-content-between'></div>
                </CardHeader>
                <CardBody className='pt-0 pt-md-4'>
                {!this.state.requesting && (
                  <Row>
                    <div className='col'>
                      <div className='card-profile-stats d-flex justify-content-center mt-6'>
                        <ImageUploader
                          withIcon={false}
                          withLabel={false}
                          buttonText=''
                          onChange={this.onDrop}
                          imgExtension={['.jpg', '.jpeg', 'png']}
                          fileSizeError=' O arquivo deve ter até 5mb'
                          fileTypeError=' Extensão do arquivo não suportada'
                          maxFileSize={5242880}
                          buttonClassName={'zoo-feed-photo'}
                        />
                      </div>
                    </div>
                  </Row>)}
                  {this.state.requesting && (
                    <div className='text-center mt-6'>
                      <Spinner
                        style={{ width: '3rem', height: '3rem' }}
                        color='primary'
                      />
                    </div>
                  )}
                  <Row>
                    <div className='col'>
                      <div className='card-profile-stats d-flex justify-content-center mt-6'>
                        <div>
                          <span className='heading'>{this.state.cpf}</span>
                          <span className='description'>CPF </span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className='col'>
                      <div className='card-profile-stats d-flex justify-content-center mt-2'>
                        <div>
                          <span className='heading'>
                            {this.state.matricula}
                          </span>
                          <span className='description'>MATRÍCULA </span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className='col'>
                      <div className='card-profile-stats d-flex justify-content-center mt-2'>
                        <div>
                          <span className='heading'>{this.state.tipo}</span>
                          <span className='description'>PERFIL </span>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className='order-xl-1' xl='8'>
              <Card className='bg-secondary shadow'>
                <CardHeader className='bg-white border-0'>
                  <Row className='align-items-center'>
                    <Col xs='8'>
                      <h3 className='mb-0'>Minha Conta</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className='heading-small text-muted mb-4'>
                      Informações do usuário
                    </h6>
                    <div className='pl-lg-4'>
                      <Row>
                        <Col lg='12'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-nome'
                            >
                              Nome Usuário *
                            </label>

                            <Input
                              className='form-control-alternative'
                              id='input-nome'
                              type='text'
                              required
                              value={this.state.nome}
                              onChange={(e) =>
                                this.setState({ nome: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className='my-4' />
                    {/* Address */}
                    <h6 className='heading-small text-muted mb-4'>Contato</h6>
                    <div className='pl-lg-4'>
                      <Row>
                        <Col lg='3'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Celular *
                            </label>
                            <InputMask
                              mask='(99) 99999-9999'
                              type='text'
                              name='cel'
                              inputMode='numeric'
                              id='cel'
                              className='form-control'
                              value={this.state.cel}
                              onChange={(e) =>
                                this.setState({ cel: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg='9'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-email'
                            >
                              E-mail
                            </label>

                            <Input
                              className='form-control-alternative'
                              id='input-email'
                              type='email'
                              autoComplete='new-email'
                              required
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className='my-4' />
                    {/* Description */}
                    <h6 className='heading-small text-muted mb-4'>Endereço</h6>
                    <div className='pl-lg-4'>
                      <Row>
                        <Col lg='9'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-endereco'
                            >
                              Endereço
                            </label>

                            <Input
                              className='form-control-alternative'
                              id='input-endereco'
                              type='text'
                              required
                              value={this.state.logradouro}
                              onChange={(e) =>
                                this.setState({ logradouro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg='3'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-num'
                            >
                              Nº
                            </label>

                            <Input
                              className='form-control-alternative'
                              id='input-num'
                              type='text'
                              required
                              value={this.state.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='5'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-bairro'
                            >
                              Bairro
                            </label>

                            <Input
                              className='form-control-alternative'
                              id='input-bairro'
                              type='text'
                              required
                              value={this.state.bairro}
                              onChange={(e) =>
                                this.setState({ bairro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg='4'>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-comp'
                            >
                              Complemento
                            </label>

                            <Input
                              className='form-control-alternative'
                              id='input-comp'
                              type='text'
                              required
                              value={this.state.complemento}
                              onChange={(e) =>
                                this.setState({ complemento: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className='text-center'>
                      <Row>
                        <Col className='order-xl-1' xl='12'>
                          <Button
                            className='mt-4 btn-zoo'
                            color='primary'
                            type='button'
                            onClick={this.handleSave}
                          >
                            <b>Salvar Alterações</b>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
