
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/scss/style.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import AuthService from "shared/services/AuthService";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        render={props =>
          AuthService.getAuth() ? (
            <AdminLayout path='admin' {...props} />
          ) : (
            <AuthLayout path='auth' {...props} />
          )
        }
      />
      <Redirect from="/" to="/auth/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
