import React from "react";
import SweetAlert2 from "react-sweetalert2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import api from "shared/resources/Api";
import { cpfMask } from "shared/services/Mask";
import InputMask from "react-input-mask";
import moment from "moment";

class CitizenEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      cpf: "",
      tel: "",
      status: "",
      data_nascimento: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      response: "",
      api: api.baseURL,
      citizen: [],
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount() {
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.selectedCitizen != null
    ) {
      this.setState(
        {
          selectedCitizen: this.props.location.state.selectedCitizen,
          citizen: this.props.location.state.citizen,
          cpf: this.props.location.state.selectedCitizen.cpf,
          nome: this.props.location.state.selectedCitizen.nome,
          tel: this.props.location.state.selectedCitizen.tel,
          data_nascimento: moment(
            moment(this.props.location.state.selectedCitizen.data_nascimento)
          ).format("DD/MM/YYYY"),
          status: this.props.location.state.selectedCitizen.status,
          logradouro: this.props.location.state.selectedCitizen.endereco.logradouro.trim(),
          numero: this.props.location.state.selectedCitizen.endereco.numero,
          bairro: this.props.location.state.selectedCitizen.endereco.bairro,
          complemento: this.props.location.state.selectedCitizen.endereco
            .complemento,
        },
        () => console.log("")
      );
    }
  }

  validateFields() {
    if (
      this.state.tel !== "" &&
      this.state.tel.replace(/\D/g, "").length !== 11
    ) {
      alert("Preencha o Celular com DDD e nono Dígito.");
      return false;
    }

    return true;
  }

  handleSave(event) {
    if (this.validateFields()) {
      this.callSave();
    }
  }

  callSave = async () => {
    console.log(this.state);
    const response = await fetch(
      api.baseURL + "/cidadao/" + this.state.cpf.replace(/\D/g, ""),
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: JSON.stringify({
          cpf:
            this.state.cpf !== ""
              ? this.state.cpf.replace(/\D/g, "")
              : this.state.selectedCitizen.cpf,
          nome:
            this.state.nome !== ""
              ? this.state.nome
              : this.state.selectedCitizen.nome,
          tel:
            this.state.tel !== ""
              ? this.state.tel
              : this.state.selectedCitizen.tel,
          data_nascimento:
            this.state.data_nascimento !== ""
              ? moment(moment(this.state.data_nascimento, "DD/MM/YYYY")).format(
                  "YYYY-MM-DD"
                )
              : this.state.selectedCitizen.data_nascimento,
          status:
            this.state.status !== ""
              ? this.state.status
              : this.state.selectedCitizen.status,
          endereco: {
            logradouro:
              this.state.logradouro !== ""
                ? this.state.logradouro.trim()
                : this.state.selectedCitizen.endereco.logradouro.trim(),
            bairro:
              this.state.bairro !== ""
                ? this.state.bairro
                : this.state.selectedCitizen.endereco.bairro,
            numero:
              this.state.numero !== ""
                ? this.state.numero
                : this.state.selectedCitizen.endereco.numero,
            complemento:
              this.state.complemento !== ""
                ? this.state.complemento
                : this.state.selectedCitizen.endereco.complemento,
          },
        }),
      }
    );
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Edição realizada com sucesso!",
          type: "success",
        },
      });
    } else if (body.sucesso === false) {
      alert(body.erro);
    } else {
      alert("Erro ao editar usuário: ", body.erro);
    }

    return body;
  };

  render() {
    return (
      <>
        <UserHeader />
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState(
              {
                swal: {
                  show: false,
                },
              },
              this.props.history.push("/admin/citizen")
            );
          }}
        />
        {/* Page content */}
        <Container className="main-zoo" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Cidadão</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Dados Pessoais
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cpf"
                            >
                              CPF
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-cpf"
                              placeholder=""
                              type="text"
                              value={cpfMask(this.state.cpf)}
                              disabled
                              onChange={(e) =>
                                this.setState({ cpf: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-nome"
                            >
                              Nome
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-nome"
                              placeholder=""
                              type="nome"
                              value={this.state.nome}
                              onChange={(e) =>
                                this.setState({ nome: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-cel"
                            >
                              Celular
                            </label>
                            <InputMask
                              mask="(99) 99999-9999"
                              type="text"
                              name="cel"
                              id="cel"
                              className="form-control"
                              value={this.state.tel}
                              onChange={(e) =>
                                this.setState({ tel: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-dtnascimento"
                            >
                              Data Nascimento
                            </label>
                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              name="cel"
                              id="cel"
                              className="form-control"
                              value={this.state.data_nascimento}
                              onChange={(e) =>
                                this.setState({
                                  data_nascimento: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-dtnascimento"
                            >
                              Status
                            </label>
                            <select
                              value={this.state.status}
                              onChange={(e) =>
                                this.setState({ status: e.target.value })
                              }
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">Todos</option>
                              <option value="ativo">ativo</option>
                              <option value="inativo">inativo</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Dados Endereço
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Logradouro
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-address"
                              placeholder=""
                              type="text"
                              value={this.state.logradouro}
                              onChange={(e) =>
                                this.setState({ logradouro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Número
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-number"
                              placeholder=""
                              type="text"
                              value={this.state.numero.replace(/\D/g, "")}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Bairro
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-district"
                              placeholder=""
                              type="text"
                              value={this.state.bairro}
                              onChange={(e) =>
                                this.setState({ bairro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-complement"
                            >
                              Complemento
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-complement"
                              placeholder=""
                              type="text"
                              value={this.state.complemento}
                              onChange={(e) =>
                                this.setState({ complemento: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {localStorage.getItem("perm") === "coordenador" && (
                        <div className="text-center">
                          <Button
                            className="mt-4 btn-zoo"
                            color="primary"
                            type="button"
                            onClick={this.handleSave}
                          >
                            Salvar Alterações
                          </Button>
                        </div>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CitizenEdit;
