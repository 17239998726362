import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert2 from "react-sweetalert2";

import Api from "shared/resources/Api";
import ImageUploader from "react-images-upload";

import Header from "components/Headers/Header.js";
import { neighborhood } from "shared/services/PlacesService";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import moment from "moment";
export default class NeighborhoodEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bairro: "",
      cod_bairro: "",
      api: Api.baseURL,
      isEdicao: false,
      foto: "",
      data: "",
      digitalSketch: [],
      selectedDigitalSketch: "",
      formTitle: "CADASTRAR",
      file: null,
      requesting: false,
      neighborhood: [],
      photoIndex: 0,
      isOpen: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentWillMount() {
    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.selectedDigitalSketch != null
    ) {
      // console.log(this.props.location.state.selectedDigitalSketch.media.foto1)
      this.setState(
        {
          selectedDigitalSketch: this.props.location.state
            .selectedDigitalSketch,
          digitalSketch: this.props.location.state.selectedDigitalSketch,
          loading: false,
          bairro: this.props.location.state.selectedDigitalSketch.nome,
          cod_bairro: this.props.location.state.selectedDigitalSketch
            .setor,
          id: this.props.location.state.selectedDigitalSketch.id,
          foto: "",
          codigo: "",
          isEdicao: true,
          formTitle: "EDITAR",
        },
        () => console.log("state logou end", this.state.status)
      );
    }
    
  }
  validate = () => {

    if(this.state.id === undefined)
    {
        if(this.state.codigo === undefined)
        {

          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "Informe o ID",
              type: "warning",
            },
          });
    
          return false;
        }



    }


    if (this.state.bairro === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.cod_bairro === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Setor",
          type: "warning",
        },
      });

      return false;
    }
    return true;
  };

  handleDownload = (event) => {
    event.preventDefault();
    try {
      
    const url = `${this.props.location.state.selectedDigitalSketch.media.foto1.replace('http://', 'https://')}?${moment().format('YYYYMMDDHHMMSS')}`;
    fetch(url)
    .then(res => res.blob())
    .then(blob => {
    const link = document.createElement('a');
    link.download = this.state.bairro? this.state.bairro+'.jpeg':'myImage.jpeg';
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
       });
    } catch (e) {
      console.error("Invalid JSON string:", e);
    }
  };

  handleSave() {
    if (this.validate()) {
      this.setState({ requesting: true });
      this.callApi();
    }
  }
  handleChange = (value) => {  
    if (value.length > 0) {
      this.setState({
        bairro: this.state.neighborhood.find((p) => p.id.toString() === value)
          .nome,
          cod_bairro: value,
      });
    } else {
      this.setState({
        bairro: "",
        cod_bairro: "",
      });
    }
  };
  callApi = async () => {
    var sketch = {
      
      nome: this.state.bairro,
      setor: this.state.cod_bairro,
      id: this.state.codigo,
      
    };

    var sketchid = {

      nome: this.state.bairro,
      setor: this.state.cod_bairro,
    };

    var requestBody = {
      bairro: sketchid,
    };
    
   
    var data = new FormData();
    data.append("bairro", JSON.stringify(sketchid));

    var data2 = new FormData();
    data2.append("body", JSON.stringify(sketch));

  

    let response = "";
    if (this.state.id > 0) {
      response = await fetch(Api.baseURL + "/bairro/" + this.state.id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: data,
      });
    } else {
      response = await fetch(Api.baseURL + "/bairro/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: data2,
      });
    }

    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo.",
          text:
            this.state.id > 0
              ? "Edição realizada com sucesso!"
              : "Cadastro realizado com sucesso!",
          type: "success",
        },
        requesting: false,
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Já possui ID/Bairro",
          type: "error",
        },
      });
      this.setState({ requesting: false });
    } else {
      throw Error(body.message);
    }

    return body;
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState(
      {
        foto: pictureDataURLs[pictureDataURLs.length - 1],
        pictures: [],
        file: pictureFiles[pictureFiles.length - 1],
      },
      () => console.log(this.state.feed)
    );
  }

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/neighboorhood");
            }
          }}
        />
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.state.foto}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {this.state.formTitle} Localidade
                    </h6>
                    <div className="pl-lg-4">
                      {this.state.id > 0 ? (
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-nome"
                              >
                                Código
                              </label>

                              <Input
                                className="form-control-alternative"
                                id="input-id"
                                type="number"
                                disabled
                                required
                                value={this.state.id}
                                onChange={(e) =>
                                  this.setState({ id: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ): 

                      <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome"
                          >
                            Código
                          </label>

                          <Input
                            className="form-control-alternative"
                            id="input-id"
                            type="number"
                            
                            required
                            value={this.state.codigo}
                            onChange={(e) =>
                              this.setState({ codigo: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                      
                      }
                      <Row>
                      
                        <Col md="8">
                        {this.state.id > 0 ? (
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Localidade
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-address"
                                  placeholder=""
                                  disabled
                                  type="text"
                                  value={this.state.bairro}
                                  onChange={(e) =>
                                    this.setState({
                                      bairro: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                                ): 
                                <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Localidade
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-address"
                                  placeholder=""
                                  
                                  type="text"
                                  value={this.state.bairro}
                                  onChange={(e) =>
                                    this.setState({
                                      bairro: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>}
                            </Col>
                           
                        {/* <Col lg="9">
                          <FormGroup>
                            {this.state.neighborhood.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Bairro *
                                </label>

                                <select
                                  value={this.state.cod_bairro}
                                  onChange={(e) => {
                                    this.handleChange(e.target.value);
                                  }}
                                  className="form-control "
                                >
                                  <option value="">Selecione...</option>

                                  {this.state.neighborhood.map((team) => (
                                    <option
                                      key={team.id}
                                      value={team.id}
                                      defaultValue={this.state.cod_bairro}
                                    >
                                      {team.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col> */}
                        <Col lg="3">
                        <FormGroup>
                        <label className="form-control-label" htmlFor="input-first-name">
                          Região
                        </label>
                        <select
                          className="form-control"
                          id="input-first-name"
                          required
                          value={this.state.cod_bairro}
                          onChange={(e) => this.setState({ cod_bairro: e.target.value })}
                        >
                          <option value="">Selecione...</option>
                          <option value="1">CENTRAL</option>
                          <option value="2">SUL</option>
                          <option value="3">NORTE</option>
                          <option value="4">LESTE</option>
                          <option value="5">OESTE</option>
                          <option value="6">PERIURBANO</option>
                          <option value="7">RURAL</option>
                        </select>
                      </FormGroup>
                        </Col>{" "}
                      </Row>

                      
                   
                   
                    </div>
                    {localStorage.getItem("perm") === "coordenador" && (
                      <div className="text-center">
                        <Row>
                          <Col className="order-xl-1" xl="12">
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={this.handleSave}
                            >
                              <b>
                                {this.state.isEdicao
                                  ? "Salvar Alterações"
                                  : "Cadastrar"}
                              </b>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
