import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import InputMask from "react-input-mask";

import Header from "components/Headers/Header.js";

import Api from "shared/resources/Api";
import { sectors, neighborhood } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";

export default class OvitrampLabList extends React.Component {
  /* Dados Grid */
  columns = [
    {
      name: "Cód Ovitrampa",
      maxWidth: "50px",
      selector: "codigo_ovitrampa",
      sortable: true,
    },
    {
      name: "Nome",
      selector: "nome",
      sortable: true,
    },
    {
      name: "Logradouro",
      selector: "logradouro",
      sortable: true,
    },
    {
      name: "Bairro",
      selector: "bairro",
      sortable: true,
    },
    {
      name: "Setor",
      sortable: true,
      selector: "Setor",
      cell: (row) => (
        <div>
          {row.setor !== undefined && row.setor !== null
            ? row.setor
            : "-"}
        </div>
      ),
    },
    {
      name: "Situação",
      selector: "situacao",
      sortable: true,
    },
    {
      name: "Qtde Aeg",
      selector: "ovos_aeg",
      sortable: true,
    },
    {
      name: "Qtde Alb",
      selector: "ovos_alb",
      sortable: true,
    },
    {
      name: "Qtde Larv",
      selector: "ovos_larva",
      sortable: true,
    },
    {
      name: "Qtde Outros",
      selector: "ovos_culex",
      sortable: true,
    },
    {
      name: "Semana",
      selector: "semana",
      sortable: true,
    },
    {
      name: "Ano",
      selector: "ano",
      sortable: true,
    },
    {
      name: "",
      center: true,
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              this.props.history.push("/admin/laboratorio-edit", {
                ovitramp: row,
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {localStorage.getItem("perm") === "coordenador" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      response: "",
      nome: "",
      bairro: "",
      logradouro: "",
      tel: "",
      api: Api.baseURL,
      id: "",
      setor: "",
      requesting: false,
      neighborhood: [],
      sectors: [],
      selectedSector: null,
      selectedNeighborhood: null,
      situacao: "",
      status: "ativo",
      tipo: "",
      codigo: null,
      ovosmax: null,
      ovosmin: null,
      ovos_aeg_min: "",
      ovos_aeg_max: "",
      ovos_alb_min: "",
      ovos_alb_max: "",
      ovos_larva_min: "",
      ovos_larva_max: "",
      ovos_culex_min: "",
      ovos_culex_max: "",
      data_fim: moment().format("DD/MM/YYYY"),
      data_inicio: moment().subtract(1, 'months').format("DD/MM/YYYY"),
    };
  }
  componentWillMount() {
    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    if (localStorage.getItem("gridovilab")) {

      var obj = JSON.parse(localStorage.getItem("gridovilab"));
      this.setState({
        response: obj,
      })
    }
    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });

    sectors().then((res) => {
      if (res) {
        this.setState({
          sectors: res,
        });
      }
    });
  }

  toNormalCase(str) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, function (match) {
      return match.toUpperCase();
    });
  }

  fetchSectors = async () => {
    const response = await fetch(Api.baseURL + "/setor", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };

  fetchNeighborhood = async () => {
    const response = await fetch(Api.baseURL + "/bairro", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        neighborhood: body,
      });
    }

    return body;
  };

  handleSubmit = (event) => {
  
    if (this.validate()) {
      this.setState({ response: "", requesting: true });
      this.fetchOvitramp()
        .then((res) => this.setState({ response: res }, () => {
          localStorage.setItem("gridovilab", JSON.stringify(res));
        }))
        .catch((err) => {
          console.log(err);
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text:
                "Não foram encontradas ovitrampas para os parâmetros informados.",
              type: "error",
            },
            requesting: false,
          });
        });
        event.preventDefault();
    }

  };

  fetchOvitramp = async () => {

    let host = this.state.api + "/controle_ovitrampa?";


    let params = {
      codigo_ovitrampa: this.state.codigo !== null ? this.state.codigo : "",
      situacao: this.state.situacao,
      data_inicio: this.dataIni !== undefined ? this.dataIni : "",
      data_fim: this.dataFim !== undefined ? this.dataFim : "",
      nome: this.state.nome,
      status: this.state.status,
      setor: this.state.selectedSector != null ? this.state.selectedSector : "",
      logradouro: this.state.logradouro.trim(),
      tel: this.state.tel.replace(/\D/g, ""),
      bairro: this.state.bairro,
      ovos_aeg_min: this.state.tipo === "aedes_aegypti" ? this.state.ovosmin : "",
      ovos_aeg_max: this.state.tipo === "aedes_aegypti" ? this.state.ovosmax : "",
      ovos_alb_min: this.state.tipo === "aedes_albobictus" ? this.state.ovosmin : "",
      ovos_alb_max: this.state.tipo === "aedes_albobictus" ? this.state.ovosmax : "",
      ovos_larva_min: this.state.tipo === "larvas" ? this.state.ovosmin : "",
      ovos_larva_max: this.state.tipo === "larvas" ? this.state.ovosmax : "",
      ovos_culex_min: this.state.tipo === "outros" ? this.state.ovosmin : "",
      ovos_culex_max: this.state.tipo === "outros" ? this.state.ovosmax : "",
    };
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        agent: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text:
            "Não foram encontradas ovitrampas para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };


   validate() {

 
    var dateFormat = "DD/MM/YYYY";

    if (
      this.state.data_inicio !== undefined &&
      this.state.data_inicio !== ""
    ) {
      if (
        !moment(this.state.data_inicio, dateFormat, true).isValid()
      ) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data inicial inválida",
            type: "error",
          },
        });
        return false;
      } else {
        this.dataIni = moment(
          moment(this.state.data_inicio, "DD/MM/YYYY")
        ).format("YYYY-MM-DD");
      }
    }

    if (
      this.state.data_fim !== undefined &&
      this.state.data_fim !== ""
    ) {
      if (!moment(this.state.data_fim, dateFormat, true).isValid()) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data final inválida",
            type: "error",
          },
        });
        return false;
      } else {
        this.dataFim = moment(
          moment(this.state.data_fim, "DD/MM/YYYY")
        ).format("YYYY-MM-DD");
      }
    }

    if (this.dataIni !== undefined && this.dataFim !== undefined) {
      var date1 = moment(this.state.data_inicio, "DD/MM/YYYY");
      var date2 = moment(this.state.data_fim, "DD/MM/YYYY");
      var diff = date2.diff(date1);

      if (diff < 0) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data de Início menor que Data Final.",
            type: "error",
          },
        });
        return false;
      }
    }

    if (this.state.data_inicio === "") {
      this.dataIni = undefined;
    }

    if (this.state.data_fim === "") {
      this.dataFim = undefined;
    }

    if(this.state.tipo !== "")
    {
       if(this.state.ovosmax != null && this.state.ovosmin != null)
       {

         var diffovos = this.state.ovosmax < this.state.ovosmin
    
         if(diffovos)
         {
           this.setState({
             swal: {
               show: true,
               title: "Atenção",
               text: "Qtde Máxima menor que Qtde Mínima",
               type: "error",
             },
           });
           return false;
         }
       }
 
      if(this.state.tipo === "aedes_aegypti")
      {
        this.setState({
          ovos_aeg_min: this.state.ovosmin,
          ovos_aeg_max: this.state.ovosmax,
          ovos_alb_min: "",
          ovos_alb_max: "",
          ovos_larva_min: "",
          ovos_larva_max: "",
          ovos_culex_min: "",
          ovos_culex_max: "",
        });
      }
 
      if(this.state.tipo === "aedes_albobictus")
      {
    
        this.setState({
          ovos_alb_min: this.state.ovosmin,
          ovos_alb_max: this.state.ovosmax,
          ovos_aeg_min: "",
          ovos_aeg_max: "",
          ovos_larva_min: "",
          ovos_larva_max: "",
          ovos_culex_min: "",
          ovos_culex_max: "",
        });
      }
 
      if(this.state.tipo === "larvas")
      {
        this.setState({
          ovos_larva_min: this.state.ovosmin,
          ovos_larva_max: this.state.ovosmax,
          ovos_aeg_min: "",
          ovos_aeg_max: "",
          ovos_alb_min: "",
          ovos_alb_max: "",
          ovos_culex_min: "",
          ovos_culex_max: "",
        });
      }
 
      if(this.state.tipo === "outros")
      {
        this.setState({
          ovos_culex_min: this.state.ovosmin,
          ovos_culex_max: this.state.ovosmax,
          ovos_aeg_min: "",
          ovos_aeg_max: "",
          ovos_alb_min: "",
          ovos_alb_max: "",
          ovos_larva_min: "",
          ovos_larva_max: ""
        });
      }
 
    }
 
    return true;
  }

  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "error",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      ovitramp: element,
      isConfirmation: true,
    });
  };
  handleDeleteOvitramp(d) {
    this.callDel(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDel = async (d) => {
    const response = await fetch(Api.baseURL + "/ovitrampa/" + d.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Ovitrampa deletada com sucesso!",
          type: "success",
        },
        response: "",
        nome: "",
        logradouro: "",
        tel: "",
        selectedNeighborhood: "",
        selectedSector: "",
        codigo: "",
        situacao: ""
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar ovitrampa.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteOvitramp(this.state.ovitramp);
            }
          }}
        />

        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Consulta Monitoramento Ovitrampas
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Código Ovitrampa
                            </label>

                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-id"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder=""
                              value={this.state.codigo}
                              onChange={(e) =>
                                this.setState({ codigo: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                  
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Tipo Ovos
                                </label>

                                <select
                                  value={this.state.tipo}
                                  onChange={(e) =>
                                    this.setState({
                                      tipo: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  <option value="aedes_aegypti">Aedes Aegypti</option>
                                  <option value="aedes_albobictus">Aedes Albobictus</option>
                                  <option value="larvas">Larvas/Pupas</option>
                                  <option value="outros">Outros</option>
                                </select>
                              </div>
                       
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Qtd Mínima Ovos
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-min"
                              type="number"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                )
                                    .toString()
                                    .slice(0, 7);
                            }}
                              required
                              value={this.state.ovosmin}
                              onChange={(e) =>
                                this.setState({ ovosmin: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-tel"
                            >
                              Qtd Máxima Ovos
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-max"
                              type="number"
                              required
                              onInput={(e) => {
                                e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                )
                                    .toString()
                                    .slice(0, 7);
                            }}
                              value={this.state.ovosmax}
                              onChange={(e) =>
                                this.setState({ ovosmax: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Data Inicial
                            </label>
                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              name="data_abertura_inicio"
                              inputMode="numeric"
                              id="data_abertura_inicio"
                              className="form-control"
                              value={this.state.data_inicio}
                              onChange={(e) =>
                                this.setState({
                                  data_inicio: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Data Final
                            </label>
                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              name="data_fim"
                              inputMode="numeric"
                              id="data_fim"
                              className="form-control"
                              value={this.state.data_fim}
                              onChange={(e) =>
                                this.setState({
                                  data_fim: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bairro"
                            >
                              Bairro
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-lado"
                              type="text"
                              maxLength="30"
                              value={this.state.bairro}
                              onChange={(e) =>
                                this.setState({ bairro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>


                        {" "}
                      </Row>
                      <Row>

                        <Col lg="4">
                          <FormGroup>

                            <div>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-dtnascimento"
                              >
                                Status
                                </label>

                              <select
                                value={this.state.status}
                                onChange={(e) =>
                                  this.setState({ status: e.target.value })
                                }

                                className="form-control "
                              >
                                <option defaultValue="ativo" value="ativo">
                                  {" "}
                                    Ativo{" "}
                                </option>
                                <option value="inativo">Inativo</option>
                              </select>
                            </div>

                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            {this.state.sectors.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Setor
                                </label>

                                <select
                                  value={this.state.selectedSector}
                                  onChange={(e) =>
                                    this.setState({
                                      selectedSector: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  {this.state.sectors.map((team) => (
                                    <option
                                      key={team.id}
                                      value={team.nome}
                                      defaultValue={this.state.selectedSector}
                                    >
                                      {team.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Situação
                            </label>

                            <select
                              value={this.state.situacao}
                              onChange={(e) =>
                                this.setState({ situacao: e.target.value })
                              }
                              className="form-control "
                              id="select-tipo"
                            >
                              <option defaultValue="" value="">
                                {" "}
                                Selecione...{" "}
                              </option>

                              <option value="amarelo">Amarelo</option>
                              <option value="verde">Verde</option>
                              <option value="vermelho">Vermelho</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {" "}
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              paginationComponentOptions={this.defaultComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={this.customStyles}
              progressPending={this.state.response === undefined}
              noDataComponent="Nenhum registro encontrado."
              paginationResetDefaultPage={this.state.response.length > 0}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
