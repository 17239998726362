import Api from 'shared/resources/Api';
const AvatarService = {
  avatar: '',
  async fetchUserImage() {
    let host = Api.baseURL + '/usuario/avatar';

    const response = await fetch(host, {
      headers: {
        Authorization: localStorage.getItem('agToken'),
      },
    });

    const body = await response.json();
    if (body && body.foto && body.foto.length > 0) {
      sessionStorage.setItem(
        'avatar',
        `data:image/jpeg;base64,${body.foto[0]}`
      );
      return `data:image/jpeg;base64,${body.foto[0]}`;
    }
    return null;
  },

  async getAvatar() {
    if (sessionStorage.getItem('avatar')) {
      this.avatar= sessionStorage.getItem('avatar');
    } else {
      let host = Api.baseURL + '/usuario/avatar';

      const response = await fetch(host, {
        headers: {
          Authorization: localStorage.getItem('agToken'),
        },
      });

      const body = await response.json();
    
      if (body && body.foto && body.foto.length > 0) {
        
        sessionStorage.setItem(
          'avatar',
          `data:image/jpeg;base64,${body.foto[0]}`
        );
        this.avatar= `data:image/jpeg;base64,${body.foto[0]}`;
      }
     
    }
  },
};
export default AvatarService;
