import React from "react";
import {  Container, Row, Col } from "reactstrap";
import Header from "components/Headers/Header.js";
import linhasOnibusHeader from "assets/img/linhas-onibus-header.png";
import linhasOnibus from "assets/img/linhas-onibus.png";

export default class BusList extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Container className="main-zoo" fluid>
          <Row className="align-items-center">
            <Col lg="12">
              <img
                width="600"
                heigth="600"
                className="img-fluid mx-auto d-block"
                src={linhasOnibusHeader}
                alt="Imagem Notícia"
              />
            </Col>
          </Row>
          <br />
          <br />
          <div className="header-body">
            <a href="https://moovit.com/?from=Local%20escolhido&fll=-18.881031_-48.278292&utm_medium=Organic&utm_source=company_website&metroId=3364&lang=pt-br">
              <img
                className="img-fluid mx-auto d-block"
                width="400"
                heigth="400"
                src={linhasOnibus}
                alt="Fale Conosco Telefone"
              />
            </a>
          </div>
        </Container>
      </>
    );
  }
}
