export const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cepMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{5})(\d)/, '$1-$2') 
}

export const telMask = value => {
  
  if (value && value.replace(/\D/g, '').length === 11)
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, '($1) $2 ')
      .replace(/(\d{4})(\d)/, '$1-$2')
      if (value && value.replace(/\D/g, '').length === 10)
      return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
}

export const cnpjMask = value => {
  if (!value) return '';

  return value
    .replace(/\D/g, '') // Remove any non-digit character
    .replace(/(\d{2})(\d)/, '$1.$2') // Add a dot after the first two digits
    .replace(/(\d{3})(\d)/, '$1.$2') // Add a dot after the next three digits
    .replace(/(\d{3})(\d)/, '$1/$2') // Add a slash after the next three digits
    .replace(/(\d{4})(\d{2})/, '$1-$2'); // Add a dash before the last two digits
};