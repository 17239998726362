import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert2 from "react-sweetalert2";

import Api from "shared/resources/Api";
import ImageUploader from "react-images-upload";

import Header from "components/Headers/Header.js";
import { neighborhood } from "shared/services/PlacesService";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import moment from "moment";
import ReactInputMask from 'react-input-mask';
import Util from 'shared/services/Util';

export default class EmpresaCad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bairro: "",
      cod_bairro: "",
      api: Api.baseURL,
      isEdicao: false,
      foto: "",
      data: "",
      digitalSketch: [],
      selectedDigitalSketch: "",
      formTitle: "CADASTRAR",
      file: null,
      requesting: false,
      neighborhood: [],
      photoIndex: 0,
      isOpen: false,
      isSuccess: false,
      showState: 0,
      NR_CNPJ: "",
      DS_RAZAO_FORNECEDOR_SERVICO: "",
      NR_TELEFONE: "",
      NR_CEP: "",
      NM_LOGRADOURO: "",
      NM_BAIRRO: "",
      NR_NUMERO: "",
      DS_COMPLEMENTO: "",
      DS_CIDADE: "Uberlãndia",
      DS_ESTADO: "MG",
      DS_PAIS: "Brasil",
      DS_ENDERECO :"",
      CD_CODIGO: "",
      DS_USER: "",
      DS_SECRET: "",
      DS_SECRET_CONFIRM: "",
      DS_USU_ALTER: "",
      DS_EMAIL: "",
      user: {},
    };

    this.handleSave = this.handleSave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentWillMount() {
    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.selectedDigitalSketch != null
    ) {
      // console.log(this.props.location.state.selectedDigitalSketch.media.foto1)
      this.setState(
        {
          selectedDigitalSketch: this.props.location.state
            .selectedDigitalSketch,
          digitalSketch: this.props.location.state.selectedDigitalSketch,
          loading: false,
          bairro: this.props.location.state.selectedDigitalSketch.ds_descricao_equipe,
          cod_bairro: this.props.location.state.selectedDigitalSketch
            .setor,
          id: this.props.location.state.selectedDigitalSketch.oid_equipe,
          tp: this.props.location.state.selectedDigitalSketch.tp_ativo,
          foto: "",
          codigo: "",
          isEdicao: true,
          formTitle: "EDITAR",
         
        },
        () => console.log("state logou end", this.state.status)
      );
    }
    
  }





  companyRegistration = async () => {


    // this.setState({
    //   DS_RAZAO_SOCIAL: '',
    //   NR_CNPJ: '',
    //   showState: 1,
      
    // });

    let cnpjl = this.state.NR_CNPJ.replace(/\D/g, '')

    if(cnpjl.length < 13){

      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe um CNPJ válido.",
          type: "warning",
        },
      
      });
      return
    }

    this.setState({ requesting: true });

    if (cnpjl.length > 13) {

      try {
        const response = await fetch(`https://publica.cnpj.ws/cnpj/${cnpjl}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data from API');
        }

        const data = await response.json();
        

        if (response.status === 200) {
          this.setState({ requesting: false });

          this.setState({
            DS_RAZAO_FORNECEDOR_SERVICO: data.razao_social || '',
            company: data,
            showState: 1,
            swal: {
              show: false,
              title: "",
              text: "",
              type: "",
            },
          });
        }
        else{

          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "CNPJ não identificado na Receita.",
              type: "warning",
            },
          
          });
          return

        }
      } catch (error) {
        this.setState({ requesting: false });
        this.setState({
          swal: {
            show: true,
            title: "Erro",
            text: "CNPJ não identificado na Receita.",
            type: "warning",
          },
        });
        // Handle error state or show a message to the user
      }

    }


  
  };

  companyData = () => {
    let phone = this.state.NR_TELEFONE.replace(/\D/g, '')

    if (phone.length < 9 ) {
      
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe um Nr. Telefone válido.",
          type: "warning",
        },
      
      });
      return
      }
    this.setState({ showState: 2 });
  };

  addressInformation = async () => {
    const cep = this.state.NR_CEP;

    if (this.state.NR_NUMERO.length === ""  ) {
      
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Nr.",
          type: "warning",
        },
      
      });
      return
      }

    if (this.state.NR_CEP.length < 5 ) {
      
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe um CEP válido.",
          type: "warning",
        },
      
      });
      return
      }
      if (this.state.NM_LOGRADOURO.length < 4 ) {
      
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Informe um Logradouro.",
            type: "warning",
          },
        
        });
        return
        }

        if (this.state.NM_BAIRRO.length < 2 ) {
      
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "Informe o Bairro.",
              type: "warning",
            },
          
          });
          return
          }

    if (cep.length < 5 ) {
      
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe um CEP válido.",
          type: "warning",
        },
      
      });
      return
      }
      this.setState({ showState: 3 });
  
  };

   checkCep = async (e) => {
    const cep = this.state.NR_CEP.replace(/\D/g, '');
    if (cep.length == 8) {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        if (!response.ok) {
          throw new Error('Failed to fetch data from API');
        }
        const data = await response.json();
        this.setState({
          NM_LOGRADOURO: data.logradouro || '',
          DS_COMPLEMENTO: data.complemento || '',
          NM_BAIRRO: data.bairro || ''
        
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error state or show a message to the user
      }
    }
  }

  personalInformation = () => {
    const email = this.state.user.DS_EMAIL;
    if (
      this.state.DS_EMAIL &&
      this.state.DS_EMAIL !== '' &&
      !Util.validateEmail(this.state.DS_EMAIL)
    ) {
      this.setState({
        swal: {
          show: true,
          title: 'Atenção',
          text: 'E-mail inválido',
          type: 'warning',
        },
      });

      return false;
    }

    if (this.state.DS_USER.length < 2 ) {
      
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o nome.",
          type: "warning",
        },
      
      });
      return
      }

      if (this.state.DS_SECRET.length < 2 ) {
      
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Informe a senha.",
            type: "warning",
          },
        
        });
        return
        }

        if (this.state.DS_SECRET != this.state.DS_SECRET_CONFIRM  ) {
      
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "Favor verificar as senhas digitadas.",
              type: "warning",
            },
          
          });
          return
          }

      
   
    this.setState({
      
      showState: 4
    });
  };

  // confirmSubmit = () => {
  //   Swal.fire("Success", "Your registration is complete!", "success");
  // };

  previousState = () => {
    this.setState((prevState) => ({
      showState: prevState.showState - 1,
    }));
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      [name]: value,
    }));
  };


  handleSave(event) {
    
      this.callSave();
    
  }


  createBody = () => {
   
      
      return JSON.stringify({
        ds_razao_fornecedor_servico: this.state.DS_RAZAO_FORNECEDOR_SERVICO,
        nr_cnpj: this.state.NR_CNPJ,
        nr_telefone: this.state.NR_TELEFONE.replace(/\D/g, ""),
        
        nr_cep: this.state.NR_CEP.replace(/\D/g, ''),
        nm_logradouro: this.state.NM_LOGRADOURO,
        nm_bairro: this.state.NM_BAIRRO,
        nr_numero: this.state.NR_NUMERO,
        ds_email: this.state.DS_EMAIL,
        ds_user: this.state.DS_USER,
        ds_secret: this.state.DS_SECRET,
        tp_ativo: 'A',
        
      });
    
  };

  callSave = async () => {
    var data = new FormData();
    data.append("fornecedores", this.createBody());

    var dataemail = JSON.stringify({
      email: this.state.DS_EMAIL,
      user: this.state.NR_CNPJ,
      secret: this.state.DS_SECRET
      
      
      
    });

    fetch(Api.baseURL + "/Fornecedores/email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
      body: dataemail
    })

    
    const response = await fetch(
      Api.baseURL +
      "/Fornecedores/" ,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: data,
      }
    );
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Cadastro realizado com sucesso!",
          type: "success",
        },
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: body.erro,
          type: "warning",
        },
      });
      console.log(body.erro);
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao cadastrar",
          type: "warning",
        },
      });
      console.log(body.erro);
    }

   

    return body;
  };











  validate = () => {



    if (this.state.bairro === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a equipe",
          type: "warning",
        },
      });

      return false;
    }

    
    return true;
  };

  handleDownload = (event) => {
    event.preventDefault();
    try {
      
    const url = `${this.props.location.state.selectedDigitalSketch.media.foto1.replace('http://', 'https://')}?${moment().format('YYYYMMDDHHMMSS')}`;
    fetch(url)
    .then(res => res.blob())
    .then(blob => {
    const link = document.createElement('a');
    link.download = this.state.bairro? this.state.bairro+'.jpeg':'myImage.jpeg';
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
       });
    } catch (e) {
      console.error("Invalid JSON string:", e);
    }
  };

 
  handleChange = (value) => {  
    if (value.length > 0) {
      this.setState({
        bairro: this.state.neighborhood.find((p) => p.id.toString() === value)
          .nome,
          cod_bairro: value,
      });
    } else {
      this.setState({
        bairro: "",
        cod_bairro: "",
      });
    }
  };
  callApi = async () => {
    var sketch = {
      
      nome: this.state.bairro,
      descricao: this.state.bairro,
      status: this.state.tp ? this.state.tp : ""
      
      
    };

    var sketchid = {

      
      descricao: this.state.bairro,
      status: this.state.tp ? this.state.tp : ""
    };

    var requestBody = {
      bairro: sketchid,
    };
    
   
    var data = new FormData();
    data.append("ronda", JSON.stringify(sketchid));

    var data2 = new FormData();
    data2.append("ronda", JSON.stringify(sketch));

  

    let response = "";
    if (this.state.id > 0) {
      response = await fetch(Api.baseURL + "/unidade_saude/equipe/" + this.state.id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: data,
      });
    } else {
      response = await fetch(Api.baseURL + "/unidade_saude/equipe/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: data2,
      });
    }

    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo.",
          text:
            this.state.id > 0
              ? "Edição realizada com sucesso!"
              : "Cadastro realizado com sucesso!",
          type: "success",
        },
        requesting: false,
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Já possui equipe com esse nome ",
          type: "error",
        },
      });
      this.setState({ requesting: false });
    } else {
      throw Error(body.message);
    }

    return body;
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState(
      {
        foto: pictureDataURLs[pictureDataURLs.length - 1],
        pictures: [],
        file: pictureFiles[pictureFiles.length - 1],
      },
      () => console.log(this.state.feed)
    );
  }

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/empresalist");
            }
          }}
        />
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.state.foto}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {this.state.formTitle} EMPRESA
                    </h6>
                    <div className="pl-lg-4">
                  

                    {this.state.showState === 0 ? (
                  <div className="page-title-box">
                    <Row className="align-items-center">
                    
                        <ol className="breadcrumb m-0">
                      
                          <p>
                            Você iniciará agora o cadastro destinado a Pessoas Jurídicas(PJ). Não são realizados cadastros de pessoas físicas.
                          </p>
                          {/* <p>
                            Após o preenchimento de todas as informações, o sistema enviará um e-mail automático de confirmação dos dados preenchidos. <br />
                            Logo que o cadastro for confirmado pelo usuário, a equipe do DMAE fará a análise das informações e se não houver qualquer divergência, seu acesso será liberado.
                          </p> */}
                        </ol>
                      
                    </Row>
                  </div>
                ) : null}

                {this.state.showState == 0 ?
                <div>
                
            <Row>
              <Col lg={12}>
                <div className="page-title-box">
                  <Row className="mb-3">
                    {/* <h6 className="page-title">Cadastro da Empresa</h6> */}

                    <div className="col-md-6 ">
                      <label
                        className="col-md-12col-form-label"
                      >CNPJ*
                      </label>
                      <ReactInputMask
                        value={this.state.NR_CNPJ}
                        onChange={(e) =>
                          this.setState({
                            NR_CNPJ: e.target.value,
                          })
                        }
                        mask="99.999.999/9999-99"
                        className="form-control"
                        type="text"
                        defaultValue=""
                      />
                    </div>
                    {/* <Row className="mb-3">
                      <div className="col-md-6 mt-2 ">
                        <Button
                          color="info"
                          className="btn-lg btn-primary"
                          type="button"
                          value='1'
                          onClick={this.companyRegistration} 
                        >
                          Continuar
                        </Button>
                      </div>
                    </Row> */}

                  </Row>
                </div>
              </Col>
            </Row>


        <div className="text-center">
        {/* {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )} */}
          {!this.state.requesting && (
        <Row>
          <Col className="order-xl-1" xl="12">
             
                <Button
                className="mt-4 btn-zoo"
                color="primary"
                type="button"
                onClick={this.companyRegistration} 
              >
                <b>Continuar</b>
              </Button>
           
            </Col>
          </Row>
            )}
          </div>
          </div>
          
            : null}


            {this.state.showState === 1 && (
          <Row>
            <Col lg={12}>
              <div className="page-title-box">
                <Row className="mb-3">
                  {/* <h6 className="page-title">Dados da Empresa</h6> */}
                  <div className="col-md-6">
                    <label className="col-md-12 col-form-label">CNPJ</label>
                    <input
                      name="CNPJ"
                      className="form-control "
                      value={this.state.NR_CNPJ}
                      type="text"
                      disabled
                    />
                    <label className="col-md-12 col-form-label">Razão Social</label>
                    <input
                      name="DS_RAZAO_SOCIAL"
                      value={this.state.DS_RAZAO_FORNECEDOR_SERVICO}
                      className="form-control "
                      type="text"
                      disabled
                    />
                    <label className="col-md-12 col-form-label">Telefone*</label>
                    <ReactInputMask
                    value={this.state.NR_TELEFONE}
                    onChange={(e) =>
                      this.setState({
                        NR_TELEFONE: e.target.value,
                      })
                    }
                      name="DS_TELEFONE"
                      mask="(99)99999-9999"
                      className="form-control "
                      type="text"
                      
                    />
                    <Row className="mb-3">
                      <div className="col-md-6 mt-2">
                        <Button onClick={this.previousState}><i className="ti-angle-left"></i> Voltar </Button>
                        <Button
                          className=" btn-zoo"
                          color="primary"
                          type="button"
                          onClick={this.companyData}
                        >
                          Continuar
                        </Button>
                      </div>
                    </Row>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        )}

         {this.state.showState === 2 && (
          <Row>
          <Col lg={12}>
            <div className="page-title-box">
              <Row className="mb-3">
                {/* <h6 className="page-title">Informações de endereço</h6> */}

                <div className="col-md-6">
                  <label
                    className="col-md-12col-form-label"
                  >CEP*
                  </label>
                  <ReactInputMask
                    onBlur={this.checkCep}
                    value={this.state.NR_CEP}
                    onChange={(e) =>
                          this.setState({
                            NR_CEP: e.target.value,
                          })
                        }
                    mask="99999-999"
                    className="form-control "
                    type="text"
                    defaultValue=""
                  />
                  <label
                    className="col-md-12col-form-label"
                  >Logradouro*
                  </label>
                  <input
                  value={this.state.NM_LOGRADOURO}
                  onChange={(e) =>
                        this.setState({
                          NM_LOGRADOURO: e.target.value,
                        })
                      }
                   
                    className="form-control "
                    type="text"
                    defaultValue=""
                  />
                  <Row>
                    <Col lg={6}>
                      <label
                        className="col-md-12col-form-label"
                      >Bairro*
                      </label>
                      <input
                      value={this.state.NM_BAIRRO}
                      onChange={(e) =>
                            this.setState({
                              NM_BAIRRO: e.target.value,
                            })
                          }
                      
                        
                        className="form-control  w-100"
                        type="text"
                        defaultValue=""
                      />
                    </Col>
                    <Col lg={6}>
                      <label
                        className="col-md-12col-form-label"
                      >Número*
                      </label>
                      <input
                      value={this.state.NR_NUMERO}
                      onChange={(e) =>
                            this.setState({
                              NR_NUMERO: e.target.value,
                            })
                          }
                       
                        className="form-control  w-100"
                        type="text"
                        defaultValue=""
                      />
                    </Col>
                  </Row>
                  <label
                    className="col-md-12col-form-label"
                  >Complemento
                  </label>
                  <input
                  value={this.state.DS_COMPLEMENTO}
                  onChange={(e) =>
                        this.setState({
                          DS_COMPLEMENTO: e.target.value,
                        })
                      }
                   
                    className="form-control "
                    type="text"
                    defaultValue=""
                  />
                  <label
                    className="col-md-12col-form-label"
                  >Cidade*
                  </label>
                  <input
                  value={this.state.DS_CIDADE}
                    
                    className="form-control "
                    type="text"
                    defaultValue=""
                    disabled
                  />
                  <label
                    className="col-md-12col-form-label"
                  >Estado*
                  </label>
                  <input
                    value={this.state.DS_ESTADO}
                    className="form-control "
                    type="text"
                    defaultValue=""
                    disabled
                  />
                  <label
                    className="col-md-12col-form-label"
                  >País*
                  </label>
                  <input
                    value={this.state.DS_PAIS}
                    className="form-control "
                    type="text"
                    defaultValue="Brasil"
                    disabled
                  />


                </div>
                <Row className="col-md-12 mb-3">

                  <div className="col-md-6 mt-2">
                  <Button onClick={this.previousState}><i className="ti-angle-left"></i> Voltar </Button>
                    <Button
                      className=" btn-zoo"
                      color="primary"
                      type="button"
                      onClick={this.addressInformation}
                    >
                      Continuar
                    </Button>
                  </div>
                </Row>

              </Row>
            </div>
          </Col>
        </Row>
        )}

         {this.state.showState == 3 ?
            <Row>
              <Col lg={12}>
                <div className="page-title-box">
                  <Row className="mb-3" >
                    {/* <h6 className="page-title">Informações Pessoais</h6> */}
                    <div className="col-md-12 row">


                      <div className="col-md-6">

                        <label
                          className="col-md-12col-form-label"
                        >Nome*
                        </label>
                        <input
                        value={this.state.DS_USER}
                        onChange={(e) =>
                              this.setState({
                                DS_USER: e.target.value,
                              })
                            }
                        
                          className="form-control "
                          type="text"
                          defaultValue=""
                        />
                     
                        <label
                          className="col-md-12col-form-label"
                        >Email*
                        </label>
                        
                        <input
                          value={this.state.DS_EMAIL}
                          onChange={(e) =>
                                this.setState({
                                  DS_EMAIL: e.target.value,
                                })
                              }
                          
                          className="form-control  "
                          type="email"
                          placeholder="exemplo@email.com"
                          defaultValue=""
                        />
                        <p
                          // {...register("validemail")}
                        />
                        <Row>
                          <Col lg={6}>
                            <label
                              className="col-md-12 col-form-label"
                            >Senha*
                            </label>
                            <input
                             value={this.state.DS_SECRET}
                             onChange={(e) =>
                                   this.setState({
                                    DS_SECRET: e.target.value,
                                   })
                                 }
                             
                              className="form-control  "
                              type="password"
                              defaultValue=""
                              maxLength={10}
                            />
                          </Col>
                          <Col lg={6}>
                            <label
                              className="col-md-12 col-form-label"
                            >Confirmar Senha*
                            </label>
                            <input
                              value={this.state.DS_SECRET_CONFIRM}
                              onChange={(e) =>
                                    this.setState({
                                      DS_SECRET_CONFIRM: e.target.value,
                                    })
                                  }
                              className="form-control  "
                              type="password"
                              defaultValue=""
                              maxLength={10}
                            />



                          </Col>
                        </Row>
                        {/* <p><input
                          {...register("confirmaCheck")}
                          type="checkbox"
                        /> Aceito receber conteúdos e promoções por email.</p> */}


                      </div>
                      <Row className="col-md-12 mb-3">
                        <div className="col-md-12 mt-2">
                        <Button onClick={this.previousState}><i className="ti-angle-left"></i> Voltar </Button>
                          <Button
                            className="btn-zoo"
                            color="primary"
                            type="button"
                            onClick={this.personalInformation}
                          >
                            Continuar
                          </Button>
                        </div>
                      </Row>

                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
            : null}
            
              {this.state.showState == 4 ?

            <Row>
              <Col lg={12}>
                <div className="page-title-box">
                  <Row className="mb-3" >
                    {/* <h6 className="page-title">Confirmação de Cadastro</h6> */}
                   
                    <p>
                      
                      Pré-cadastro foi realizado com sucesso.{" "} <br></br>
                      
                    </p>
                    <br></br>
                    <p>
                      Para sua segurança, pedimos que acesse o botão abaixo e confirme o cadastro.
                      <br></br>
                      </p>
                      <p>
                      Logo após, Será disparado um e-mail para {this.state.DS_EMAIL} com os dados de acesso.
                    </p>


                    <div className="col-md-12 mt-2">
                    <Button onClick={this.previousState}><i className="ti-angle-left"></i> Voltar </Button>
                      <Button
                        className="btn-zoo"
                        color="primary"
                        type="button"
                        onClick={this.handleSave}
                      >
                        Confirmar
                      </Button>
                    </div>

                  </Row>
                </div>
              </Col>
            </Row>
            : null}
                      {/* {localStorage.getItem("perm") === "coordenador" && (
                      <div className="text-center">
                        <Row>
                          <Col className="order-xl-1" xl="12">
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={this.handleSave}
                            >
                              <b>
                                {this.state.isEdicao
                                  ? "Salvar Alterações"
                                  : "Cadastrar"}
                              </b>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )} */}

                      
                   
                   
                    </div>
                 
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
