import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import { viaCep } from "shared/services/ViaCep";
import Api from "shared/resources/Api";
import Header from "components/Headers/Header.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { healthUnits } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";
import gconf from "shared/resources/Gconf";
import DataTable from "react-data-table-component";

export default class PoolEdit extends React.Component {
  columns = [
    {
      name: "Endereço",
      selector: "endereco",
      sortable: true,
    },
    {
      name: "Bairro",
      selector: "bairro",
      sortable: true,
      cell: (row) => <div>{row.bairro ? row.bairro : "-"}</div>,
    },
    {
      name: "Ação",
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Escolher"
            onClick={() => {
              this.handleSelectedAddress(row)
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
            Escolher
          </Button>
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    // cells: {
    //   style: {
    //     backgroundColor: "#F7FAFC"
    //   },
    // },
    table: {
      style: {
        backgroundColor: "#F7FAFC",
      },
    },
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
        backgroundColor: "#F7FAFC"
      },
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      cep: "",
      nome_paciente: "",
      responseAddress: "",
      selectedAddress: "",
      tel: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      api: Api.baseURL,
      isEdicao: false,
      formTitle: "CADASTRAR",
      neighborhood: [],
      sectors: [],
      healthUnits: [],
      selectedUnit: null,
      selectedSector: null,
      lado: null,
      file: null,
      quarteirao: null,
      pictures: [],
      selectedFile: "",
      video: "",
      descricao: "",
      showMedia: false,
      tratamento: "",
      volume: "",
      foto1: "",
    };

    this.handleSave = this.handleSave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  fileSelectedHandler = (e) => {
    this.setState({ files: [...this.state.files, ...e.target.files] })
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      foto1: pictureDataURLs[pictureDataURLs.length - 1],
      pictures: [],
      file: pictureFiles[pictureFiles.length - 1],
    });
  }

  handleImage = (imageList, addUpdateIndex) => {
    console.log('carai');
    this.setState({
      images: imageList,
    });
  };

  handleSelectedAddress = (row) => {
    console.log(row, 'escolheu')
    this.setState({ selectedAddress: row, logradouro: row.endereco, bairro: row.bairro });
  };


  handleSiat = (event) => {

    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    } else {
      if (this.state.selectedAddress === "") {
        this.setState({ requesting: true });

        this.fetchSiat()
          .then((res) => this.setState({ responseAddress: res }))
          .catch((err) => {
            console.log(err);
            this.setState({
              swal: {
                show: true,
                title: "Atenção",
                text:
                  "Não foram encontrados endereços para os parâmetros informados.",
                type: "error",
              },
              requesting: false,
            });
          });
      } else {
        this.setState({ selectedAddress: "", logradouro: "", bairro: "", responseAddress: "" });
      }
    }




    event.preventDefault();
  };

  fetchSiat = async () => {
    let host = this.state.api + "/siat_enderecos?";

    let params = {
      endereco: this.state.logradouro,
      bairro: this.state.bairro ? this.state.bairro : ""
    };

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text:
            "Não foram encontrados endereços para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };


  componentWillMount() {
    healthUnits().then((res) => {
      if (res) {
        this.setState({
          healthUnits: res,
        });
      }
    });

    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.ubv != null
    ) {
      this.setState(
        {
          ubv: this.props.location.state.ubv,
          loading: false,
          nome_paciente: this.props.location.state.ubv.nome_paciente,
          lado: this.props.location.state.ubv.lado,
          status: this.props.location.state.ubv.status,
          quarteirao: this.props.location.state.ubv.quarteirao,
          selectedUnit: this.props.location.state.ubv.unidade_saude,
          logradouro: this.props.location.state.ubv.endereco.logradouro.trim(),
          numero: this.props.location.state.ubv.endereco.numero,
          complemento: this.props.location.state.ubv.endereco.complemento,
          bairro: this.props.location.state.ubv.bairro,
          descricao: this.props.location.state.ubv.descricao,
          isEdicao: true,
          formTitle: "EDITAR",
        },
        () => console.log("state logou end")
      );
    }
  }

  componentDidMount() { }

  consultarCep = () => {
    if (this.state.cep && this.state.cep.replace(/\D/g, "").length === 8) {
      viaCep(this.state.cep)
        .then((res) => {
          if (res && !res.erro) {
            const idx = this.state.neighborhood.findIndex(
              (p) => p.nome === res.bairro
            );
            this.setState({
              ...this.state,
              bairro: res.bairro,
              complemento: res.complemento,
              logradouro: res.logradouro.trim(),
              selectedNeighborhood:
                idx > -1
                  ? this.state.neighborhood[idx].id
                  : this.state.selectedNeighborhood,
            });
            this.consultarLatLong();
          } else {
            this.setState({
              swal: {
                show: true,
                title: "Atenção",
                text: "Nenhum Endereço localizado.",
                type: "warning",
              },
            });
          }
        })
        .catch((err) => {
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "Nenhum Endereço localizado.",
              type: "warning",
            },
          });
          console.log(err);
        });
    }
  };
  handleSave(event) {
    if (this.validateFields()) {
      this.setState({ requestingUbv: true });
      this.callSave();
    }
  }

  blurEndereco = () => {
    this.consultarLatLong();
  };

  consultarLatLong = () => {
    this.setState({ isMapOk: false });
    const addres = `${this.state.logradouro}, ${this.state.numero}, ${this.state.bairro}, ${this.state.cep}`;
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${addres}&key=${gconf.key}`
    )
      .then((response) => response.json())
      .then((res) => {
        if (res && res.status === "OK") {
          console.log(res);
          if (
            res.results.length > 0 &&
            res.results[0].geometry &&
            res.results[0].geometry.location
          ) {
            this.setState({
              ...this.state,
              lat: res.results[0].geometry.location.lat,
              lon: res.results[0].geometry.location.lng,
              isMapOk: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          lat: -18.90971,
          lon: -48.261921,
          isMapOk: true,
        });
      });
  };

  validateFields() {
    /* Validação obrigatoriedade */
    // if (this.state.nome_paciente === undefined || this.state.nome_paciente.length === 0) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe o Nome do Paciente",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    // if (
    //   this.state.selectedUnit === undefined ||
    //   this.state.selectedUnit === ""
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe a Unidade de Saúde.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }

    if (
      this.state.status === undefined ||
      this.state.status === ""
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Status.",
          type: "warning",
        },
      });

      return false;
    }


    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    }
    if (this.state.bairro === undefined || this.state.bairro.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro.",
          type: "warning",
        },
      });

      return false;
    }
    if (this.state.numero === undefined || this.state.numero.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Número.",
          type: "warning",
        },
      });

      return false;
    }


    return true;
  }


  callSave = async () => {

    var ubv = JSON.stringify(
      {
        nome_paciente:
          this.state.nome_paciente !== "" ? this.state.nome_paciente : "",
        cod_unidade_saude: this.state.selectedUnit !== null && this.state.selectedUnit !== undefined ? this.state.selectedUnit : "",
        descricao: this.state.descricao,
        status: this.state.status,
        bairro:
          this.state.bairro !== ""
            ? this.state.bairro
            : "",
        lado: this.state.lado,
        logradouro: this.state.logradouro.trim(),
        quarteirao: this.state.quarteirao !== null && this.state.quarteirao !== undefined ? this.state.quarteirao : 0,
        numero: this.state.numero,
        complemento: this.state.complemento
      })

    const response = await fetch(
      Api.baseURL +
      "/ubv/" +
      (this.state.ubv ? this.state.ubv.id : ""),
      {
        method: this.state.ubv ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: ubv,
      }
    );
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: this.state.ubv
            ? "Edição realizada com sucesso!"
            : "Cadastro realizado com sucesso!",
          type: "success",
        },
        isSuccess: true,
        requestingUbv: false
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.ubv
            ? "Erro ao editar ubv. "
            : "Erro ao cadastrar ubv.",
          type: "warning",
          requestingUbv: false
        },
      });
      console.log(body.erro);
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.ubv
            ? "Erro ao editar ubv. "
            : "Erro ao cadastrar ubv.",
          type: "warning",
        },
      });
      console.log(body.erro);
    }

    return body;
  };
  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/ubv");
            }
          }}
        />
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  {!this.state.requestingUbv && (
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        {this.state.formTitle} UBV
                    </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Nome do Paciente 
                            </label>

                              <Input
                                className="form-control-alternative"
                                id="input-nome"
                                type="text"
                                required
                                value={this.state.nome_paciente}
                                onChange={(e) =>
                                  this.setState({ nome_paciente: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="3">
                            <FormGroup>
                              {this.state.healthUnits.length > 0 && (
                                <div>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-last-dtnascimento"
                                  >
                                    Unidade de Saúde 
                                </label>

                                  <select
                                    value={this.state.selectedUnit}
                                    onChange={(e) =>
                                      this.setState({
                                        selectedUnit: e.target.value,
                                      })
                                    }
                                    className="form-control "
                                  >
                                    <option defaultValue="" value="">
                                      {" "}
                                    Selecione...{" "}
                                    </option>
                                    {this.state.healthUnits.map((team) => (
                                      <option
                                        key={team.cod}
                                        value={team.cod}
                                        defaultValue={this.state.selectedUnit}
                                      >
                                        {team.nome}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Status *
                            </label>

                              <select
                                value={this.state.status}
                                onChange={(e) =>
                                  this.setState({ status: e.target.value })
                                }
                                className="form-control "
                                id="select-tipo"
                              >
                                <option defaultValue="" value="">
                                  {" "}
                                Selecione...{" "}
                                </option>

                                <option value="ACIONAR_BLOQ">Acionar o Bloqueio</option>
                                <option value="BLOQ_FOCAL">Bloqueio Focal</option>
                                <option value="BLOQ_FOCAL_TERMO">Bloqueio Focal e Termonebulizador</option>
                                <option value="BLOQ_FOCAL_UBV_LEVE">Bloqueio Focal e UBV-Leve</option>
                                <option value="BLOQ_FOCAL_UBV_PESADO">Bloqueio Focal e UBV-Pesado</option>
                                <option value="BLOQ_TERMO">Bloqueio Termonebulizador</option>
                                <option value="BLOQ_UBV_LEVE">Bloqueio UBV-Leve</option>
                                <option value="BLOQ_UBV_LEVE_PESADO">Bloqueio UBV-Leve e Pesado</option>
                                <option value="BLOQ_UBV_LEVE_TERMO">Bloqueio UBV-Leve e Termonebulizador</option>
                                <option value="BLOQ_UBV_PESADO">Bloqueio UBV-Pesado</option>
                                <option value="CLIMA_INAD_BLOQ">Clima Inadequado para o Bloqueio</option>
                                <option value="END_INCOMPATIVEL">Endereço incompatível</option>
                                <option value="INVESTIGAR_END">Investigar o Endereço</option>
                                <option value="MORADOR_AUSENTE">Morador Ausente</option>
                                <option value="NOVO_DIAGNOSTICO">Novo Diagnóstico</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>


                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Logradouro *
                                </label>
                              <Input
                                className="form-control-alternative"
                                id="input-address"
                                disabled={this.state.selectedAddress !== ""}
                                placeholder=""
                                type="text"
                                value={this.state.logradouro}
                                onChange={(e) =>
                                  this.setState({
                                    logradouro: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-bairro"
                              >
                                Bairro *
                            </label>

                              <Input
                                className="form-control-alternative"
                                id="input-lado"
                                type="text"
                                maxLength="40"
                                disabled={this.state.selectedAddress !== ""}
                                value={this.state.bairro}
                                onChange={(e) =>
                                  this.setState({ bairro: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                              >
                                Número *
                                </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder=""
                                type="text"
                                value={this.state.numero}
                                onChange={(e) =>
                                  this.setState({ numero: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>


                        </Row>

                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-complement"
                              >
                                Complemento
                                </label>
                              <Input
                                className="form-control-alternative"
                                id="input-complement"
                                placeholder=""
                                type="text"
                                maxLength={20}
                                value={this.state.complemento}
                                onChange={(e) =>
                                  this.setState({
                                    complemento: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-comp"
                              >
                                Quarteirão
                            </label>

                              <InputNumber
                                onlydigits
                                className="form-control"
                                id="input-quarteirao"
                                inputMode="numeric"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 4);
                                }}
                                placeholder=""
                                value={this.state.quarteirao}
                                onChange={(e) =>
                                  this.setState({ quarteirao: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-comp"
                              >
                                Lado
                            </label>

                              <Input
                                className="form-control-alternative"
                                id="input-comp"
                                type="text"
                                required
                                maxLength={4}
                                value={this.state.lado}
                                onChange={(e) =>
                                  this.setState({ lado: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <div className="text-left mt-4 pt-2">
                                <Button
                                  className={this.state.selectedAddress === "" ? "btn-zoo-alt" : "btn-zoo"}
                                  color="primary"
                                  type="button"
                                  onClick={this.handleSiat}
                                >
                                  {this.state.selectedAddress === "" ? "Consultar Endereço" : "Alterar Endereço"}

                                </Button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        {this.state.responseAddress && !this.state.requesting && this.state.selectedAddress === "" && (
                          <DataTable
                            noHeader={true}
                            pagination={true}
                            striped={true}
                            columns={this.columns}
                            sortable={true}
                            responsive={true}
                            highlightOnHover={true}
                            data={this.state.responseAddress}
                            noDataComponent="Nenhum registro encontrado."
                            paginationComponentOptions={this.defaultComponentOptions}
                            customStyles={this.customStyles}
                          />
                        )}

                        {this.state.requesting && (
                          <div className="text-center">
                            <Spinner
                              style={{ width: "3rem", height: "3rem" }}
                              color="primary"
                            />

                          </div>

                        )}



                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-dtnascimento"
                              >
                                Descrição
                                </label>
                              <Input
                                id="input-conteudo"
                                maxLength={1000}
                                value={this.state.descricao}

                                onChange={(e) =>
                                  this.setState({
                                    descricao: e.target.value,
                                  })
                                }
                                rows="6"
                                type="textarea"
                                className="form-control-alternative"
                              />
                            </FormGroup>
                          </Col>
                        </Row>


                      </div>
                      {localStorage.getItem("perm") !== "agente" && (
                        <div className="text-center">
                          <Row>
                            <Col className="order-xl-1" xl="12">
                              <Button
                                className="mt-4 btn-zoo"
                                color="primary"
                                type="button"
                                onClick={this.handleSave}
                              >
                                <b>
                                  {this.state.isEdicao
                                    ? "Salvar Alterações"
                                    : "Cadastrar"}
                                </b>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Form>
                  )}
                  {this.state.requestingUbv && (
                    <div className="text-center">
                      <Spinner
                        style={{ width: "3rem", height: "3rem" }}
                        color="primary"
                      />

                    </div>

                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
