import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";

import Header from "components/Headers/Header.js";
import Api from "shared/resources/Api";
import { sectors, neighborhood } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";
import Util from "shared/services/Util";
import moment from "moment";

import ReactExport from "react-data-export";
import ExcelFile from "../../shared/ExcelPlugin/components/ExcelFile";

// const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class CadastroList extends React.Component {
  /* Dados Grid */

  columns2 = [
    {
      name: "O.S",
      selector: "oid_ordem_servico",
      sortable: true,
      cell: (row) => (
        <div>{row.oid_ordem_servico ? row.oid_ordem_servico : "-"} </div>
      ),
      style: {
        fontSize: "12px", // Adjust the font size to your preference
      },
    },
    {
      name: "Origem",
      selector: "tp_origem",
      sortable: true,
      cell: (row) => <div>{row.tp_origem ? row.tp_origem : "-"} </div>,
      style: {
        fontSize: "12px", // Adjust the font size to your preference
      },
    },

    {
      name: "Endereço",
      selector: "endereco",
      sortable: true,
      cell: (row) => <div>{row.nm_logradouro ? row.nm_logradouro : "-"}</div>,
      wrap: true,
      style: {
        fontSize: "12px", // Adjust the font size to your preference
      },
    },
    {
      name: "Numero",
      selector: "numero",
      sortable: true,
      cell: (row) => <div>{row.nr_numero ? row.nr_numero : "-"}</div>,
    },

    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => <div>{Util.capitalize(row.tp_status)}</div>,
      style: {
        fontSize: "11px", // Adjust the font size to your preference
      },
    },
    {
      name: "Data Abertura",
      selector: "data_abertura",
      sortable: true,
      cell: (row) => (
        <div>{moment(moment(row.dt_abertura)).format("DD/MM/YYYY")}</div>
      ),
    },

    {
      name: "Tipo",
      selector: "tipo",
      sortable: true,
      cell: (row) => (
        <div>
          {row.tp_ordem_servico === "solicitacao"
            ? Util.snakeToPascal(row.tp_ordem_servico)
            : Util.capitalize(row.tp_ordem_servico)}
        </div>
      ),
      style: {
        fontSize: "11px", // Adjust the font size to your preference
      },
    },

    {
      name: "Agente",
      selector: "agente",
      sortable: true,
      cell: (row) => <div>{row.agente ? row.agente : "N/A"}</div>,
      style: {
        fontSize: "12px", // Adjust the font size to your preference
      },
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Redirecionar"
            onClick={() => {
              let params = {
                data_abertura_inicio: "",
                data_abertura_fim: "",
                agendamento_inicio: "",
                agendamento_fim: "",
                id: row.oid_ordem_servico,
                tipo_de_imovel: "",
                local: "",
                tel: "",
                lado: "",
                quarteirao: "",
                cpf: "",
                agente: "",
                status: "",
                origem: "",
                equipe: "",
                logradouro: "",
                bairro: "",
              };

              localStorage.setItem("params", JSON.stringify(params));
              localStorage.setItem("show", "false");

              this.props.history.push("/admin/ticket");
            }}
          >
            <FontAwesomeIcon icon={faLocationArrow} className="edit" />
          </Button>
          {/* {localStorage.getItem("perm") !== "agente" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )} */}
        </div>
      ),
    },
  ];

  columns = [
    {
      name: "Código",
      maxWidth: "10px",
      selector: "codigo",
      sortable: true,
    },
    {
      name: "Tipo de Cadastro",
      minWidth: "150px",
      selector: "source",
      sortable: true,
    },

    {
      name: "Nome",
      selector: "nome",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Logradouro",
      selector: "logradouro",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Número",
      selector: "numero",
      cell: (row) => (
        <div>
          {row.numero !== undefined && row.numero !== null ? row.numero : "-"}
        </div>
      ),
    },
    {
      name: "Quarteirão",
      selector: "quarteirao",
      cell: (row) => (
        <div>
          {row.quarteirao !== undefined &&
          row.quarteirao !== null &&
          row.quarteirao !== 0
            ? row.quarteirao
            : "-"}
        </div>
      ),
    },
    {
      name: "Lado",
      selector: "lado",
      cell: (row) => (
        <div>
          {row.lado !== undefined && row.lado !== null ? row.lado : "-"}
        </div>
      ),
    },

    {
      name: "Localidade",
      selector: "Bairro",
      sortable: true,
      cell: (row) => (
        <div>
          {row.bairro !== undefined && row.bairro !== null ? row.bairro : "-"}
        </div>
      ),
    },
    {
      name: "Região",
      selector: "Setor",
      maxWidth: "10px",
      sortable: true,
      cell: (row) => (
        <div>
          {row.setor !== undefined && row.setor !== null ? row.setor : "-"}
        </div>
      ),
    },
    {
      name: "Tratamento Biológico",
      selector: "tratamento",
      sortable: true,
      cell: (row) => 
        <div>
          {row.tratamento !== undefined ? ( // Check if row.tratamento is defined
            row.tratamento ? ( // Check if row.tratamento is truthy (not 0)
              row.tratamento === 1 ? "A definir" :
              row.tratamento === 2 ? "Povoado" :
              row.tratamento === 3 ? "Monitorado" :
              row.tratamento === 4 ? "Clorado" :
              "nenhum" // Default case if row.tratamento is defined but not 1, 2, 3, or 4
            ) : "Nenhum" // Falsy case when row.tratamento is not defined
          ) : "Nenhum" // Default case if row.tratamento is undefined
        }
      </div>,
    
      wrap: true,
    },

    {
      name: "Volume",
      selector: "volume",
      maxWidth: "10px",
      sortable: true,
      // cell: (row) => (
      //   <div>
      //     {row.volume !== undefined && row.volume !== null ? row.volume : row.volume}
      //   </div>
      // ),
    },
    // {
    //   name: "Tipo de Solicitação",
    //   selector: "tipo",
    //   sortable: true,
    //   cell: (row) => (
    //     <div>
    //       {row.tipoorigem === "solicitacao"
    //         ? Util.snakeToPascal(row.tipo_de_imovel)
    //         : Util.capitalize(row.tipoorigem)}
    //     </div>
    //   ),
    // },
    {
      name: "",
      center: true,
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              let redirectPath = "";

              switch (row.source) {
                case "Reservatórios":
                  redirectPath = "pool-cad";
                  break;
                case "Ecopneumáticos":
                  redirectPath = "tireshop-cad";
                  break;
                case "Grandes Áreas":
                  redirectPath = "registred-property-cad";
                  break;
                case "Imóveis em Abandono":
                  redirectPath = "abandoned-property-cad";
                  break;

                case "Pontos Estrátegicos":
                  redirectPath = "strategicpoint-cad";
                  break;

                case "Controles Biológicos":
                  redirectPath = "biocontrol-cad";
                  break;

                case "Rondas Escolares":
                  redirectPath = "ronda-cad";
                  break;
                // Add more cases for other values of row.source as needed

                default:
                  // Handle the default case if row.source doesn't match any of the cases above
                  break;
              }

              if (redirectPath) {
                this.props.history.push(redirectPath, {
                  property: row,
                });
              }
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {localStorage.getItem("perm") !== "agente" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )}
        </div>
      ),
    },

    {
      name: "",
      center: true,
      cell: (row) => (
        <div>
          {(row.source === "Grandes Áreas" ||
            row.source === "Imóveis em Abandono") && (
            <Button
              type="button"
              color="primary"
              className=" btn-zoo "
              title="Histórico O.S."
              onClick={() =>
                this.toggleModal(row.id, row.numero, row.logradouro)
              }
              // onClick={() => {
              //   this.props.history.push(
              //     `/admin/${this.state.pathTipo === "boletim"
              //       ? "abandoned-property"
              //       : "registred-property"
              //     }-edit`,
              //     {
              //       property: row,
              //     }
              //   );
              // }}
            >
              <b>Histórico O.S</b>
              {/* <FontAwesomeIcon icon={faEdit} className="edit" /> */}
            </Button>
          )}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };
  customStyles2 = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 12,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      response: "",
      response2: "",
      nome: "",
      logradouro: "",
      numero: "",
      id: "",
      pathTipo: "",
      bairro: "",
      api: Api.baseURL,
      requesting: false,
      neighborhood: [],
      sectors: [],
      selectedSector: null,
      selectedNeighborhood: null,
      isOpen: false,
      setIsOpen: false,
    };
  }
  toggleModal = (codigoid, cnumero, clogradouro) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      codigoid: codigoid,
      cnumero: cnumero ? cnumero : "N/A",
      clogradouro: clogradouro,
    }));

    try {
      let para = {
        numero: cnumero,
        logradouro: clogradouro,
      };

      let endpoint = "imovel/boletim";
      let esc = encodeURIComponent;
      let query = Object.keys(para)
        .map((k) => esc(k) + "=" + esc(para[k]))
        .join("&");

      let host = this.state.api + `/${endpoint}?status=`;

      let path = "" + "&" + query;

      const respons2 = fetch(host + path, {
        headers: {
          Authorization: localStorage.getItem("agToken"),
        },
      })
        .then((respons2) => {
          if (!respons2.ok) {
            throw new Error("Network response was not ok");
          }
          return respons2.json();
        })
        .then((body) => {
          console.log("body" + body);

          this.setState({
            response2: body,
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (e) {
      console.error("Invalid JSON string:", e);
    }
  };

  CloseModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      codigoid: null, // Reset codigoid when closing the modal
    }));
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      localStorage.setItem("pagecad", this.state.currentPage);
      console.log(this.state.currentPage);
      this.setState({ response: this.state.response });
      
    });
  };

  handleInputChange = (e) => {
    // Use a regular expression to remove non-numeric characters
    const numericValue = e.target.value.replace(/\D/, "");
    // numericValue = numericValue.slice(0, 6);
    
    
    // Update the state with the numeric value
    this.setState({ id: numericValue });
  };

  componentWillMount() {

    if (localStorage.getItem("paramlist")) {

    try {
      var obj = (localStorage.getItem("paramlist"));

      var para = JSON.parse(localStorage.getItem("paramlist"));

      console.log(JSON.stringify(localStorage.params));
      console.log(JSON.stringify(para));

    let reload = {
      id: para.id !== undefined ? para.id : "",
      nome: para.nome !== undefined ? para.nome : "",
      tipoorigem: para.tipoorigem ? para.tipoorigem : "",

    };

    let endpoint = "imovel/cadastro?=";

    let esc = encodeURIComponent;
    let query = Object.keys(reload)
      .map((k) => esc(k) + "=" + esc(reload[k]))
      .join("&");

    let host = this.state.api + `/${endpoint}`;


    let path =
      reload.status === "todos"
        ? "&" + query
        : "" + "&" + query;

        const respons2 = fetch(host + path, {
          headers: {
            Authorization: localStorage.getItem("agToken"),
          },
        })
        .then((respons2) => {
          if (!respons2.ok) {
            throw new Error("Network response was not ok");
          }
          return respons2.json();
        })
        .then((body) => {
          console.log("body"+body)
          localStorage.setItem("gridimov", JSON.stringify(body));
          var obj2 = JSON.parse(localStorage.getItem("gridimov"));
          this.setState({
            response: obj2,
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });


      console.log(reload)
        // Do something with the parsed object
      } catch (e) {
        console.error("Invalid JSON string:", e);
      }

    }
    


    localStorage.setItem("excel", "Imóveis");

    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });
    if (this.props.location != null) {
      const path = this.props.location.pathname;
      this.setState({
        pathTipo:
          path === "/admin/abandoned-property" ? "abandonado" : "cadastrado",
      });
    }

    if (localStorage.getItem("pagecad")) {
      var obj = JSON.parse(localStorage.getItem("pagecad")); // Parse the JSON string
      this.setState(
        {
          currentPage: obj,
          response: this.state.response, // You can omit this line as it's not necessary
        },
        () => {
          // Optional callback function to perform actions after state update
          this.forceUpdate(); // Perform the force update after state is set
        }
      );
    }

    if (localStorage.getItem("gridimov")) {
      localStorage.setItem("excel", "Imóveis");

      var obj = JSON.parse(localStorage.getItem("gridimov"));
      console.log(obj);
      console.log(obj.length);
      this.setState({
        response: obj,
      });
    }

    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });

    sectors().then((res) => {
      if (res) {
        this.setState({
          sectors: res,
        });
      }
    });
  }

  fetchSectors = async () => {
    const response = await fetch(Api.baseURL + "/setor", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };

  fetchNeighborhood = async () => {
    const response = await fetch(Api.baseURL + "/bairro", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        neighborhood: body,
      });
    }

    return body;
  };

  handleSubmit = (event) => {

    this.setState({
      currentPage: 1,
    });

    if (this.state.volume_min !== "" && this.state.volume_max !== "") {
      if (this.state.volume_min > this.state.volume_max) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Volume mínimo maior que o Volume máximo",
            type: "error",
          },
        });
        return false;
      }
    }

    if ((this.state.logradouro === undefined || this.state.logradouro === "") && this.state.numero.length !== 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro ou remova o Número.",
          type: "warning",
        },
      });

      return false;
    }

    this.setState({ response: "", requesting: true });



    localStorage.setItem("excel", "Imóveis");

    this.fetchTireShop()
      .then((res) =>
        this.setState({ response: res }, () => {
          localStorage.setItem("gridimov", JSON.stringify(res));
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Não foram encontrados registros para os parâmetros informados.",
            type: "error",
          },
          requesting: false,
        });
      });

    event.preventDefault();
  };

  fetchTireShop = async () => {
    let host = this.state.api + "/imovel/cadastro?";
    let params = {};
    // if (this.state.id) {
    //   params = {
    //     id: this.state.id,
    //   };
    // } else {
      params = {
        id: this.state.id,
        nome: this.state.nome,
        logradouro: this.state.logradouro.trim(),
        tipo: this.state.pathTipo,
        tipoorigem: this.state.tipo_de_imovel ? this.state.tipo_de_imovel : "",
        lado: this.state.lado ? this.state.lado : "",
        
        tiporeservatorio:
        (this.state.tipo_de_imovel === "reservatorio" ||
        this.state.tipo_de_imovel === "controlebio") &&
           this.state.tiporeservatorio ? this.state.tiporeservatorio : "",
        
        volume_min:
        (this.state.tipo_de_imovel === "reservatorio" ||
          this.state.tipo_de_imovel === "controlebio") &&
        this.state.volume_min
          ? this.state.volume_min
          : "",
        volume_max:
        (this.state.tipo_de_imovel === "reservatorio" ||
          this.state.tipo_de_imovel === "controlebio") &&
        this.state.volume_max
          ? this.state.volume_max
          : "",
        quarteirao: this.state.quarteirao ? this.state.quarteirao : "",
        numero: this.state.numero ? this.state.numero : "",
        tratamento: this.state.tratamento ? this.state.tratamento : "",
        bairro: this.state.bairro !== null ? this.state.bairro : "",
        setor:
          this.state.selectedSector !== null ? this.state.selectedSector : "",
      // };
    }
    localStorage.setItem("paramlist", JSON.stringify(params));

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        agent: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não foram encontrados registros para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };
  handleDelete = (element) => {
    console.log(element)
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "error",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      trieshop: element,
      isConfirmation: true,
    });
  };
  handleDeleteTireShop(d) {
    this.callDel(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDel = async (d) => {


    let apidel = '';

    switch (d.source) {
      case "Reservatórios":
        apidel = "piscinas";
        break;
      case "Ecopneumáticos":
        apidel = "borracharia";
        break;
      case "Grandes Áreas":
        apidel = "imovel";
        break;
      case "Imóveis em Abandono":
        apidel = "imovel";
        break;

      case "Pontos Estrátegicos":
        apidel = "ponto_estrategico";
        break;

      case "Controles Biológicos":
        apidel = "controle_biologico";
        break;

      case "Rondas Escolares":
        apidel = "Ronda";
        break;
      // Add more cases for other values of row.source as needed

      default:
        // Handle the default case if row.source doesn't match any of the cases above
        break;
    }


    // this.setState({
    //   swal: {
    //     show: true,
    //     title: "Atenção",
    //     text: apidel,
    //     type: "error",
    //   },
    // });

    





    const response = await fetch(Api.baseURL + "/"+apidel+"/" + d.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Cadastro "+ d.id +" " +"de " + d.source  +" deletado com sucesso!",
          type: "success",
        },
        response: "",
        nome: "",
        logradouro: "",
        tel: "",
        selectedNeighborhood: "",
        selectedSector: "",
        id: "",
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar registro.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  render() {
    const { isOpen, codigoid, cnumero, clogradouro } = this.state;
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteTireShop(this.state.trieshop);
            }
          }}
        />

        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Gerenciar Cadastros{" "}
                      {this.state.pathTipo === "cadastrado" ? "" : ""}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Tipo de Cadastro
                            </label>

                            <select
                              value={this.state.tipo_de_imovel}
                              onChange={(e) =>
                                this.setState({
                                  tipo_de_imovel: e.target.value,
                                })
                              }
                              className="form-control "
                              id="select-tipo"
                            >
                              <option defaultValue="" value="">
                                {" "}
                                Selecione...{" "}
                              </option>
                              <option value="reservatorio">
                                Reservatórios
                              </option>
                              <option value="grandesareas">
                              Grandes Áreas
                              </option>
                              <option value="imoveisabandono">
                                Imóveis em Abandono
                              </option>
                              <option value="controlebio">
                                Controles biológicos
                              </option>
                              <option value="borracharias">
                                Ecopneumáticos
                              </option>
                              <option value="ponto">Pontos Estratégicos</option>
                              <option value="ronda">Rondas Escolares</option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Nome Cadastro{" "}
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-nome"
                              type="text"
                              required
                              value={this.state.nome}
                              onChange={(e) =>
                                this.setState({ nome: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Código
                            </label>

                            <Input
                              onlydigits
                              className="form-control"
                              id="input-id"
                              inputMode="numeric"
                              // onInput={(e) => {
                              //   e.target.value = Math.max(
                              //     0,
                              //     parseInt(e.target.value)
                              //   )
                              //     .toString()
                              //     .slice(0, 6);
                              // }}
                              placeholder=""
                              value={this.state.id}
                              // onChange={(e) =>
                              //   this.setState({ id: e.target.value })
                              // }
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {(this.state.tipo_de_imovel === "reservatorio" || this.state.tipo_de_imovel === "controlebio")  && (

                         <Row>
                        <Col lg="4">
                        <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Tipo de  Reservatório *
                                </label>

                              <select
                                value={this.state.tiporeservatorio}
                                onChange={(e) =>
                                  this.setState({
                                    tiporeservatorio: e.target.value,
                                  })
                                }
                                className="form-control "
                                id="select-tratamento"
                              >
                                <option defaultValue="" value="">
                                  {" "}
                                    Selecione...{" "}
                                </option>
                                <option value="bolsao">
                                    Bolsão
                                  </option>
                                  <option value="piscina">
                                    Piscina
                                  </option>
                                  <option value="tanque">Tanque</option>
                                  <option value="poco">
                                    Poço
                                  </option>
                                  <option value="mina">
                                    Mina
                                  </option>
                                  <option value="outros">Outros</option>
                              </select>
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Vol. Mínimo
                            </label>

                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-quarteirao"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder=""
                              value={this.state.volume_min}
                              onChange={(e) =>
                                this.setState({ volume_min: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Vol. Máximo
                            </label>

                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-quarteirao"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder=""
                              value={this.state.volume_max}
                              onChange={(e) =>
                                this.setState({ volume_max: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      )}
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bairro"
                            >
                              Localidade
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-lado"
                              type="text"
                              maxLength="30"
                              value={this.state.bairro}
                              onChange={(e) =>
                                this.setState({ bairro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-quarteirao"
                            >
                              Quarteirão
                            </label>

                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-quarteirao"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                              placeholder=""
                              value={this.state.quarteirao}
                              onChange={(e) =>
                                this.setState({
                                  quarteirao: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-lado"
                            >
                              Lado
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-lado"
                              type="text"
                              maxLength="4"
                              value={this.state.lado}
                              onChange={(e) =>
                                this.setState({ lado: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            {this.state.sectors.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Região
                                </label>

                                <select
                                  value={this.state.selectedSector}
                                  onChange={(e) =>
                                    this.setState({
                                      selectedSector: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  {this.state.sectors.map((team) => (
                                    <option
                                      key={team.id}
                                      value={team.nome}
                                      defaultValue={this.state.selectedSector}
                                    >
                                      {team.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-logradouro"
                            >
                              Logradouro
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-logradouro"
                              type="text"
                              required
                              value={this.state.logradouro}
                              onChange={(e) =>
                                this.setState({ logradouro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Número
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              required
                              value={this.state.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Tratamento Biológico
                            </label>

                            <select
                              value={this.state.tratamento}
                              onChange={(e) =>
                                this.setState({
                                  tratamento: e.target.value,
                                })
                              }
                              className="form-control "
                              id="select-tratamento"
                            >
                              <option defaultValue="" value="">
                                {" "}
                                Selecione...{" "}
                              </option>
                              <option value="0">
                                  Nenhum
                                  </option>
                                <option value="1">A definir</option>
                                <option value="2">Povoado</option>
                                <option value="3">Monitorado</option>
                                <option value="4">Clorado</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {" "}
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                          {/* {localStorage.getItem("perm") !== "agente" && (
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={() => {
                                this.props.history.push(
                                  `/admin/${this.state.pathTipo === "abandonado"
                                    ? "abandoned-property"
                                    : "registred-property"
                                  }-edit`
                                );
                              }}
                            >
                              <b>Cadastrar</b>
                            </Button>
                          )} */}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
            <Row className="text-left" style={{ paddingBottom: "2%" }}>
              <Col className="order-xl-1" xl="12">
                <ExcelFile>
                  <ExcelSheet
                    data={this.state.response}
                    name={
                      this.state.tipo_de_imovel
                        ? "Lista_Cadastro_" + this.state.tipo_de_imovel
                        : "Lista_Cadastro"
                    }
                  >
                    <ExcelColumn
                      label="Código"
                      value={(row) => " " + row.id}
                      cell={(props) => (
                        <span style={{ textAlign: "left" }}>{props.value}</span>
                      )}
                    />

                    <ExcelColumn
                      label="Tipo de Cadastro"
                      value={(row) => " " + row.source}
                      cell={(props) => (
                        <span style={{ textAlign: "left" }}>{props.value}</span>
                      )}
                    />

                    <ExcelColumn
                      label="nome"
                      value={(row) => " " + row.nome}
                      cell={(props) => (
                        <span style={{ textAlign: "left" }}>{props.value}</span>
                      )}
                    />

                    <ExcelColumn
                      label="logradouro"
                      value={(row) => row.logradouro}
                      cell={(props) => (
                        <span style={{ textAlign: "left" }}>{props.value}</span>
                      )}
                    />

                    <ExcelColumn
                      label="Número"
                      value={(row) => row.numero}
                      cell={(props) => (
                        <span style={{ textAlign: "left" }}>{props.value}</span>
                      )}
                    />
                    
                    <ExcelColumn
                      label="quarteirao"
                      value={(row) =>
                        row.quarteirao ? " " + row.quarteirao : ""
                      }
                      cell={(props) => (
                        <span style={{ textAlign: "left" }}>{props.value}</span>
                      )}
                    />
                    <ExcelColumn
                      label="lado"
                      value={(row) => (row.lado ? row.lado : "")}
                      cell={(props) => (
                        <span style={{ textAlign: "left" }}>{props.value}</span>
                      )}
                    />
                    <ExcelColumn
                      label="Localidade"
                      value={(row) => (row.bairro ? row.bairro : "")}
                      cell={(props) => (
                        <span style={{ textAlign: "left" }}>{props.value}</span>
                      )}
                    />
                    <ExcelColumn
                      label="Região"
                      value={(row) => (row.setor ? row.setor : "")}
                      cell={(props) => (
                        <span style={{ textAlign: "left" }}>{props.value}</span>
                      )}
                    />
                    <ExcelColumn
                    label="Tratamento Biológico"
                    value={(row) => {
                      if (row.tratamento !== undefined) {
                        // Check if row.tratamento is defined
                        switch (row.tratamento) {
                          case 1:
                            return "A definir";
                          case 2:
                            return "Povoado";
                          case 3:
                            return "Monitorado";
                          case 4:
                            return "Clorado";
                          default:
                            return "Nenhum"; // Default case if row.tratamento is defined but not 1, 2, 3, or 4
                        }
                      } else {
                        return "Nenhum"; // Default case if row.tratamento is undefined
                      }
                    }}
                    cell={(props) => (
                      <span style={{ textAlign: "left" }}>{props.value}</span>
                    )}
                  />
                   
                  </ExcelSheet>
                </ExcelFile>
              </Col>
            </Row>
          )}
          {this.state.response && (
            <DataTable
              // noHeader={true}
              // pagination={true}
              // striped={true}
              // columns={this.columns}
              // responsive={true}
              // highlightOnHover={true}
              // data={this.state.response}
              // paginationComponentOptions={this.defaultComponentOptions}
              // paginationPerPage={5}
              // paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              // customStyles={this.customStyles}
              // progressPending={this.state.response === undefined}
              // noDataComponent="Nenhum registro encontrado."
              // paginationResetDefaultPage={this.state.response.length > 0}
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              sortable={true}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              noDataComponent="Nenhum registro encontrado."
              paginationComponentOptions={this.defaultComponentOptions}
              customStyles={this.customStyles}
              paginationDefaultPage={this.state.currentPage}
              onChangePage={this.handlePageChange}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
        <Modal
          isOpen={isOpen}
          toggle={() => this.toggleModal(codigoid, cnumero, clogradouro)}
          className="custom-modal"
        >
          <ModalHeader
            toggle={() => this.toggleModal(codigoid, cnumero, clogradouro)}
            className="custom-modal-header"
          >
            Histórico de O.S.
          </ModalHeader>
          <ModalBody>
            <p style={{ fontWeight: "bold" }}>
              Histórico de O.S. para Logradouro: {clogradouro}, Nº {cnumero}
            </p>
            {this.state.response2 && !this.state.requesting && (
              <DataTable
                noHeader={true}
                pagination={true}
                striped={true}
                columns={this.columns2}
                responsive={true}
                highlightOnHover={true}
                data={this.state.response2}
                paginationComponentOptions={this.defaultComponentOptions}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                customStyles={this.customStyles}
                progressPending={this.state.response2 === undefined}
                noDataComponent="Nenhum registro encontrado."
                paginationResetDefaultPage={this.state.response2.length > 0}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.CloseModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
